import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import { connect } from 'react-redux'

import { setLoginErrorAction } from '../../redux/reducers/auth/actions'

class LoginErrorModal extends Component {
  handleClose = () => this.props.setLoginError(null)

  render() {
    const { isOpen, message } = this.props

    return (
      <Modal open={isOpen} onClose={this.handleClose}>
        <Modal.Content>{message}</Modal.Content>
      </Modal>
    )
  }
}

LoginErrorModal.propTypes = {
  // All props from redux
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  setLoginError: PropTypes.func.isRequired,
}
LoginErrorModal.defaultProps = {
  isOpen: null,
  message: null
}

const mapState = state => ({
  isOpen: !!state.auth.loginError,
  message: state.auth.loginError,
})
const mapDispatch = dispatch => ({
  setLoginError: v => dispatch(setLoginErrorAction(v))
})

export default connect(mapState, mapDispatch)(LoginErrorModal)
