import { sendRequest } from '../../../lib/api'
import { setFlashMessageAction } from '../app/actions'
import { actionTypes, screens } from '../../constants'

export const setLoginErrorAction = value => ({ type: actionTypes.SET_LOGIN_ERROR, payload: value })

export const loginAction = (email, password) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_LOGIN_IN_PROGRESS, payload: true })
    const response = await sendRequest('login', {
      login_email: email,
      login_password: password,
    })
    if (response.error) {
      dispatch({ type: actionTypes.SET_LOGIN_IN_PROGRESS, payload: false })
      dispatch({ type: actionTypes.SET_LOGIN_ERROR, payload: response.message })
      return
    }
    dispatch({ type: actionTypes.LOGIN, payload: response })
    dispatch({ type: actionTypes.SET_LOGIN_IN_PROGRESS, payload: false })
    dispatch(setFlashMessageAction({
      message: 'Logged in successfully.',
      type: 'positive',
    }))
    dispatch({ type: actionTypes.SET_SCREEN, payload: screens.NEW_SALE })
  }
}

export const logoutAction = () => {
  return async (dispatch, getState) => {
    const session_id = (getState().auth.user?getState().auth.user.session_id:null)
    dispatch({ type: actionTypes.SET_LOGIN_IN_PROGRESS, payload: true })
    const response = await sendRequest('logoff', {
      session_id,
    })
    if (response.error) {
      dispatch({ type: actionTypes.SET_LOGIN_IN_PROGRESS, payload: false })
      dispatch(setFlashMessageAction({
        message: 'Auto log out...',
        type: 'negative',
      }))
      return
    }
    dispatch({ type: actionTypes.LOGOUT })
    dispatch({ type: actionTypes.SET_LOGIN_IN_PROGRESS, payload: false })
    dispatch(setFlashMessageAction({
      message: 'Logged out successfully.',
      type: 'positive',
    }))
    dispatch({ type: actionTypes.SET_SCREEN, payload: screens.APP_LOGIN })
  }
}

export const validateSessionAction = () => {
  return async (dispatch, getState) => {
    const session_id = (getState().auth.user?getState().auth.user.session_id:null)
    const response = await sendRequest('validate_session', {
      session_id,
    })
    if (response.error) {
      dispatch(setFlashMessageAction({
        message: `${response.message}. Please log in.`,
        type: 'negative',
      }))
      dispatch(logoutAction())
      return
    }
  }
}
