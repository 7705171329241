import React from 'react'
import PropTypes from 'prop-types'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import { connect } from 'react-redux'

import Logo from './MenuLogo'
import MenuItem from './MenuItem'
import LogoutMenuItem from './LogoutMenuItem'
import { screens } from '../../redux/constants'

const MainMenu = ({ user }) => (
  <Menu fixed="top" inverted size="huge" color="blue">
    <Logo />
    {user && user.permissions.indexOf('ViewOnly')===-1 && user.permissions.indexOf('Production')===-1 && user.permissions.indexOf('Despatch')===-1 && (
      <MenuItem
        // activeScreens={[
        //   screens.NEW_SALE,
        //   screens.NEW_SALE_DETAIL,
        // ]}
        targetScreen={screens.NEW_SALE}
        title="Home"
      />
    )}
    {user && user.permissions.indexOf('ViewOnly')===-1 && user.permissions.indexOf('Production')===-1 && user.permissions.indexOf('Despatch')===-1 && (
      <MenuItem
        targetScreen={screens.PRODUCTION}
        title="Production"
      />
    )}
    {user && (user.permissions.indexOf('ViewOnly')!==-1 || user.permissions.indexOf('Production')!==-1 || user.permissions.indexOf('Despatch')!==-1) && (
      <MenuItem
        // activeScreens={[
        //   screens.NEW_SALE,
        //   screens.NEW_SALE_DETAIL,
        // ]}
        targetScreen={screens.NEW_SALE}
        title="Home"
      />
    )}
    <Menu.Menu position="right">
    { (!user || (user.permissions.indexOf('ViewOnly')===-1 && user.permissions.indexOf('Production')===-1 && user.permissions.indexOf('Despatch')===-1) ) && (
      <MenuItem
        // activeScreens={[
        //   screens.SETTINGS,
        //   screens.SETTINGS_LOGIN,
        // ]}
        targetScreen={screens.SETTINGS_LOGIN}
        title="Settings"
      />
    )}
      {!user && (
        <MenuItem
          targetScreen={screens.APP_LOGIN}
          title="Login"
        />
      )}
      {user && <LogoutMenuItem />}
    </Menu.Menu>
  </Menu>
)

MainMenu.propTypes = {
  user: PropTypes.object,
}
MainMenu.defaultProps = {
  user: null,
}

const mapState = state => ({
  user: state.auth.user,
})

export default connect(mapState)(MainMenu)
