import Table from 'semantic-ui-react/dist/commonjs/collections/Table'
import styled from 'styled-components'

const BorderlessTableRow = styled(Table.Row)`
&&& {
  border: 0 !important;
}
`

export default BorderlessTableRow
