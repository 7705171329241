import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'

class FlashMessage extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.onDismiss()
    }, (this.props.type==='negative'?20000:3000))
  }

  handleDismiss = () => this.props.onDismiss()

  render() {
    const { children, onDismiss, top, type } = this.props
    
    let icon='info circle';
    if(type === 'negative'){
    	    icon='exclamation circle';
    }
    if(type === 'positive'){
    	    icon='check circle';
    }
    
    return (
      <Message
        icon={icon}
        content={children}
        size='large'
        type={type}
        info={type === 'info'}
        negative={type === 'negative'}
        onDismiss={onDismiss}
        positive={type === 'positive'}
        floating
        style={{
          borderRadius: 10,
          margin: 0,
          position: 'fixed',
          right: 30,
          top,
          zIndex: 999999,
          whiteSpace: 'pre-line',
          width:400,
          boxShadow:'0px 0px 20px rgba(0, 0, 0, 0.4)'
        }}
      />
    )
  }
}

FlashMessage.propTypes = {
  children: PropTypes.any.isRequired,
  onDismiss: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['positive', 'negative', 'info']),
  top: PropTypes.number,
}
FlashMessage.defaultProps = {
  top: 48,
  type: null,
}

export default FlashMessage