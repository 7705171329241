import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'


class SaleHeader extends Component {

	openSO = () => {
		const { sales_orderISbb_sales_ordersID} = this.props
		window.open('https://picton.blueboxonline.com/?class=bb_sales_orders&method=view&global[fields][_id]='+sales_orderISbb_sales_ordersID,'view_so')
	}

	openSO2 = () => {
		const {second_sales_orderISbb_sales_ordersID} = this.props
		window.open('https://picton.blueboxonline.com/?class=bb_sales_orders&method=view&global[fields][_id]='+second_sales_orderISbb_sales_ordersID,'view_so')
	}

	render() {
		const { this_quote_id, sale_id, text, sale_completedYN, sales_orderISbb_sales_ordersID, second_sales_orderISbb_sales_ordersID, saved_to_quoteISbb_sales_quotesID, sales_order_cancelledYN } = this.props

		let status=<div className="ui red circular label">Open</div>
		let doc_indicator=<span>TEMP{sale_id}</span>
		if(sale_completedYN===1){
			status=<div className="ui blue circular label">Completed</div>
		}
		if(sales_orderISbb_sales_ordersID*1>0){
			doc_indicator=<span><span style={{cursor:'pointer'}} onClick={this.openSO} >SO:{sales_orderISbb_sales_ordersID}</span><span>&nbsp;</span>{second_sales_orderISbb_sales_ordersID*1>0?<small style={{cursor:'pointer'}} onClick={this.openSO2} >Shipped:{second_sales_orderISbb_sales_ordersID}</small>:<span/>}</span>
			status=<div className="ui green circular label">Production</div>
		}
		if(saved_to_quoteISbb_sales_quotesID*1>0){
			doc_indicator=<span>Quote:{saved_to_quoteISbb_sales_quotesID}</span>
			status=<div className="ui gray circular label">Quotes</div>
		}
		if(sales_order_cancelledYN===1){
			status=<div className="ui red circular label">***CANCELLED***</div>
		}
		if(this_quote_id*1>0){
			doc_indicator=<span>Quote:{this_quote_id}</span>
			status=<div className="ui blue circular label">Quote</div>
		}
	return(
	  <Header as="h2">
	    {text}
	    <Header.Subheader>
	    {doc_indicator}
	    {status}
	    </Header.Subheader>
	  </Header>
	  )
	}
}

SaleHeader.propTypes = {
  sale_id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  sale_completedYN: PropTypes.number.isRequired,
  sales_orderISbb_sales_ordersID: PropTypes.string,
}

export default SaleHeader
