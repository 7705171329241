import React, { Component } from 'react'
import PropTypes from 'prop-types'

import EmbroideryLogoForm from './EmbroideryLogoForm'

class EmbroideryLogosForm extends Component {
  handleItemChange = (item) => {
    const { handleChange } = this.props
    handleChange(item)
  }

  handleItemDelete = (item) => {
    const { handleDelete } = this.props
    handleDelete(item)
  }

  render() {
    const { items } = this.props

    return (
      <div>
        {items.map(i => (
          <EmbroideryLogoForm
            key={i.id}
            handleChange={this.handleItemChange}
            handleDelete={this.handleItemDelete}
            logo={i}
          />
        ))}
      </div>
    )
  }
}

EmbroideryLogosForm.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
}

export default EmbroideryLogosForm
