export const printToThermalPrinter = (printerIPAddress, printerPort, dataString) => {
  // TODO: should return a promise with the printResult, so can await the result in actions.js
  try {
    let result = false

    const bufferData = new Uint8Array(dataString.length)
    for (var i = 0; i < bufferData.length; i++) {
      bufferData[i] = dataString.charCodeAt(i)
    }
    const buffer = bufferData.buffer

    window.chrome.sockets.tcp.create({}, (createInfo) => {
      var _socketTcpId= createInfo.socketId
      window.chrome.sockets.tcp.connect(_socketTcpId, printerIPAddress, parseInt(printerPort), (result) => {
        if (result === 0) {
          window.chrome.sockets.tcp.send(_socketTcpId, buffer)
        }
      })
      window.chrome.sockets.tcp.onReceive.addListener((info) => {
        // recived, then close connection
        window.chrome.sockets.tcp.close(_socketTcpId)
        result = true
      })
      // set the timeout
      setTimeout(() => {
        window.chrome.sockets.tcp.close(_socketTcpId)
      }, 5000)
    })

    return result
  } catch (e) {
    return false
  }
}

export const printToSystemPrinter = (dataString) => {
  let html = ''
  html += '<html>'
  html += '<head>'
  html += '<style>.tillslip_wrapper{http://margin-top:20px;}.tillslip_wrapper div{font-family:"Courier New", Courier, monospace;http://font-size:11pt;line-height:15pt;text-align:center;white-space: pre;}</style>'
  html += '</head>'
  html += '<body>'
  html += '<div class="tillslip_wrapper">'
  html += '<div>'
  html += dataString
  html += '</div>'
  html += '</div>'
  html += '</body>'
  html += '</html>'

  if (window.cordova) {
    let result = false
    // window.cordova.plugins.printer.check((available, count) => {
    //   if (available) {
        window.cordova.plugins.printer.print(html, { duplex: 'long' }, (res) => {
          result = res
        })
        return result
    //   }
    // })
  } else {
    const myWindow = window.open('', 'picton-print')
    if (typeof myWindow !== 'undefined' && myWindow !== null && typeof myWindow.document !== 'undefined') {
      myWindow.document.write(html)
      setTimeout(function(){
        myWindow.focus()
        myWindow.print()
        myWindow.close()
      }, 500)
      return true
    } else {
      return 'Please check that pop-up windows in your browser are not blocked for this system/domain.'
    }
  }
}
