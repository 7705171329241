import { actionTypes } from '../../constants'

const initialState = {
  isLoginInProgress: false,
  loginError: null,
  user: null,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        user: action.payload,
      }
    case actionTypes.LOGOUT:
      return {
        ...state,
        user: null,
      }
    case actionTypes.SET_LOGIN_ERROR:
      return {
        ...state,
        loginError: action.payload,
      }
    case actionTypes.SET_LOGIN_IN_PROGRESS:
      return {
        ...state,
        isLoginInProgress: action.payload,
      }
    default:
      return state
  }
}

export default authReducer
