import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import { connect } from 'react-redux'

import { addSaleDocumentAction } from '../../redux/reducers/app/actions'
import dataURLToBlob from '../../lib/dataURLToBlob'
import readFile from '../../lib/readFile'

class AddDocumentButton extends Component {
  constructor(props) {
    super(props)
    this.fileInput = React.createRef()
  }

  chooseFile = (e) => {
    const file = e.target.files[0]
    readFile(file, (dataURL) => {
      const { sale_id, addSaleDocument } = this.props
      const blob = dataURLToBlob(dataURL)
      addSaleDocument(
        sale_id,
        blob,
        window.cordova
          ? file.name.substring(file.name.lastIndexOf('/') + 1)
          : file.name,
      )
    })
  }

  render() {
    return (
      <div>
        <Button
          as="label"
          compact
          onClick={() => this.fileInput.current.click()}
        >
          + Doc
        </Button>
        <input
          ref={this.fileInput}
          hidden
          onChange={this.chooseFile}
          type="file"
        />
      </div>
    )
  }
}

AddDocumentButton.propTypes = {
  // The following props from redux:
  addSaleDocument: PropTypes.func.isRequired,
  sale_id: PropTypes.string.isRequired,
}

const mapState = state => ({
  sale_id: state.app.activeSale._id,
})
const mapDispatch = dispatch => ({
  addSaleDocument: (sale_id, file, filename) => dispatch(addSaleDocumentAction(sale_id, file, filename)),
})

export default connect(mapState, mapDispatch)(AddDocumentButton)
