import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'
import { connect } from 'react-redux'

import { newSaleAction, loadSaleAction, loadQuoteAction, setSearchResultsModalIsOpenAction } from '../../../redux/reducers/app/actions'

class SearchResultRow extends Component {
  handleClick = () => {
    const { data, loadSale, loadQuote, newSale, setSearchResultsModalIsOpen } = this.props

    setSearchResultsModalIsOpen(false)

    switch (data.type) {
      case 'customer':
      	newSale(data.customer_id)
      	break
      case 'quote':
      	//console.log('tyring new sale from quote : '+data.customer_id+'@'+data.quote_id);
        //newSale(data.customer_id, data.quote_id)
        loadQuote(data.quote_id)
        break
      case 'sale':
        loadSale(data.sale_id)
        return
      default:
        return
    }
  }

  render() {
    const { columns, data } = this.props
    //console.log(data)
    return (
      <Table.Row
        onClick={this.handleClick}
        style={{
          cursor: 'pointer',
          backgroundColor: ((typeof data.on_holdYN!='undefined' && data.on_holdYN==='1')?'#ffcccc':''),
        }}
      >
        {columns.map(c => <Table.Cell key={c.name} style={{fontSize:'9pt'}}>{data[c.field]}</Table.Cell>)}
      </Table.Row>
    )
  }
}

SearchResultRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.object.isRequired,

  // The following props from redux:
  loadSale: PropTypes.func.isRequired,
  newSale: PropTypes.func.isRequired,
  setSearchResultsModalIsOpen: PropTypes.func.isRequired,
}

const mapDispatch = dispatch => ({
  loadSale: (sale_id) => dispatch(loadSaleAction(sale_id)),
  newSale: (customer_id, quote_id) => dispatch(newSaleAction(customer_id, quote_id)),
  setSearchResultsModalIsOpen: (value) => dispatch(setSearchResultsModalIsOpenAction(value)),
  loadQuote: (quote_id) => dispatch(loadQuoteAction(quote_id)),
})

export default connect(null, mapDispatch)(SearchResultRow)