import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'
import { connect } from 'react-redux'

import ItemRow from './ItemRow'

class AddItemModal extends Component {
  handleItemClick = (item, option) => {
    const { activeSaleId, addItem } = this.props //,handleClose
    //handleClose() - used to close the modal each time an item was selected
    document.getElementById('ticked_'+item._id+'_'+(option?option.option_1_id:0)+'_'+(option?option.option_2_id:0)).style.backgroundColor="#ccffcc";
    addItem(
      activeSaleId,
      item._id,
      option ? option.option_1_id : null,
      option ? option.option_2_id : null,
    )
  }

  render() {
    const { isOpen, handleClose, items, searchValue } = this.props

    const sortedItems = items
      .sort((a, b) => a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? 1 : -1)

    // console.log(sortedItems)

    return (
      <Modal open={isOpen} onClose={handleClose}>
        <Modal.Header>Search results for "{searchValue}"</Modal.Header>
        <Modal.Content scrolling>
          {items && items.length > 0 ? (
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>SKU</Table.HeaderCell>
                  <Table.HeaderCell>Product</Table.HeaderCell>
                  <Table.HeaderCell>Price</Table.HeaderCell>
                  <Table.HeaderCell>Sizes</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {sortedItems.map(i => (
                  <ItemRow key={i._id} item={i} onClick={this.handleItemClick} />
                ))}
              </Table.Body>
            </Table>
          ) : (
            <div>No items found.</div>
          )}
        </Modal.Content>
      </Modal>
    )
  }
}

AddItemModal.propTypes = {
  activeSaleId: PropTypes.string.isRequired,
  addItem: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchValue: PropTypes.string.isRequired,
}

const mapState = state => ({
  activeSaleId: state.app.activeSale._id,
})

export default connect(mapState)(AddItemModal)
