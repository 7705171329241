import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import Input from 'semantic-ui-react/dist/commonjs/elements/Input'
import { isEmpty } from 'validator'
import { connect } from 'react-redux'

import AddItemModal from './AddItemModal'

class AddItemForm extends Component {
  state = {
    isModalOpen: false,
    items: [],
    value: '',
    valueError: false,
    searching:false,
  }

  handleInputChange = (e, { value }) => this.setState({ value })

  closeModal = () => this.setState({ isModalOpen: false })

  openModal = () => this.setState({ isModalOpen: true, searching: false })

  handleSearchItems = () => {
    const { value } = this.state
    
    this.setState({ searching: true })

    if (isEmpty(value)) {
      this.setState({ valueError: true, searching: false })
      return
    }
    
    setTimeout(function(){
    		const { products, product_options } = this.props
    
		    // we just search the in-memory store for now. Seems fast enough
		    // TODO: if we use indexeddb, search that too
		    const items = products
		      .filter(p => {
			if (p.name && p.name.trim().toLowerCase().indexOf(value.trim().toLowerCase()) > -1) return true
			if (p.code && p.code.trim().toLowerCase().indexOf(value.trim().toLowerCase()) > -1) return true
			return false
		      })
		      .map(p => {
			const options = product_options.filter(o => parseInt(o.item_id) === parseInt(p._id))
			return {
			  ...p,
			  options,
			}
		      })
		      
		    // if items found:
		    this.setState({ items }, () => {
		      this.setState({ valueError: false })
		      this.openModal()
		    })    
    }.bind(this), 100)
    
  }

  render() {
    const { addItem } = this.props
    const { isModalOpen, value, items, valueError,searching } = this.state

    return (
      <Form onSubmit={this.handleSearchItems}>
        <Input
          error={valueError}
          label={(
            searching
            ?
            <Button
              content="Search"
              loading
              secondary
              id="gobutton"
            />
            :
            <Button
              content="Search"
              onClick={this.handleSearchItems}
              secondary
              type="submit"
              id="gobutton"
            />
          )}
          labelPosition='right'
          onChange={this.handleInputChange}
          placeholder="Add item:"
          value={value}
        />
        <AddItemModal
          addItem={addItem}
          isOpen={isModalOpen}
          items={items}
          handleClose={this.closeModal}
          searchValue={value}
        />
      </Form>
    )
  }
}

AddItemForm.propTypes = {
  addItem: PropTypes.func.isRequired,

  // The following props from redux:
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  product_options: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const mapState = state => ({
  products: state.app.products,
  product_options: state.app.product_options,
})

export default connect(mapState)(AddItemForm)
