import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import throttle from 'lodash/throttle'

import './lib/polyfills'
import { loadState, saveState } from './lib/localStorage'
import rootReducer from './redux/reducers'

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const persistedState = loadState()
const store = createStore(
  rootReducer,
  persistedState,
  compose(applyMiddleware(thunk)),
  // composeEnhancers(applyMiddleware(thunk)),
)

store.subscribe(throttle(() => {
  saveState({
    settings: store.getState().settings,
  })
}), 1000)

const startApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  )
}

if(!window.cordova) {
  startApp()
} else {
  document.addEventListener('deviceready', startApp, false)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
