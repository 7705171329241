import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
//import DrawableCanvas from 'react-drawable-canvas'
import SignatureCanvas from 'react-signature-canvas'

import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import { connect } from 'react-redux'

import dataURLToBlob from '../../lib/dataURLToBlob'

class SignatureButton extends Component {
  state = {
    isModalOpen: false,
  }

  componentDidUpdate(prevProps) {
    // Close modal after request completes
    if (this.props.isRequestInProgress === false && prevProps.isRequestInProgress === true) {
      this.setState({ isModalOpen: false })
    }
  }

  closeModal = () => this.setState({ isModalOpen: false })

  openModal = () => this.setState({ isModalOpen: true })

  handleAddSaleSignature = () => {
    const { onAddSaleSignature, sale_id } = this.props
    const canvas = document.querySelector('canvas')
    const dataURL = canvas.toDataURL()
    const file = dataURLToBlob(dataURL)
    const print_name = document.getElementById('new_sale_signature_print_name').value
    if(!print_name){
    	document.getElementById('new_sale_signature_print_name').style.backgroundColor='#ffcccc';
    	return
    }
    onAddSaleSignature(sale_id, file, print_name)
  }

  sigPad = {}

  handleClearClick = () => this.sigPad.clear()

  setInputToWhite = () => document.getElementById('new_sale_signature_print_name').style.backgroundColor='white';

  render() {
    const { isRequestInProgress, stage_name } = this.props
    const { isModalOpen } = this.state

    return (
      <div>
        <Button
          compact
          color="green"
          content={(stage_name && (stage_name.indexOf('despatch') || stage_name.indexOf('collect')))?"Sign Taken":"Sign"}
          onClick={this.openModal}
        />
        <Modal
          open={isModalOpen}
          onClose={this.closeModal}
        >
          <Modal.Header>Add signature</Modal.Header>
          <Modal.Content>

            <SignatureCanvas
            penColor='black'
            canvasProps={{width: 800, height: 300, className: 'sigCanvas', style:{border:'1px solid gray'} }}
            ref={(ref) => { this.sigPad = ref }}
            />

            <Form.Input
		  name="print_name"
		  placeholder="Print Name..."
		  required
		  style={{ width: "500px" }}
		  id='new_sale_signature_print_name'
		  onClick={this.setInputToWhite}
		  /*autoFocus*/
		/>
          </Modal.Content>
          <Modal.Actions>
            <Button
              disabled={isRequestInProgress}
              onClick={this.handleClearClick}
            >
              Clear
            </Button>
            <Button
              disabled={isRequestInProgress}
              onClick={this.closeModal}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleAddSaleSignature}
              loading={isRequestInProgress}
              positive
            >
              Add signature
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

SignatureButton.propTypes = {
  onAddSaleSignature: PropTypes.func.isRequired,
  sale_id: PropTypes.string.isRequired,

  // The following props from redux:
  isRequestInProgress: PropTypes.bool.isRequired,
}

const mapState = state => ({
  isRequestInProgress: state.app.isRequestInProgress,
})

export default connect(mapState)(SignatureButton)
