import React, { Component } from 'react'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import { connect } from 'react-redux'

import {
setModalNoteSentAction
} from '../../redux/reducers/app/actions'

class AddNoteButton extends Component {
  handleClick = () => {
    const {
    setModalNoteSent
    } = this.props

      setModalNoteSent(false);//triggers the modal


  }

  render() {
    return (
      <Button
        size='mini' floated='right'
        content="Add Note"
        onClick={this.handleClick}
      />
    )
  }
}

const mapState = state => ({
  sale_id: state.app.activeSale._id,
})
const mapDispatch = dispatch => ({
  setModalNoteSent: (bool) => dispatch(setModalNoteSentAction(bool)),
})

export default connect(mapState, mapDispatch)(AddNoteButton)
