import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import { connect } from 'react-redux'

import HPEmbModal from './HPEmbModal'

class AddHPEmbButton extends Component {
  state = {
    isModalOpen: false,
  }

  componentDidUpdate(prevProps) {
    // Close modal after request completes
    if (this.props.isRequestInProgress === false && prevProps.isRequestInProgress === true) {
      this.setState({ isModalOpen: false })
    }
  }

  closeModal = () => this.setState({ isModalOpen: false })

  openModal = () => this.setState({ isModalOpen: true })

  render() {
    const { isModalOpen } = this.state

    return (
      <div>
        <Button
          compact
          content="+ HP/Emb"
          onClick={this.openModal}
          color='purple'
        />
        <HPEmbModal
          handleClose={this.closeModal}
          isOpen={isModalOpen}
        />
      </div>
    )
  }
}

AddHPEmbButton.propTypes = {
  isRequestInProgress: PropTypes.bool.isRequired,
}

const mapState = state => ({
  isRequestInProgress: state.app.isRequestInProgress,
})

export default connect(mapState)(AddHPEmbButton)
