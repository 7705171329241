import React from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'

const PayCashButton = ({ onClick }) => (
  <Button
    color='green'
    compact
    content="Cash"
    onClick={() => onClick('cash')}
  />
)

PayCashButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default PayCashButton
