import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import { Icon } from 'semantic-ui-react'

import { connect } from 'react-redux'

import { loadSaleAction, loadQuoteAction } from '../redux/reducers/app/actions'

class SaleSummary extends Component {

	handleClick = () => {
		const { loadQuote,loadSale, sale_id, this_quote_id } = this.props
		if(this_quote_id*1>0){
			loadQuote(sale_id)
		}else{
			loadSale(sale_id)
		}
	}

	render() {
		const { itemCount, price, sale_id } = this.props
		let clickStyle={};
		if(sale_id){
			clickStyle={cursor:'pointer'};
		}
		return (
			<Header as="h2" onClick={sale_id?this.handleClick:null} style={clickStyle}>{itemCount} Item{itemCount*1===1?'':'s'}, Total &pound;{price.toFixed(2)} {sale_id && <Icon name='refresh' style={{color:'silver'}}/>}</Header>
			)
	}

}

SaleSummary.propTypes = {
  itemCount: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
}

const mapDispatch = dispatch => ({
	loadSale: sale_id => dispatch(loadSaleAction(sale_id)),
	loadQuote: (quote_id) => dispatch(loadQuoteAction(quote_id)),
})

export default connect(null, mapDispatch)(SaleSummary)
