import React from 'react'

import SettingsLoginForm from './SettingsLoginForm'
import SettingsHeader from '../SettingsHeader'

const SettingsLoginScreen = () => (
  <div>
    <SettingsHeader />
    <SettingsLoginForm />
  </div>
)

export default SettingsLoginScreen
