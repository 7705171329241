import React from 'react'
import PropTypes from 'prop-types'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import List from 'semantic-ui-react/dist/commonjs/elements/List'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'

const FilesList = ({ files }) => (
  <Segment>
    {files && files.length > 0 ? (
      <div>
        <Header as="h4">Files ({files.length})</Header>
        <List link>
          {files.map(f => {
            const fileName = f.file.substring(f.file.lastIndexOf('/') + 1)
            let fileIcon
            switch (f.type) {
              case "IMAGE":
                fileIcon = 'file image outline'
                break
              case "SIGNATURE":
                fileIcon = 'file alternate outline'
                break
              default:
                fileIcon = 'file outline'
            }
            return (
              <List.Item
                key={f._id}
                as="a"
                href={`https://picton.blueboxonline.com/${f.file}`}
                style={{ display: 'flex', alignItems: 'center' }}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Icon name={fileIcon} style={{ marginRight: '0.2em' }} />
                {fileName}
              </List.Item>
            )
          })}
        </List>
      </div>
    ) : (
      <div>There are no files for this sale.</div>
    )}
  </Segment>
)

FilesList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
}
FilesList.defaultProps = {
  files: null,
}

export default FilesList
