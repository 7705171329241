import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import { connect } from 'react-redux'

class PrintA4Button extends Component {
  handleClick = () => {
    const { sale_id } = this.props
    window.open('https://picton.blueboxonline.com/?class=bb_picton_tablet_sales&method=a4print&global[noincludes]=print&global[sale_id]='+sale_id,'printme')
  }

  render() {
    return (
      <Button
        style={{fontSize:'9pt'}}
        content="A4"
        onClick={this.handleClick}
      />
    )
  }
}

PrintA4Button.propTypes = {
  sale_id: PropTypes.string.isRequired,
}

const mapState = state => ({
  sale_id: state.app.activeSale._apikey,
})

export default connect(mapState)(PrintA4Button)
