import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'

import { isEmpty } from 'validator'
import { connect } from 'react-redux'

import AddDocumentButton from './AddDocumentButton'
import AddHPEmbButton from './AddHPEmbButton'
import AddItemForm from './AddItemForm'
import AddPhotoButton from './AddPhotoButton'
import BorderlessTableCell from '../BorderlessTableCell'
import BorderlessTableRow from '../BorderlessTableRow'
import CategoryForm from './CategoryForm'
import EditableInput from '../EditableInput'
import EditableTextArea from '../EditableTextArea'
import ItemsTable from '../ItemsTable'
import FilesList from '../FilesList'
//import PayCashButton from '../PayCashButton'
//import PayCardButton from '../PayCardButton'
//import PayChequeButton from '../PayChequeButton'
import PaymentDetails from '../PaymentDetails'
//import PayOnAccountButton from '../PayOnAccountButton'
//import PayOverrideButton from '../PayOverrideButton'
import SaveSaleButton from '../SaveSaleButton'
//import PaymentModal from '../PaymentModal'
import SaleHeader from '../SaleHeader'
import SaleSummary from '../SaleSummary'

import { Checkbox } from 'semantic-ui-react'

import SignatureButton from './SignatureButton'
import withAuth from '../../lib/withAuth'

import {
  addSaleSignatureAction, addSaleItemAction, updateSaleAction, editSaleItemAction, loadSaleAction
  /*setPaymentModalIsOpenAction, setPaymentTypeAction, addPaymentAction,*/
} from '../../redux/reducers/app/actions'

class NewSaleDetailScreen extends Component {

	state = {
		autoUpdatingInProgress: false,
	}

  handleUpdateSale = (sale_id, data) => {
    const { updateSale } = this.props
    const {
      category, mobile, email, order_ref, delivery_address, notes, alert_name, toggle_backofficeYN, _datetime_queued
    } = data

    if(typeof _datetime_queued!='undefined' && _datetime_queued===1){
    	    localStorage.setItem('_datetime_queued', 1)
    }
    if(typeof _datetime_queued!='undefined' && _datetime_queued===0){
    	     this.setState({ autoUpdatingInProgress: false })
    	    localStorage.setItem('_datetime_queued', 0)
    }

    updateSale(sale_id, category, mobile, email, order_ref, delivery_address, notes, alert_name, toggle_backofficeYN, _datetime_queued)
  }

  handleAddSaleItem = (sale_id, item_id, option_1_id, option_2_id) => {
    const { addSaleItem } = this.props
    addSaleItem(sale_id, item_id, option_1_id, option_2_id)
  }

  handleDeleteSaleItem = (sale_id, line_id) => {
    const { editSaleItem } = this.props
    editSaleItem(sale_id, line_id, null, null, null, '1')
  }

  handleEditSaleItem = (sale_id, line_id, data) => {
    const { editSaleItem } = this.props
    const {
      notes, quantity, price, _delete, discount,
    } = data
    editSaleItem(sale_id, line_id, notes, quantity, price, _delete, discount)
  }

  handleAddSaleSignature = (sale_id, file, print_name) => {
    const { addSaleSignature } = this.props
    addSaleSignature(sale_id, file, print_name)
  }

  showStatus = (activeSale) => {
  	  const { loadSale } = this.props
  	  //console.log('cust:')
  	  //console.log(activeSale.customer)
  	  if(typeof activeSale.customer.status.OK!='undefined'){
  	  	  return(<div style={{color:'green'}} dangerouslySetInnerHTML={{__html: activeSale.customer.status.OK}}></div>)
  	  }else{
  	  	  if(typeof activeSale.customer.on_holdYN!='undefined' && activeSale.customer.on_holdYN*1===1){
  	  	  	  loadSale(activeSale._id)
  	  	  }
  	  	  return(<div style={{color:'red'}} dangerouslySetInnerHTML={{__html: activeSale.customer.status.error}}></div>)
  	  }
  }


  /*
  closePaymentModal = () => {
    const { setPaymentType, setPaymentModalIsOpen } = this.props
    setPaymentType(null)
    setPaymentModalIsOpen(false)
  }

  openPaymentModal = (paymentType) => {
    const { setPaymentType, setPaymentModalIsOpen } = this.props
    setPaymentType(paymentType)
    setPaymentModalIsOpen(true)
  }

  handleAddPayment = (sale_id, type, amount, ref) => {
    const { addPayment } = this.props
    addPayment(sale_id, type, amount, ref)
  }
  */

  render() {
    const { activeSale, isRequestInProgress, /*isPaymentModalOpen,*/ sale_categories/*, paymentType*/, permissions } = this.props

    const { autoUpdatingInProgress } = this.state

    const {
      _id,
      sale_completedYN,
      sales_orderISbb_sales_ordersID,
      second_sales_orderISbb_sales_ordersID,
      alert_email,
      alert_mobile,
      alert_name,
      customer: {
        first_name,
        last_name,
        mobile,
        email,
        //street_address,
	//street_address2,
	//street_suburb,
	//street_city,
	//street_zipcode
      },
      delivery_addressISsmallplaintextbox,
      customer_order_number,
      files,
      grand_totalCUR,
      items_totalCUR,
      tax_totalCUR,
      external_notesISsmallplaintextbox,
      payments,
      rows,
      sales_category,
      ship_date,
      transaction_date,
      total_owingCUR,
      stage_log,
      teller,
      _datetime_queued,
      toggle_backofficeYN,
    } = activeSale

    //const customer = `${first_name} ${last_name}`

    let localStorage_datetime_queued=localStorage.getItem('_datetime_queued')

    //console.log(localStorage_datetime_queued, autoUpdatingInProgress, _datetime_queued)

    if(!autoUpdatingInProgress && localStorage_datetime_queued*1===1 && !_datetime_queued){
    	    this.setState({ autoUpdatingInProgress: true })
    	    //console.log('auto-updating...')
    	    this.handleUpdateSale(_id, { _datetime_queued: 1 })
    }

    return (
      <Grid
        as={Segment}
        basic
        stackable
        loading={isRequestInProgress}
        style={{ padding: 0, margin: 0  }}
      >
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column width={6}>
            <SaleHeader
              sale_id={_id}
              sale_completedYN={sale_completedYN*1}
              text={grand_totalCUR ? "Edit Sale:" : "New Sale:"}
            />
          </Grid.Column>
          <Grid.Column width={10} textAlign="right">
            <SaleSummary
              itemCount={rows ? rows.length : 0}
              price={grand_totalCUR ? parseFloat(grand_totalCUR) : 0}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Grid.Column width={16}>
            <Table compact basic="very">
              <Table.Body>
                <BorderlessTableRow>
                  <BorderlessTableCell width={2} style={{ paddingBottom: 0, fontWeight: 700 }}>Order Ref#:</BorderlessTableCell>
                  <BorderlessTableCell width={6} style={{ paddingBottom: 0}}>
                    <EditableInput
                      defaultValue={customer_order_number || ''}
                      onSubmit={v => this.handleUpdateSale(_id, { order_ref: v })}
                      placeholder="Order #"
                      validationFunc={v => !isEmpty(v.trim())}
                    />
                  </BorderlessTableCell>
                  <BorderlessTableCell width={3} style={{ paddingBottom: 0, fontWeight: 700 }}>Category:</BorderlessTableCell>
                  <BorderlessTableCell width={5} style={{ paddingBottom: 0}}>
                    {(permissions.indexOf('Supervisor')!==-1 || permissions.indexOf('Tellers')!==-1 || permissions.indexOf('SuperAdmin')!==-1)?
                    <CategoryForm
                      categories={sale_categories}
                      onChange={v => this.handleUpdateSale(_id, { category: v })}
                      selectedValue={sales_category ? sales_category._id : null}
                    />
                    :
                    <span>{sales_category?sales_category.name:null}</span>
                  }
                  </BorderlessTableCell>
                </BorderlessTableRow>
                <BorderlessTableRow>
                  <BorderlessTableCell style={{ paddingBottom: 5, fontWeight: 700 }}>Backoffice Order:</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 5 }}>
                        <Checkbox
                        toggle
                        checked={toggle_backofficeYN*1===1 ? true : false}
                        onClick={v => this.handleUpdateSale(_id, { toggle_backofficeYN: (toggle_backofficeYN*1===1?0:1) })}
                        />
                  </BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 5, fontWeight: 700 }}>Send to Queue:</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 5 }}>
                        <Checkbox
                        toggle
                        checked={_datetime_queued>'' ? true : false}
                        onClick={v => this.handleUpdateSale(_id, { _datetime_queued: (_datetime_queued>''?0:1) })}
                        />
                  </BorderlessTableCell>
                </BorderlessTableRow>
                <BorderlessTableRow>
                  <BorderlessTableCell style={{ paddingBottom: 0, fontWeight: 700 }}>Customer:</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>{activeSale.customer.first_name} {activeSale.customer.last_name}</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0, fontWeight: 700 }}>Date of sale:</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>{transaction_date}</BorderlessTableCell>
                </BorderlessTableRow>
                <BorderlessTableRow>
                  <BorderlessTableCell style={{ paddingBottom: 0, fontWeight: 700 }}>Company:</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>{activeSale.customer.company_name} ({activeSale.customer.code})</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0, fontWeight: 700 }}>Date promised:</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>{ship_date}</BorderlessTableCell>
                </BorderlessTableRow>
                <BorderlessTableRow>
                  <BorderlessTableCell style={{ paddingBottom: 0, fontWeight: 700 }}>Street:</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>{activeSale.customer.street_address}</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0, fontWeight: 700 }}>Captured by:</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>{teller?teller:(stage_log?stage_log.so_created.stage_user:'')}</BorderlessTableCell>
                </BorderlessTableRow>
                <BorderlessTableRow>
                  <BorderlessTableCell style={{ paddingBottom: 0, fontWeight: 700 }}>Town:</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>{activeSale.customer.street_city}</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0, fontWeight: 700 }}>Mobile:</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>
                    <EditableInput
                      defaultValue={alert_mobile || mobile || ''}
                      onSubmit={v => this.handleUpdateSale(_id, { mobile: v })}
                      placeholder="Mobile"
                    />
                  </BorderlessTableCell>
                </BorderlessTableRow>
                <BorderlessTableRow>
                  <BorderlessTableCell style={{ paddingBottom: 0, fontWeight: 700 }}>Postcode:</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>{activeSale.customer.street_zipcode}</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0, fontWeight: 700 }}>Email:</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>
                    <EditableInput
                      defaultValue={alert_email || email || ''}
                      onSubmit={v => this.handleUpdateSale(_id, { email: v })}
                      placeholder="Email"
                    />
                  </BorderlessTableCell>
                </BorderlessTableRow>
                <BorderlessTableRow>
                  <BorderlessTableCell style={{ paddingBottom: 0, fontWeight: 700 }}></BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}></BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0, fontWeight: 700 }}>Cash Customer Name:</BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>
                    <EditableInput
                      defaultValue={alert_name || (first_name || '')+' '+(last_name || '') || ''}
                      onSubmit={v => this.handleUpdateSale(_id, { alert_name: v })}
                      placeholder="Customer Name"
                    />
                  </BorderlessTableCell>
                </BorderlessTableRow>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column width={16}>
            <AddItemForm addItem={this.handleAddSaleItem} />
            <ItemsTable
              items={rows}
              onDeleteSaleItem={this.handleDeleteSaleItem}
              onEditSaleItem={this.handleEditSaleItem}
              sale_id={_id}
              sales_orderISbb_sales_ordersID={sales_orderISbb_sales_ordersID}
              second_sales_orderISbb_sales_ordersID={second_sales_orderISbb_sales_ordersID}
              total_owing={total_owingCUR}
              items_totalCUR={items_totalCUR}
              tax_totalCUR={tax_totalCUR}
              grand_totalCUR={grand_totalCUR}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16} textAlign="right">
            <PaymentDetails amountOutstanding={total_owingCUR.toString()} payments={payments} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <div style={{ display: 'flex' }}>
              <>
                <SignatureButton
                  onAddSaleSignature={this.handleAddSaleSignature}
                  sale_id={_id}
                />
                <AddPhotoButton />
                <AddDocumentButton />
                <AddHPEmbButton />
              </>
              <div style={{ textAlign: 'right', flex: 1 }}>
              {/*
                <PayChequeButton onClick={this.openPaymentModal} />
                <PayCashButton onClick={this.openPaymentModal} />
                <PayCardButton onClick={this.openPaymentModal} />
                <PayOnAccountButton onClick={this.openPaymentModal} />
                <PayOverrideButton onClick={this.openPaymentModal} />
                <PaymentModal
                  amountOutstanding={total_owingCUR}
                  onSubmit={this.handleAddPayment}
                  onClose={this.closePaymentModal}
                  isOpen={isPaymentModalOpen}
                  paymentType={paymentType}
                  sale_id={_id}
                />
              */}
              </div>
            </div>
            <div style={{ textAlign: 'right', paddingTop: '20px'}}>
		          <SaveSaleButton sale_id={_id} customer_order_number={customer_order_number} customer_status={activeSale.customer.status} total_owingCUR={total_owingCUR*1} />
	          </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column stretched width={6}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ flex: 1, }}>
                <FilesList files={files} />
              </div>
              <Divider hidden />

            </div>
          </Grid.Column>
          <Grid.Column width={5} stretched verticalAlign="top">
            <EditableTextArea
              defaultValue={delivery_addressISsmallplaintextbox || ''}
              onSubmit={v => this.handleUpdateSale(_id, { delivery_address: v })}
              placeholder="Delivery Address"
              rows={6}
            />
            <Divider hidden />
            <EditableTextArea
              defaultValue={external_notesISsmallplaintextbox || ''}
              onSubmit={v => this.handleUpdateSale(_id, { notes: v })}
              placeholder="Order Notes"
              rows={6}
            />
          </Grid.Column>
          <Grid.Column width={5} stretched verticalAlign="top" style={{ textAlign: 'right'}}>
            <span>{this.showStatus(activeSale)}</span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

NewSaleDetailScreen.propTypes = {
  // The following props from redux:
  activeSale: PropTypes.object.isRequired,
  addPayment: PropTypes.func.isRequired,
  addSaleItem: PropTypes.func.isRequired,
  editSaleItem: PropTypes.func.isRequired,
  isPaymentModalOpen: PropTypes.bool.isRequired,
  isRequestInProgress: PropTypes.bool.isRequired,
  //paymentType: PropTypes.string,
  sale_categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  //setPaymentModalIsOpen: PropTypes.func.isRequired,
  //setPaymentType: PropTypes.func.isRequired,
  updateSale: PropTypes.func.isRequired,
}

const mapState = state => ({
  activeSale: state.app.activeSale,
  //isPaymentModalOpen: state.app.isPaymentModalOpen,
  isRequestInProgress: state.app.isRequestInProgress,
  //paymentType: state.app.paymentType,
  sale_categories: state.app.sale_categories,
  permissions: (state.auth.user?state.auth.user.permissions:null),
})
const mapDispatch = dispatch => ({
  //addPayment: (sale_id, type, amount, ref) => dispatch(addPaymentAction(sale_id, type, amount, ref)),
  addSaleItem: (sale_id, item_id, option_1_id, option_2_id) =>
    dispatch(addSaleItemAction(sale_id, item_id, option_1_id, option_2_id)),
  addSaleSignature: (sale_id, file, print_name) => dispatch(addSaleSignatureAction(sale_id, file, print_name)),
  editSaleItem: (sale_id, line_id, notes, quantity, price, _delete, discount) =>
    dispatch(editSaleItemAction(sale_id, line_id, notes, quantity, price, _delete, discount)),
  //setPaymentModalIsOpen: (value) => dispatch(setPaymentModalIsOpenAction(value)),
  //setPaymentType: (type) => dispatch(setPaymentTypeAction(type)),
  updateSale: (sale_id, category, mobile, email, order_ref, delivery_address, notes, alert_name, toggle_backofficeYN, _datetime_queued) =>
    dispatch(updateSaleAction(sale_id, category, mobile, email, order_ref, delivery_address, notes, alert_name, toggle_backofficeYN, _datetime_queued)),
  loadSale: sale_id => dispatch(loadSaleAction(sale_id)),
})

export default connect(mapState, mapDispatch)(withAuth(NewSaleDetailScreen))
