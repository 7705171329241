import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import { isEmpty } from 'validator'
import { connect } from 'react-redux'

import LoginErrorModal from './LoginErrorModal'
import { screens } from '../../redux/constants'
import { setScreenAction } from '../../redux/reducers/app/actions'
import { loginAction } from '../../redux/reducers/auth/actions'

class AppLoginForm extends Component {
  state = {
    email: '',
    password: '',
  }

  componentDidMount() {
    const { user, setScreen } = this.props
    if (user) setScreen(screens.NEW_SALE)
  }

  handleInputChange = (e, { name, value }) => this.setState({ [name]: value })

  handleSubmit = () => {
    const { login } = this.props
    const { email, password } = this.state

    // Basic form validation
    let isErrors = false
    /*
    if (isEmpty(email)) {
      this.setState({ isEmailError: true })
      isErrors = true
    }
    */
    if (isEmpty(password)) {
      this.setState({ isPasswordError: true })
      isErrors = true
    }
    if (isErrors) return

    login(email, password)
  }

  render() {
    const { isLoginInProgress } = this.props
    const { email, password  } = this.state

    return (
      <div>
        <LoginErrorModal />
        <Form onSubmit={this.handleSubmit}>
          <Form.Field>
            <Form.Input
              name="email"
              onChange={this.handleInputChange}
              placeholder="Email"
              autoComplete="username"
              value={email}
              onFocus = {() => this.setState({
                  email: '',
                })
              }
              /*required*/
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              name="password"
              onChange={this.handleInputChange}
              placeholder="Password"
              required
              autoComplete="current-password"
              type="password"
              value={password}
              onFocus = {() => this.setState({
                  password: '',
                })
              }
            />
          </Form.Field>
          <Form.Button
            type="submit"
            content="Login"
            loading={isLoginInProgress}
            primary
          />
        </Form>
      </div>
    )
  }
}

AppLoginForm.propTypes = {
  isLoginInProgress: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  setScreen: PropTypes.func.isRequired,
  user: PropTypes.object,
}
AppLoginForm.defaultProps = {
  user: null,
}

const mapState = state => ({
  isLoginInProgress: state.auth.isLoginInProgress,
  user: state.auth.user,
})
const mapDispatch = dispatch => ({
  login: (email, password) => dispatch(loginAction(email, password)),
  setScreen: screen => dispatch(setScreenAction(screen)),
})

export default connect(mapState, mapDispatch)(AppLoginForm)
