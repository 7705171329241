import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import { connect } from 'react-redux'
//import { isEmpty, isIP, isNumeric } from 'validator'

import { setTillNameAction, setPrinterIPAddressAction, setPrinterPortAction, setCardTerminalIDAction } from '../../redux/reducers/settings/actions'
import { setFlashMessageAction } from '../../redux/reducers/app/actions'
import { loadDataAction } from '../../redux/reducers/app/actions'

class SettingsForm extends Component {
  state = {
    printerIPAddress: this.props.printerIPAddress,
    printerIPAddressError: false,
    printerPort: this.props.printerPort,
    printerPortError: false,
    tillName: this.props.tillName,
    cardTerminalID: this.props.cardTerminalID,
    tillNameError: false,
    cardTerminalIDError: false,
  }

  handleInputChange = (e, { name, value }) => this.setState({ [name]: value })

  handleUpdateSettings = () => {
    const { setTillName, setCardTerminalID, setPrinterIPAddress, setPrinterPort, setFlashMessage, loadData } = this.props
    const { tillName, cardTerminalID, printerIPAddress, printerPort } = this.state

    let isValid = true

    /*
    if (isEmpty(cardTerminalID)) {
      this.setState({ cardTerminalIDError: true })
      isValid = false
    }
    */
    /*
    if (isEmpty(tillName)) {
      this.setState({ tillNameError: true })
      isValid = false
    }
    */
    /*
    if (!isIP(printerIPAddress)) {
      this.setState({ printerIPAddressError: true })
      isValid = false
    }
    if (isEmpty(printerPort) || !isNumeric(printerPort)) {
      this.setState({ printerPortError: true })
      isValid = false
    }
    */

    if (isValid) {
      this.setState({
        printerIPAddressError: false,
        printerPortError: false,
        tillNameError: false,
        cardTerminalIDError: false,
      }, () => {
        setPrinterIPAddress(printerIPAddress)
        setPrinterPort(printerPort)
        setTillName(tillName)
        setCardTerminalID(cardTerminalID)
        setFlashMessage({
          message: 'The settings have been updated.',
          type: 'positive',
        })
        setTimeout(function(){ loadData() }, 1000);
      })
    }
  }

  render() {
    const {
      tillName, tillNameError, printerIPAddress, printerIPAddressError,
      printerPort, printerPortError, cardTerminalID, cardTerminalIDError,
    } = this.state

    return (
      <Form widths="equal" onSubmit={this.handleUpdateSettings} >
        <Form.Group>
          <Form.Input
            error={tillNameError}
            label="Till name:"
            name="tillName"
            onChange={this.handleInputChange}
            placeholder="Till name"
            required
            value={tillName}
          />
          <Form.Input
            error={printerIPAddressError}
            label="Printer IP address:"
            name="printerIPAddress"
            onChange={this.handleInputChange}
            placeholder="Printer IP address"
            value={printerIPAddress}
          />
          <Form.Input
            error={printerPortError}
            label="Printer port:"
            name="printerPort"
            onChange={this.handleInputChange}
            placeholder="Printer port"
            value={printerPort}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            error={cardTerminalIDError}
            label="Card Terminal ID:"
            name="cardTerminalID"
            onChange={this.handleInputChange}
            placeholder="Card Terminal ID:"
            value={cardTerminalID}
          />
        </Form.Group>
        <Form.Button type="submit" content="Update settings" primary />
      </Form>

    )
  }
}

SettingsForm.propTypes = {
  // The following props from redux:
  printerIPAddress: PropTypes.string,
  printerPort: PropTypes.string,
  setFlashMessage: PropTypes.func.isRequired,
  setPrinterIPAddress: PropTypes.func.isRequired,
  setTillName: PropTypes.func.isRequired,
  setCardTerminalID: PropTypes.func.isRequired,
  tillName: PropTypes.string,
  cardTerminalID: PropTypes.string,
}
SettingsForm.defaultProps = {
  printerIPAddress: '',
  printerPort: '',
  tillName: '',
  cardTerminalID: '',
}

const mapState = state => ({
  printerIPAddress: state.settings.printerIPAddress,
  printerPort: state.settings.printerPort,
  tillName: state.settings.tillName,
  cardTerminalID: state.settings.cardTerminalID,
})
const mapDispatch = dispatch => ({
  setFlashMessage: m => dispatch(setFlashMessageAction(m)),
  setPrinterIPAddress: ip => dispatch(setPrinterIPAddressAction(ip)),
  setPrinterPort: port => dispatch(setPrinterPortAction(port)),
  setTillName: tillName => dispatch(setTillNameAction(tillName)),
  setCardTerminalID: cardTerminalID => dispatch(setCardTerminalIDAction(cardTerminalID)),
  loadData: () => dispatch(loadDataAction()),
})

export default connect(mapState, mapDispatch)(SettingsForm)
