import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'

import withAuth from '../../lib/withAuth'

import { setScreenAction} from '../../redux/reducers/app/actions'

import CustomerDetailsLine from './CustomerDetailsLine'

import { sendRequest } from '../../lib/api'

import Form from 'semantic-ui-react/dist/commonjs/collections/Form'



class CustomerDetails extends Component {

  state = {
    result: [],
    busy: false
  }

  handleBack = () => {
    const { setScreen } = this.props
    setScreen('NEW_SALE')
  }

  componentDidMount(){
    this.loadCustomerDetails()
  }

  async loadCustomerDetails(){
    const {session_id,searchCustomerDetailsText} = this.props
    this.setState({
      result: [],
      busy: true
    });
    const data = await sendRequest('search_customer_details',{session_id:session_id,search_string:searchCustomerDetailsText})
    console.log(data.search_results)
    this.setState({
      result: data.search_results,
      busy: false
    });
  }

  render() {
    const { permissions } = this.props

    return (
      <Dimmer.Dimmable as={Segment} dimmed={this.state.busy}>

          <Dimmer active={this.state.busy} inverted>
            <Loader>Loading</Loader>
          </Dimmer>

          <Form.Button type="button" primary content="Back" onClick={this.handleBack} />

          {this.state.result && this.state.result.constructor.name==="Array" && this.state.result.map((customer) => <CustomerDetailsLine permissions={permissions} key={customer._id} customer={customer}/>)}

          {this.state.result && typeof this.state.result==='string' && <Segment inverted color='red'>No results...</Segment>}

        </Dimmer.Dimmable>
    )
  }
}

const mapState = state => ({
  isRequestInProgress: state.app.isRequestInProgress,
  session_id: state.auth.user.session_id,
  searchCustomerDetailsText: state.app.searchCustomerDetailsText,
  permissions: (state.auth.user?state.auth.user.permissions:null),
})

const mapDispatch = dispatch => ({
  setScreen: screen => dispatch(setScreenAction(screen)),
})

export default connect(mapState,mapDispatch)(withAuth(CustomerDetails))
