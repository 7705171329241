import React, { Component } from 'react'
import List from 'semantic-ui-react/dist/commonjs/elements/List'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'

class SalePeopleList extends Component {
  render() {
   const { stage_log } = this.props
    return(<Segment>
	  <List>
	    <List.Item style={{fontSize:'8pt'}}>
	      <span style={{ fontWeight: 700 }}>SO Added by:</span> {typeof stage_log['so_created']!='undefined'?stage_log.so_created.stage_date+' '+stage_log.so_created.stage_user:'...'} 
	    </List.Item>
	    <List.Item style={{fontSize:'8pt'}}>
	      <span style={{ fontWeight: 700 }}>Purchased by:</span> {typeof stage_log['Purchased']!='undefined'?stage_log.Purchased.stage_date+' '+stage_log.Purchased.stage_user:'...'} 
	    </List.Item>
	    <List.Item style={{fontSize:'8pt'}}>
	      <span style={{ fontWeight: 700 }}>Counted in by:</span> {typeof stage_log['Counted In']!='undefined'?stage_log['Counted In'].stage_date+' '+stage_log['Counted In'].stage_user:'...'}
	    </List.Item>
	    <List.Item style={{fontSize:'8pt'}}>
	      <span style={{ fontWeight: 700 }}>Designed by:</span> {typeof stage_log['Sublimation Design']!='undefined'?stage_log['Sublimation Design'].stage_date+' '+stage_log['Sublimation Design'].stage_user:'...'}
	    </List.Item>
	    <List.Item style={{fontSize:'8pt'}}>
	      <span style={{ fontWeight: 700 }}>Printed by:</span> {typeof stage_log['Sublimation Print']!='undefined'?stage_log['Sublimation Print'].stage_date+' '+stage_log['Sublimation Print'].stage_user:'...'}
	    </List.Item>
	    <List.Item style={{fontSize:'8pt'}}>
	      <span style={{ fontWeight: 700 }}>Cut by:</span> {typeof stage_log['Cutting']!='undefined'?stage_log['Cutting'].stage_date+' '+stage_log['Cutting'].stage_user:'...'}
	    </List.Item>
	    <List.Item style={{fontSize:'8pt'}}>
	      <span style={{ fontWeight: 700 }}>Pressed by:</span> {typeof stage_log['Pressing']!='undefined'?stage_log['Pressing'].stage_date+' '+stage_log['Pressing'].stage_user:'...'}
	    </List.Item>
	    <List.Item style={{fontSize:'8pt'}}>
	      <span style={{ fontWeight: 700 }}>Embroidered by:</span> {typeof stage_log['Embroidery']!='undefined'?stage_log['Embroidery'].stage_date+' '+stage_log['Embroidery'].stage_user:'...'}
	    </List.Item>
	    <List.Item style={{fontSize:'8pt'}}>
	      <span style={{ fontWeight: 700 }}>Heat pressed by:</span> {typeof stage_log['Heat Press']!='undefined'?stage_log['Heat Press'].stage_date+' '+stage_log['Heat Press'].stage_user:'...'}
	    </List.Item>
	    <List.Item style={{fontSize:'8pt'}}>
	      <span style={{ fontWeight: 700 }}>Quality checked by:</span> {typeof stage_log['Quality Control']!='undefined'?stage_log['Quality Control'].stage_date+' '+stage_log['Quality Control'].stage_user:'...'}
	    </List.Item>
	    <List.Item style={{fontSize:'8pt'}}>
	      <span style={{ fontWeight: 700 }}>Dispatched by:</span> {typeof stage_log['Delivery']!='undefined'?stage_log.Delivery.stage_date+' '+stage_log.Delivery.stage_user:'...'}
	    </List.Item>
	  </List>
	</Segment>);
  }
}

export default SalePeopleList
