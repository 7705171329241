import React from 'react'
import PropTypes from 'prop-types'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'

const SignatureBlock = ({ imageURL }) => (
  <Segment>
    <Header as="h3">Signed:</Header>
    {imageURL ? (
      <Image
        alt="Signature"
        fluid
        src={`https://picton.blueboxonline.com/${imageURL}`}
      />
    ) : (
      <Message negative>No signature file found.</Message>
    )}
  </Segment>
)

SignatureBlock.propTypes = {
  imageURL: PropTypes.string,
}
SignatureBlock.defaultProps = {
  imageURL: null,
}

export default SignatureBlock
