import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import { isEmpty, isEmail } from 'validator'
import { connect } from 'react-redux'

import { addCustomerAction } from '../../redux/reducers/app/actions'

class NewCustomerForm extends Component {
  state = {
    firstName: '',
    isFirstNameError: false,
    lastName: '',
    isLastNameError: false,
    companyName: '',
    isCompanyNameError: false,
    email: '',
    isEmailError: false,
    mobile: '',
    isMobileError: false,
  }

  handleInputChange = (e, { name, value }) => this.setState({ [name]: value })

  handleSubmit = () => {
    const { addCustomer } = this.props
    const { firstName, lastName, companyName, email, mobile } = this.state

    let isFormErrors = false
    // Form validation
    if (isEmpty(firstName)) {
      this.setState({ isFirstNameError: true })
      isFormErrors = true
    }
    if (isEmpty(lastName)) {
      this.setState({ isLastNameError: true })
      isFormErrors = true
    }
    if (isEmpty(email) || !isEmail(email)) {
      this.setState({ isEmailError: true })
      isFormErrors = true
    }
    if (isEmpty(mobile)) {
      this.setState({ isMobileError: true })
      isFormErrors = true
    }
    if (isFormErrors) return

    // add_customer
    addCustomer(firstName, lastName, companyName, email, mobile)
  }

  render() {
    const {
      firstName, isFirstNameError,
      lastName, isLastNameError,
      companyName, isCompanyNameError,
      email, isEmailError,
      mobile, isMobileError,
    } = this.state

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Input
          error={isFirstNameError}
          label="First name:"
          name="firstName"
          onChange={this.handleInputChange}
          placeholder="First name"
          required
          value={firstName}
        />
        <Form.Input
          error={isLastNameError}
          label="Last name:"
          name="lastName"
          onChange={this.handleInputChange}
          placeholder="Last name"
          required
          value={lastName}
        />
        <Form.Input
          error={isCompanyNameError}
          label="Company name:"
          name="companyName"
          onChange={this.handleInputChange}
          placeholder="Company name"
          value={companyName}
        />
        <Form.Input
          error={isEmailError}
          label="Email:"
          name="email"
          onChange={this.handleInputChange}
          placeholder="Email"
          required
          value={email}
        />
        <Form.Input
          error={isMobileError}
          label="Mobile:"
          name="mobile"
          onChange={this.handleInputChange}
          placeholder="Mobile"
          required
          value={mobile}
        />
        <Form.Button type="submit" primary content="Capture Sale" />
      </Form>
    )
  }
}

NewCustomerForm.propTypes = {
  // The following props from redux:
  addCustomer: PropTypes.func.isRequired,
}

const mapDispatch = dispatch => ({
  addCustomer: (firstName, lastName, companyName, email, mobile) =>
    dispatch(addCustomerAction(firstName, lastName, companyName, email, mobile)),
})

export default connect(null, mapDispatch)(NewCustomerForm)