import React from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'

const PayOverrideButton = ({ onClick }) => (
  <Button
    compact
    color='orange'
    content="O/Ride"
    onClick={() => onClick('override')}
  />
)

PayOverrideButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default PayOverrideButton