import React from "react";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import { connect } from "react-redux";

const MenuLogo = ({ tillName }) => (
  <span>
    <Menu.Item header>
      PICTON SPORTS - Sales App{" "}
      <span className="ui label small black">v { process.env.REACT_APP_VERSION }</span>{" "}
      <span style={{ marginLeft: "15px" }}>
        Till:{` `}
        {tillName}
      </span>
    </Menu.Item>
  </span>
);

const mapState = state => ({
  tillName: state.settings.tillName
});

MenuLogo.defaultProps = {
  tillName: "NOT-SET"
};

export default connect(mapState)(MenuLogo);
