import React, { Component } from 'react'
import PropTypes from 'prop-types'

import EmbroideryNameForm from './EmbroideryNameForm'

class EmbroideryNamesForm extends Component {
  handleItemChange = (item) => {
    const { handleChange } = this.props
    handleChange(item)
  }

  handleItemDelete = (item) => {
    const { handleDelete } = this.props
    handleDelete(item)
  }

  render() {
    const { items } = this.props

    return (
      <div>
        {items.map(i => (
          <EmbroideryNameForm
            key={i.id}
            handleChange={this.handleItemChange}
            handleDelete={this.handleItemDelete}
            name={i}
          />
        ))}
      </div>
    )
  }
}

EmbroideryNamesForm.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
}

export default EmbroideryNamesForm
