import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import { connect } from 'react-redux'

import { allocateStockAction } from '../../redux/reducers/app/actions'

class AllocateStockButton extends Component {
  handleClick = () => {
    const { allocateStock, sale_id } = this.props
    allocateStock(sale_id)
  }

  render() {
    return (
      <Button onClick={this.handleClick}>Alloc. Stock</Button>
    )
  }
}

AllocateStockButton.propTypes = {
  sale_id: PropTypes.string.isRequired,

  // The following props from redux:
  allocateStock: PropTypes.func.isRequired,
}

const mapDispatch = dispatch => ({
  allocateStock: (sale_id) => dispatch(allocateStockAction(sale_id)),
})

export default connect(null, mapDispatch)(AllocateStockButton)