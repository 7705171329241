import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import { connect } from 'react-redux'

import { screens } from '../../redux/constants'
import { setScreenAction } from '../../redux/reducers/app/actions'

class SettingsLoginForm extends Component {
  state = {
    isPasswordError: false,
    password: '',
  }

  handleInputChange = (e, { name, value }) => this.setState({ [name]: value })

  handleSubmit = () => {
    const { /*settingsPassword,*/ setScreen } = this.props
    const { password } = this.state
    if (password === 'pictonsettings') {
      setScreen(screens.SETTINGS)
    } else {
      this.setState({ isPasswordError: true })
    }
  }

  render() {
    const { isPasswordError, password } = this.state

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group>
          <Form.Input
            error={isPasswordError}
            name="password"
            onChange={this.handleInputChange}
            placeholder="Password"
            required
            type="password"
            value={password}
          />
          <Form.Button type="submit" content="Go" primary />
        </Form.Group>
      </Form>
    )
  }
}

SettingsLoginForm.propTypes = {
  settingsPassword: PropTypes.string.isRequired,
  setScreen: PropTypes.func.isRequired,
}

const mapState = state => ({
  settingsPassword: state.settings.password
})
const mapDispatch = dispatch => ({
  setScreen: (screen) => dispatch(setScreenAction(screen)),
})

export default connect(mapState, mapDispatch)(SettingsLoginForm)
