import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'

import ConfirmDespatchButton from './ConfirmDespatchButton'
import ItemRow from './ItemRow'
import SignatureBlock from './SignatureBlock'
import SignatureButton from '../../NewSaleDetailScreen/SignatureButton'

import { connect } from 'react-redux'
import withAuth from '../../../lib/withAuth'

import {
  addSaleSignatureAction
} from '../../../redux/reducers/app/actions'

class DespatchModal extends Component {

  state = {
    items: [],
  }

  componentDidMount() {
    this.initItems()
  }

  initItems = () => {
    const { items } = this.props
    if (items) {
      this.setState({
        items: items.map(i => ({
          ...i,
          quantityToDespatch: parseInt(i.qty_allocatedNUM),// was 0 - before it was parseInt(i.qty_allocatedNUM) - parseInt(i.qty_despatchedNUM)
        }))
      })
    }
  }

  handleItemQuantityChange = (item, value) => {
    this.setState(state => ({
      items: state.items.map(i => {
        if (i._id === item._id) {
          return {
            ...i,
            quantityToDespatch: value,
          }
        }
        return i
      })
    }))
  }

  handleAddSaleSignature = (sale_id, file, print_name) => {
    const { addSaleSignature } = this.props
    addSaleSignature(sale_id, file, print_name, 'despatch')
  }

  render() {


    const { isOpen, handleClose, sale_id, signatureImageURL, activeSale, stage_name } = this.props
    const { items } = this.state

    const {
      _id,
    } = activeSale

    if (!items || items.length === 0) return null

    const totalToDespatch = items.reduce((count, i) => count + i.quantityToDespatch, 0)
    const buttonIsDisabled = totalToDespatch < 1

    return (
      <Modal
        onClose={handleClose}
        open={isOpen}
      >
        <Modal.Header>Despatch</Modal.Header>
        <Modal.Content scrolling>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>SKU</Table.HeaderCell>
                <Table.HeaderCell>Item</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map(i => (
                <ItemRow
                  key={i._id}
                  handleItemQuantityChange={this.handleItemQuantityChange}
                  item={i}
                />
              ))}
            </Table.Body>
          </Table>
          <SignatureButton
            onAddSaleSignature={this.handleAddSaleSignature}
            sale_id={_id}
            stage_name={stage_name?stage_name:''}
          />
          <SignatureBlock imageURL={signatureImageURL} />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleClose}>Cancel</Button>
          <ConfirmDespatchButton
            sale_id={sale_id}
            isDisabled={signatureImageURL?buttonIsDisabled:1}
            items={items}
            onSubmit={handleClose}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

DespatchModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  sale_id: PropTypes.string.isRequired,
  signatureImageURL: PropTypes.string
}
DespatchModal.defaultProps = {
  items: null,
  signatureImageURL: null,
}
const mapDispatch = dispatch => ({
  addSaleSignature: (sale_id, file, print_name, sigtype) => dispatch(addSaleSignatureAction(sale_id, file, print_name, sigtype)),
})
const mapState = state => ({
  activeSale: state.app.activeSale,
})

export default connect(mapState, mapDispatch)(withAuth(DespatchModal))
