import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Input from 'semantic-ui-react/dist/commonjs/elements/Input'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import { isInt } from 'validator'

class QuantityInput extends Component {
  state = {
    value: this.props.value,
    valueError: false,
  }

  handleDecrement = () => {
    const { value } = this.state
    if (typeof value === 'string') {
      if (!isInt(value)) return
    }
    this.handleChange(null, { value: value - 1 })
  }

  handleIncrement = () => {
    const { value } = this.state
    if (typeof value === 'string') {
      if (!isInt(value)) return
    }
    this.handleChange(null, { value: value + 1 })
  }

  handleChange = (e, { value }) => {
    const { onChange, onZero } = this.props

    const intValue = parseInt(value)
    if (isNaN(intValue)) {
      this.setState({
        value,
        valueError: true,
      })
      return
    }

    if (intValue < 0) return
    if (intValue>this.props.value) return

    this.setState({ value: intValue, valueError: false }, () => {
      if (intValue === 0) {
        onZero(0)
      } else {
        onChange(intValue)
      }
    })
  }

  render() {
    const { value, valueError } = this.state

    return (
      <div>
        <Input
          error={valueError}
          labelPosition="right"
          onChange={this.props.value===0?false:this.handleChange}
          value={value}
        >

          <Label
            onClick={this.props.value===0?false:this.handleDecrement}
            style={{ cursor: 'pointer' }}
          >
            <Icon
              fitted
              name="minus"
              style={{
                position: 'relative',
                top: 2,
              }}
            />
          </Label>
          <input disabled={this.props.value===0?true:false} style={{ textAlign: 'center', width: 44 }} />
          <Label
            onClick={this.props.value===0?false:this.handleIncrement}
            style={{ cursor: 'pointer' }}
          >
            <Icon
              fitted
              name="plus"
              style={{
                position: 'relative',
                top: 2,
              }}
            />
          </Label>
        </Input>
      </div>
    )
  }
}

QuantityInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onZero: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
}

export default QuantityInput
