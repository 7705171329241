import React from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'

const PayChequeButton = ({ onClick }) => (
  <Button
    compact
    color='green'
    content="Chq"
    onClick={() => onClick('cheque')}
  />
)

PayChequeButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default PayChequeButton
