import axios from 'axios'

/*
Posts to https://picton.blueboxonline/?api

Example data parameter:
{
  session_id,
  sale_id,
  files: [
    { file: file, filename: 'signature.png' },
  ]
}

qs is extra querystring parts appended as `&{$qs}`.
*/
export const sendRequest = async (api_method, data = null, qs = null) => {
  const { REACT_APP_API_KEY, REACT_APP_API_SECRET, REACT_APP_API_URL } = process.env

  const formData = new FormData()

  formData.append('apikey', REACT_APP_API_KEY)
  formData.append('apisecret', REACT_APP_API_SECRET)
  formData.append('api_method', api_method)
  
  if (data) {
    Object.keys(data).forEach(k => {
      if (k === 'files') {
        data[k].forEach((f, i) => {
          formData.append(`global[files][${i}]`, f.file, f.filename)
        })
      } else if (k === 'hpemb' ) {
        Object.keys(data[k]).forEach(kk => {
          if (typeof data[k][kk] === 'object' && data[k][kk].hasOwnProperty('rows')) {
            // has rows
            data[k][kk].rows.forEach((r, i) => {
              Object.keys(r).forEach(property => {
                formData.append(`hpemb[${kk}][rows][${i}][${property}]`, r[property])
              })
            })
          } else {
            formData.append(`hpemb[${kk}]`, data[k][kk])
          }
        })
      } else if (k === 'lines') {
        data[k].forEach((l, i) => {
          formData.append(`lines[${i}][_id]`, l._id)
          formData.append(`lines[${i}][qty_despatchedNUM]`, l.qty_despatchedNUM)
        })
      } else {
        let value = data[k]
        formData.append(k, value)
      }
    })
  }

  const url = qs ? `${REACT_APP_API_URL}&${qs}` : REACT_APP_API_URL
  // console.log(api_method, url, data)

  try {
    const response = await axios.post(
      url,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    // console.log(response)
    return response.data
  } catch (e) {
    if (e.response) {
      return {
        error: true,
        message: e.response.data.error,
        status: e.response.status,
      }
    } else if (e.request) {
      return {
        error: true,
        // message: e.request,
        message: 'The request was made but no response was received.',
      }
    } else {
      return {
        error: true,
        message: e.message,
      }
    }
  }
}
