import React, { Component } from 'react'
import { connect } from 'react-redux'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'

import SearchResultsModal from '../NewSaleScreen/SearchResultsModal'
import withAuth from '../../lib/withAuth'
import QrReader from 'react-qr-reader'

import { searchSalesAction } from '../../redux/reducers/app/actions'

class ScanCodeScreen extends Component {

  state = {
    result: ''
  }

  handleScan = data => {
    const { searchSales } = this.props
    if (data) {
      this.setState({
        result: data
      })
      searchSales(data)
    }
  }
  handleError = err => {
    console.error(err)
  }

  render() {
    const { isRequestInProgress } = this.props

    return (
      <div>
        <SearchResultsModal />
        <Segment as={Grid} basic stackable loading={isRequestInProgress}>
          <Grid.Row>
            <Grid.Column width={8}>

            <QrReader
              delay={300}
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: '100%' }}
            />
            <p>{this.state.result}</p>


            </Grid.Column>
          </Grid.Row>
        </Segment>
      </div>
    )
  }
}

const mapState = state => ({
  isRequestInProgress: state.app.isRequestInProgress,
})

const mapDispatch = dispatch => ({
  searchSales: q => dispatch(searchSalesAction(q)),
})

export default connect(mapState,mapDispatch)(withAuth(ScanCodeScreen))
