import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'

class CategoryForm extends Component {
  handleChange = (e, { value }) => {
    const { onChange } = this.props
    onChange(value)
  }

  render() {
    const { categories, selectedValue } = this.props

    const options = categories.map(c => ({
      key: c._id,
      value: c._id,
      text: c.name,
    }))

    return (
      <Form>
        <Form.Dropdown
          fluid
          inline
          onChange={this.handleChange}
          options={options}
          placeholder="Not set"
          search
          selection
          size="small"
          value={selectedValue}
        />
      </Form>
    )
  }
}

CategoryForm.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
}
CategoryForm.defaultProps = {
  selectedValue: null,
}

export default CategoryForm