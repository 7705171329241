import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

class EditableInput extends Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  state = {
    error: false,
    isEditing: false,
    value: this.props.defaultValue,
  }

  handleChange = (e) => this.setState({ value: e.target.value })

  handleEdit = () => {
    this.setState({ isEditing: true }, () => this.inputRef.current.focus())
  }

  handleEditComplete = () => {
    const { defaultValue, onSubmit, validationFunc } = this.props
    const { value } = this.state

    if (!validationFunc(value)) {
      this.setState({ error: true })
      return
    }

    this.setState({ error: false, isEditing: false }, () => {
      if (value !== defaultValue) onSubmit(value)
    })
  }

  keyPress = (e) => {
    if (e.keyCode === 13) this.handleEditComplete()
  }

  render() {
    const { formatValue, placeholder, textAlign, width } = this.props
    const { error, isEditing, value } = this.state

    return (
      <div
        onClick={isEditing ? this.handleEditComplete : this.handleEdit}
        style={{ cursor: 'pointer', display: 'flex' }}
      >
        <input
          disabled={!isEditing}
          onChange={this.handleChange}
          onKeyDown={this.keyPress}
          placeholder={placeholder}
          ref={this.inputRef}
          style={{
            backgroundColor: error ? '#fff6f6' : null,
            borderBottom: !isEditing ? '1px dashed #c8c8c8' : null,
            borderColor: error ? '#e0b4b4' : null,
            borderLeft: 0,
            borderRight: 0,
            borderTop: 0,
            color: error ? '#9f3a38' : null,
            cursor: !isEditing ? 'pointer' : null,
            flex: 1,
            textAlign,
            width,
          }}
          value={isEditing ? value : formatValue(value)}
        />
        <Icon
          color={isEditing ? 'green' : null}
          name={isEditing ? 'check' : 'edit'}
          size="large"
          style={{
            cursor: 'pointer',
            marginLeft: '0.4rem',
          }}
        />
      </div>
    )
  }
}

EditableInput.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formatValue: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  textAlign: PropTypes.string,
  validationFunc: PropTypes.func,
  width: PropTypes.number,
}
EditableInput.defaultProps = {
  defaultValue: '',
  formatValue: v => v,
  placeholder: '',
  textAlign: 'left',
  validationFunc: v => true,
  width: null,
}

export default EditableInput
