import React from 'react'
import PropTypes from 'prop-types'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'
import { connect } from 'react-redux'

import QuantityInput from './QuantityInput'

const ItemRow = ({ handleItemQuantityChange, item, products, product_options }) => {
    // look up parent product
    const product = products.find(p => {
      return p._id === item.itemISbb_item_dataID
    })
    let description = product ? product.name : 'N/A'

    // look up item
    const option = product_options.find(o => {
      return (o.item_id === item.itemISbb_item_dataID
        && o.option_1_id === item.option1ISbb_item_data_options1ID
        && o.option_2_id === item.option2ISbb_item_data_options2ID)
    })
    const sku = option ? option.code : 'N/A'
    if (option) description += ` ${option.name}`

    const {
      qty_allocatedNUM,
      //qty_despatchedNUM,
      //quantityToDespatch
    } = item

    // console.log(item)

    return (
      <Table.Row style={{background:(qty_allocatedNUM===0?'#ffcccc':'white')}}>
        <Table.Cell>{sku}</Table.Cell>
        <Table.Cell>{description}</Table.Cell>
        <Table.Cell>
          <QuantityInput
            onChange={value => handleItemQuantityChange(item, value)}
            onZero={value => handleItemQuantityChange(item, value)}
            value={qty_allocatedNUM}
          />
        </Table.Cell>
      </Table.Row>
    )
}

ItemRow.propTypes = {
  handleItemQuantityChange: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,

  // The following props from redux:
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  product_options: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const mapState = state => ({
  products: state.app.products,
  product_options: state.app.product_options,
})

export default connect(mapState, null)(ItemRow)
