import React from 'react'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'

import SettingsHeader from '../SettingsHeader'
import ReloadDataForm from './ReloadDataForm'
import SettingsForm from './SettingsForm'

const SettingsScreen = () => (
  <div>
    <SettingsHeader />
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={16}>
          <SettingsForm />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16} textAlign="right">
          <ReloadDataForm />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
)

export default SettingsScreen
