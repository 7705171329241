import React from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

const AddItemButton = ({ handleClick, buttonText }) => (
  <Button
    icon
    labelPosition="right"
    onClick={handleClick}
    secondary
    size="small"
    style={{ marginLeft: '1rem' }}
  >
    {buttonText}
    <Icon name="plus" />
  </Button>
)

AddItemButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default AddItemButton
