import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { screens } from '../redux/constants'
import { setScreenAction } from '../redux/reducers/app/actions'
import { validateSessionAction } from '../redux/reducers/auth/actions'

const withAuth = WrappedComponent => {
  WrappedComponent.propTypes = {
    setScreen: PropTypes.func.isRequired,
    user: PropTypes.object,
  }
    WrappedComponent.defaultProps = {
    user: null,
  }

  return class extends Component {
    async componentDidMount() {
      const { setScreen, user, validateSession } = this.props
      if (!user) setScreen(screens.APP_LOGIN)
      validateSession()
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

}

withAuth.propTypes = {
  setScreen: PropTypes.func.isRequired,
  user: PropTypes.object,
  validateSession: PropTypes.func.isRequired,
  WrappedComponent: PropTypes.element,
}
withAuth.defaultProps = {
  user: null,
}

const mapState = state => ({
  user: state.auth.user,
})
const mapDispatch = dispatch => ({
  setScreen: screen => dispatch(setScreenAction(screen)),
  validateSession: () => dispatch(validateSessionAction()),
})

export default compose(
  connect(mapState, mapDispatch),
  withAuth,
)
