import React from 'react'
import PropTypes from 'prop-types'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'

const ItemsTableFooter = ({ isEditable, vatTotal, orderTotal }) => (
  <Table.Footer>
    <Table.Row>
      {isEditable && <Table.HeaderCell />}
      <Table.HeaderCell />
      <Table.HeaderCell />
      <Table.HeaderCell />
      <Table.HeaderCell />
      <Table.HeaderCell />
      {!isEditable && (
        <div>
          <Table.HeaderCell />
          <Table.HeaderCell />
        </div>
      )}
      <Table.HeaderCell />
      <Table.HeaderCell textAlign="right">{parseFloat(vatTotal).toFixed(2)}</Table.HeaderCell>
      <Table.HeaderCell textAlign="right" style={{ fontWeight: 700, fontSize: '1.2em' }}>
        {parseFloat(orderTotal).toFixed(2)}
      </Table.HeaderCell>
    </Table.Row>
  </Table.Footer>
)

ItemsTableFooter.propTypes = {
  isEditable: PropTypes.bool,
  orderTotal: PropTypes.number.isRequired,
  vatTotal: PropTypes.number.isRequired,
}
ItemsTableFooter.defaultProps = {
  isEditable: true,
}

export default ItemsTableFooter
