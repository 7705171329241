import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import { connect } from 'react-redux'
import maxBy from 'lodash/maxBy'
import Confirm from 'semantic-ui-react/dist/commonjs/addons/Confirm'

import HPEmbForm from './HPEmbForm'
import { addSaleHPEmbAction } from '../../../redux/reducers/app/actions'

class HPEmbModal extends Component {
  state = {
    embroideryLogos: this.props.embroideryLogos,
    heatPressLogos: this.props.heatPressLogos,
    embroideryNames: this.props.embroideryNames,
    heatPressNames: this.props.heatPressNames,
    sublimations: this.props.sublimations,
    isConfirmFormIncompleteOpen: false,
  }

  handleCloseConfirmFormIncomplete = () => this.setState({ isConfirmFormIncompleteOpen: false })

  handleChangeItem = (property, item) => {
    this.setState(state => ({
      ...state,
      [property]: state[property].map(i => {
        if (item.id === i.id) return item
        return i
      })
    }))
  }

  handleAddItem = (property, newItem) => {
    this.setState(state => {
      let maxId = -1
      if (state[property].length > 0) {
        maxId = maxBy(state[property], 'id').id
      }
      return {
        ...state,
        [property]: [
          ...state[property],
          {
            ...newItem,
            id: maxId + 1,
          },
        ],
      }
    })
  }

  handleDeleteItem = (property, item) => {
    this.setState(state => ({
      ...state,
      [property]: state[property].filter(l => item.id !== l.id),
    }))
  }

  handleSubmit = () => {
    const { addSaleHPEmb, sale_id } = this.props
    let hpemb_form_err=0;
    let ixx=0
    let group_count=0
    if(this.state.embroideryLogos.length>0){
    	    for(ixx in this.state.embroideryLogos){
    	    	    if(!this.state.embroideryLogos[ixx].text){
    	    	    	    hpemb_form_err=1
    	    	    }
    	    	    if(this.state.embroideryLogos[ixx].location===null || this.state.embroideryLogos[ixx].location==='null' || this.state.embroideryLogos[ixx].location==='undefined'){
    	    	    	    hpemb_form_err=1
    	    	    }
    	    }
    	    if(!hpemb_form_err){
    	    	    group_count++
    	    }
    }
    if(this.state.embroideryNames.length>0){
    	    for(ixx in this.state.embroideryNames){
    	    	    if(!this.state.embroideryNames[ixx].text){
    	    	    	    hpemb_form_err=1
    	    	    }
    	    	    if(this.state.embroideryNames[ixx].location===null || this.state.embroideryNames[ixx].location==='null' || this.state.embroideryNames[ixx].location==='undefined'){
    	    	    	    hpemb_form_err=1
    	    	    }
    	    	    if(this.state.embroideryNames[ixx].size===null || this.state.embroideryNames[ixx].size==='null' || this.state.embroideryNames[ixx].size==='undefined'){
    	    	    	    hpemb_form_err=1
    	    	    }
    	    	    if(typeof(this.state.embroideryNames[ixx].color)==='undefined' || this.state.embroideryNames[ixx].color===null || this.state.embroideryNames[ixx].color==='null' || this.state.embroideryNames[ixx].color==='undefined'){
    	    	    	    hpemb_form_err=1
    	    	    }
    	    }
    	    if(!hpemb_form_err){
    	    	    group_count++
    	    }
    }
    if(this.state.heatPressLogos.length>0){
    	    for(ixx in this.state.heatPressLogos){
    	    	    if(!this.state.heatPressLogos[ixx].text){
    	    	    	    hpemb_form_err=1
    	    	    }
    	    	    if(this.state.heatPressLogos[ixx].location===null){
    	    	    	    hpemb_form_err=1
    	    	    }
    	    }
    	    if(!hpemb_form_err){
    	    	    group_count++
    	    }
    }
    if(this.state.heatPressNames.length>0){
    	    for(ixx in this.state.heatPressNames){
    	    	    if(!this.state.heatPressNames[ixx].text){
    	    	    	    hpemb_form_err=1
    	    	    }
    	    	    if(this.state.heatPressNames[ixx].location===null){
    	    	    	    hpemb_form_err=1
    	    	    }
    	    	    if(this.state.heatPressNames[ixx].size===null || this.state.heatPressNames[ixx].size==='null' || this.state.heatPressNames[ixx].size==='undefined'){
    	    	    	    hpemb_form_err=1
    	    	    }
    	    	    if(typeof(this.state.heatPressNames[ixx].color)==='undefined' || this.state.heatPressNames[ixx].color===null || this.state.heatPressNames[ixx].color==='null' || this.state.heatPressNames[ixx].color==='undefined'){
    	    	    	    hpemb_form_err=1
    	    	    }
    	    }
    	    if(!hpemb_form_err){
    	    	    group_count++
    	    }
    }
    if(this.state.sublimations.length>0){
    	    for(ixx in this.state.sublimations){
    	    	    if(!this.state.sublimations[ixx].text){
    	    	    	    hpemb_form_err=1
    	    	    }
    	    	    if(this.state.sublimations[ixx].size===null){
    	    	    	    hpemb_form_err=1
    	    	    }
    	    }
    	    if(!hpemb_form_err){
    	    	    group_count++
    	    }
    }
    if(hpemb_form_err===1 || group_count===0){
	    this.setState({ isConfirmFormIncompleteOpen: true })
	    return
    }
    addSaleHPEmb(sale_id, this.state)
  }

  handleClose = () => {
    const { handleClose } = this.props
    // reset state
    this.setState({
      embroideryLogos: this.props.embroideryLogos,
      heatPressLogos: this.props.heatPressLogos,
      embroideryNames: this.props.embroideryNames,
      heatPressNames: this.props.heatPressNames,
      sublimations: this.props.sublimations,
    })
    handleClose()
  }

  render() {
    const { isOpen, isRequestInProgress } = this.props
    const {
      embroideryLogos,
      heatPressLogos,
      embroideryNames,
      heatPressNames,
      sublimations,
      isConfirmFormIncompleteOpen
    } = this.state


    return (
    	    <div>
      <Modal
        open={isOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>Add Heat Press / Embroidery</Modal.Header>
        <Modal.Content scrolling>
          <HPEmbForm
            embroideryLogos={embroideryLogos}
            heatPressLogos={heatPressLogos}
            embroideryNames={embroideryNames}
            heatPressNames={heatPressNames}
            sublimations={sublimations}
            handleAddItem={this.handleAddItem}
            handleChangeItem={this.handleChangeItem}
            handleDeleteItem={this.handleDeleteItem}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            loading={isRequestInProgress}
            onClick={this.handleSubmit}
            primary
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal>

      <Confirm
          content={(
            <Modal.Content>
              <span>Please complete all fields in this form...</span>
            </Modal.Content>
          )}
          header="Form is incomplete"
          onCancel={this.handleCloseConfirmFormIncomplete}
          onConfirm={this.handleCloseConfirmFormIncomplete}
          open={isConfirmFormIncompleteOpen}
        />
        </div>
    )
  }
}

HPEmbModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,

  // The following props from redux:
  addSaleHPEmb: PropTypes.func.isRequired,
  embroideryLogos: PropTypes.arrayOf(PropTypes.object).isRequired,
  heatPressLogos: PropTypes.arrayOf(PropTypes.object).isRequired,
  isRequestInProgress: PropTypes.bool.isRequired,
  embroideryNames: PropTypes.arrayOf(PropTypes.object).isRequired,
  heatPressNames: PropTypes.arrayOf(PropTypes.object).isRequired,
  sale_id: PropTypes.string.isRequired,
  sublimations: PropTypes.arrayOf(PropTypes.object).isRequired,
}
const mapState = state => {
  // hpemb properties come as objects from the API, so we convert them to arrays
  // We also add an id to use as a key for react

  let embroideryLogos = []
  let heatPressLogos = []
  let embroideryNames = []
  let heatPressNames = []
  let sublimations = []

  if (state.app.activeSale.hpemb) {
    if (state.app.activeSale.hpemb.embroidery_logos) {
      embroideryLogos = Object.keys(state.app.activeSale.hpemb.embroidery_logos.rows)
        .map((i, index) => ({
          ...state.app.activeSale.hpemb.embroidery_logos.rows[i],
          id: index,
        }))
    }

    if (state.app.activeSale.hpemb.heat_press_logos) {
      heatPressLogos = Object.keys(state.app.activeSale.hpemb.heat_press_logos.rows)
        .map((i, index) => ({
          ...state.app.activeSale.hpemb.heat_press_logos.rows[i],
          id: index,
        }))
    }

    if (state.app.activeSale.hpemb.embroidery_initials) {
      embroideryNames = Object.keys(state.app.activeSale.hpemb.embroidery_initials.rows)
        .map((i, index) => ({
          ...state.app.activeSale.hpemb.embroidery_initials.rows[i],
          id: index,
        }))
    }

    if (state.app.activeSale.hpemb.heat_press_initials) {
      heatPressNames = Object.keys(state.app.activeSale.hpemb.heat_press_initials.rows)
        .map((i, index) => ({
          ...state.app.activeSale.hpemb.heat_press_initials.rows[i],
          id: index,
        }))
    }

    if (state.app.activeSale.hpemb.sublimation) {
      sublimations = Object.keys(state.app.activeSale.hpemb.sublimation.rows)
        .map((i, index) => ({
          ...state.app.activeSale.hpemb.sublimation.rows[i],
          id: index,
        }))
    }
  }

  return {
    embroideryLogos,
    heatPressLogos,
    embroideryNames,
    heatPressNames,
    isRequestInProgress: state.app.isRequestInProgress,
    sale_id: state.app.activeSale._id,
    sublimations,
  }
}

const mapDispatch = dispatch => ({
  addSaleHPEmb: (sale_id, data) => dispatch(addSaleHPEmbAction(sale_id, data)),
})

export default connect(mapState, mapDispatch)(HPEmbModal)
