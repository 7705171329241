import React from "react";
import PropTypes from "prop-types";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";

import SalesCard from "./SalesCard";

const SalesCards = ({ sales, paidFilter }) => (
  <Card.Group as={Segment} basic>
    {sales.map(s => (
      <SalesCard key={s._id} sale={s} paidFilter={paidFilter} />
    ))}
  </Card.Group>
);

SalesCards.propTypes = {
  sales: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SalesCards;
