import React, { Component } from 'react'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import { connect } from 'react-redux'

import {
  cancelSaleAction,
} from '../../redux/reducers/app/actions'

class CancelButton extends Component {

  handleClick = () => {
    const { cancelSale, sale_id } = this.props
    cancelSale(sale_id)
  }

  render() {

    return (
      <Button color="red" onClick={this.handleClick}>Cancel</Button>
    )
  }
}

const mapDispatch = dispatch => ({
  cancelSale: (sale_id) =>
    dispatch(cancelSaleAction(sale_id)),
})

export default connect(null, mapDispatch)(CancelButton)
