import React, { Component } from "react";
import PropTypes from "prop-types";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import { connect } from "react-redux";

import { loadSaleAction } from "../../redux/reducers/app/actions";

class SalesCard extends Component {
  handleClick = () => {
    const { loadSale, sale, paidFilter } = this.props;
    loadSale(sale._id, paidFilter);
  };

  render() {
    const { sale, stages } = this.props;

    if (typeof sale !== "object") {
      return <span></span>;
    }

    const stage = stages.find(s => s._id === sale.stage_id);
    const stageText = stage ? stage.name : "N/A";

    const statusText =
      parseFloat(sale.total_owingCUR) === 0 ? "In Production" : "Open Sales";

    //console.log(sale);
    //const totalItems = sale.rows ? sale.rows.reduce((total, r) => parseInt(r.qtyNUM) + total, 0) : 0
    //const totalAllocated = sale.rows ? sale.rows.reduce((total, r) => r.qty_allocatedNUM + total, 0) : 0
    //<Card.Content extra>{totalAllocated}/{totalItems} allocated</Card.Content>
    return (
      <Card onClick={this.handleClick}>
        <Card.Content>
          <Card.Header>
            {sale.sales_orderISbb_sales_ordersID * 1 > 0
              ? `SO: ${sale.sales_orderISbb_sales_ordersID}`
              : `ID: ${sale._id}`}
          </Card.Header>
          <Card.Description>
            {statusText} - Stage: {stageText}
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          {sale.customer.first_name} {sale.customer.last_name}{" "}
          {sale.customer.company_name}
        </Card.Content>
      </Card>
    );
  }
}

SalesCard.propTypes = {
  sale: PropTypes.object.isRequired,

  // The following props from redux:
  loadSale: PropTypes.func.isRequired,
  stages: PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapState = state => ({
  stages: state.app.stages
});
const mapDispatch = dispatch => ({
  loadSale: (sale_id, paidFilter) =>
    dispatch(loadSaleAction(sale_id, paidFilter))
});

export default connect(mapState, mapDispatch)(SalesCard);
