import React from 'react'
import PropTypes from 'prop-types'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'

const ItemsTableHeader = ({ isEditable, this_quote_id }) => (
  <Table.Header>
    <Table.Row>
      {isEditable && <Table.HeaderCell />}
      <Table.HeaderCell>Item</Table.HeaderCell>
      <Table.HeaderCell>SKU</Table.HeaderCell>
      <Table.HeaderCell>{isEditable && 'Notes'}</Table.HeaderCell>
      <Table.HeaderCell textAlign="center">
        {isEditable ? 'Qty' : ( this_quote_id*1>0? 'Qty//Conv' : 'Qty/Alloc/Dsp' )}
      </Table.HeaderCell>
      {!isEditable && (
        <div>
          <Table.HeaderCell textAlign="right">BO</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Avail/onPO</Table.HeaderCell>
        </div>
      )}
      <Table.HeaderCell textAlign="right">Price</Table.HeaderCell>
      <Table.HeaderCell textAlign="right">Dsc%</Table.HeaderCell>
      <Table.HeaderCell textAlign="right">VAT</Table.HeaderCell>
      <Table.HeaderCell textAlign="right">Total</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
)

ItemsTableHeader.propTypes = {
  isEditable: PropTypes.bool,
}
ItemsTableHeader.defaultProps = {
  isEditable: true,
}

export default ItemsTableHeader
