import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'

import ItemsTableHeader from './ItemsTableHeader'
import ItemsTableFooter from './ItemsTableFooter'
import ItemTableRow from './ItemTableRow'

import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import { setDiscModalAction } from '../../redux/reducers/app/actions'
import { connect } from 'react-redux'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import { Input } from 'semantic-ui-react'

class ItemsTable extends Component {
	constructor(props) {
	    super(props);
	    this.state = {line_id: 0,discountNUM:0};
	  }
  render(){
  	  const {this_quote_id,discModal,setDiscModal,onDeleteSaleItem, onEditSaleItem, isEditable, items, sale_id, total_owing, items_totalCUR, tax_totalCUR, grand_totalCUR, sales_orderISbb_sales_ordersID, second_sales_orderISbb_sales_ordersID}=this.props;

  	  const handleOpenDiscModal = (line_id,discountNUM) => {
  	  	  this.setState({line_id: line_id,discountNUM: discountNUM*1,});
  	  	  setDiscModal(true);
  	  }

  	  const handleCloseDiscModal = () => {
  	  	  this.setState({line_id: 0,discountNUM:0});
  	  	  setDiscModal(false)
  	  }

  	  const handleEditSaleItem = (data) => {
  	  	  const{sale_id}=this.props
  	  	  if(this.state.line_id){
			  data.discount=document.getElementById('newdiscount').value*1;
			  data.notes=null;
			  data.quantity=null;
			  data.price=null;
			  data._delete=null;
			  onEditSaleItem(sale_id,this.state.line_id,data)
			  handleCloseDiscModal()
  	  	  }
  	  }

	  if ((!items || !items.length) && total_owing*1<0) return <Message color="blue">Payment on account.</Message>

	  if ((!items || !items.length) && total_owing*1>0) return <Message color="blue">Refund to account.</Message>

	  if (!items || !items.length) return <Message color="red">This sale has no items.</Message>

	  let vatTotal = items.reduce((total, i) => total + parseFloat(i.taxTAX), 0)
	  let itemTotal = items.reduce((total, i) => total + (parseInt(i.qtyNUM) * parseFloat(i.priceCUR)), 0)
	  let orderTotal = itemTotal + vatTotal

	  if(grand_totalCUR*1>0){
		 vatTotal = tax_totalCUR; //items.reduce((total, i) => total + parseFloat(i.taxTAX), 0)
		  //const itemTotal = items_totalCUR; //items.reduce((total, i) => total + (parseInt(i.qtyNUM) * parseFloat(i.priceCUR)), 0)
		 orderTotal = grand_totalCUR; //itemTotal + vatTotal
	  }
	  console.log(grand_totalCUR)
	  return (
		  <div>
		      <Modal open={discModal} onClose={handleCloseDiscModal}>
			<Modal.Content style={{textAlign:'center'}}>
			<Input id="newdiscount" defaultValue={this.state.discountNUM} style={{marginRight:'20px'}}/>
			<Button
			    color='blue'
			    content="Set Discount"
			    onClick={handleEditSaleItem}
			  />
			</Modal.Content>
		      </Modal>
		    <Table compact size="small">
		      <ItemsTableHeader isEditable={isEditable} this_quote_id={this_quote_id} />
		      {items && items.length > 0 && (
			<Table.Body>
			  {items.map(i => (
			    <ItemTableRow
			      key={i._id}
			      isEditable={isEditable}
			      item={i}
			      onDeleteSaleItem={onDeleteSaleItem}
			      onEditSaleItem={onEditSaleItem}
			      sale_id={sale_id}
			      sales_orderISbb_sales_ordersID={sales_orderISbb_sales_ordersID}
			      second_sales_orderISbb_sales_ordersID={second_sales_orderISbb_sales_ordersID}
			      handleOpenDiscModal={handleOpenDiscModal}
						this_quote_id={this_quote_id}
			    />
			  ))}
			</Table.Body>
		      )}
		      <ItemsTableFooter
			isEditable={isEditable}
			orderTotal={orderTotal}
			vatTotal={vatTotal}
			items_totalCUR={items_totalCUR}
		      />
		    </Table>
	       </div>
	  )
  }
}


ItemsTable.propTypes = {
  isEditable: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  onDeleteSaleItem: PropTypes.func,
  onEditSaleItem: PropTypes.func,
  sale_id: PropTypes.string.isRequired,
}
ItemsTable.defaultProps = {
  isEditable: true,
  items: null,
  onDeleteSaleItem: () => {},
  onEditSaleItem: () => {},
}
const mapState = state => ({
  discModal: state.app.discModal,
})
const mapDispatch = dispatch => ({
  setDiscModal: flag => dispatch(setDiscModalAction(flag))
})

export default connect(mapState, mapDispatch)(ItemsTable)
