import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Confirm from 'semantic-ui-react/dist/commonjs/addons/Confirm'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Input from 'semantic-ui-react/dist/commonjs/elements/Input'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import Select from 'semantic-ui-react/dist/commonjs/addons/Select'
import { connect } from 'react-redux'

class HeatPressLogoForm extends Component {
  state = {
    isConfirmDeleteOpen: false,
  }

  handleTextChange = (e, { value }) => {
    const { handleChange, logo } = this.props
    handleChange({
      ...logo,
      text: value,
    })
  }

  handleLocationChange = (e, { value }) => {
    const { handleChange, logo } = this.props
    handleChange({
      ...logo,
      location: value,
    })
  }

  handleCloseConfirmDelete = () => this.setState({ isConfirmDeleteOpen: false })

  handleOpenConfirmDelete = () => this.setState({ isConfirmDeleteOpen: true })

  handleDelete = () => {
    const { handleDelete, logo } = this.props
    handleDelete(logo)
  }

  render() {
    const { logo: l, heatLocationOptions } = this.props
    const { isConfirmDeleteOpen } = this.state

    return (
      <div>
        <Form.Group key={l.id}>
          <Form.Field
            control={Input}
            fluid
            onChange={this.handleTextChange}
            placeholder="Text"
            value={l.text || ''}
            width={7}
          />
          <Form.Field
            control={Select}
            fluid
            onChange={this.handleLocationChange}
            options={heatLocationOptions
              .filter(o => o.value.trim().length > 0)
              .map(o => ({
                key: o.value,
                text: o.value,
                value: o.value,
              })
            )}
            placeholder="Location"
            value={l.location}
            width={7}
          />
          <Form.Field
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            width={2}
          >
            <Icon
              color="red"
              name="delete"
              onClick={this.handleOpenConfirmDelete}
              size="large"
              style={{ cursor: 'pointer' }}
            />
          </Form.Field>
        </Form.Group>
        <Confirm
          content={(
            <Modal.Content>
              <span>Are you sure you want to delete the heat press logo item <strong>{l.text}</strong>?</span>
            </Modal.Content>
          )}
          header="Confirm delete Heat Press Logo item"
          onCancel={this.handleCloseConfirmDelete}
          onConfirm={this.handleDelete}
          open={isConfirmDeleteOpen}
        />
      </div>
    )
  }
}

HeatPressLogoForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  logo: PropTypes.object.isRequired,

  // The following props from redux:
  heatLocationOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const mapState = state => ({
  heatLocationOptions: state.app.hpEmb.heatLocationOptions,
})

export default connect(mapState)(HeatPressLogoForm)
