import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import Input from 'semantic-ui-react/dist/commonjs/elements/Input'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import { connect } from 'react-redux'
import { isAlphanumeric, isEmpty, isNumeric } from 'validator'

//import '../../lib/hapiexpress'
//import Hapi from '../../lib/hapiexpress'
import axios from 'axios';

import AuthoriseModal from '../AuthoriseModal'
import BorderlessTableCell from '../BorderlessTableCell'
import BorderlessTableRow from '../BorderlessTableRow'
import { setAuthoriseModalIsOpenAction, printCardSlipAction } from '../../redux/reducers/app/actions'

const initialState = {
	amount: '',
	amountError: false,
	handpointStatus: 'Waiting to capture...',
	pxpStatus: 0,
	pxpDisplay: '',
	pxpTimer: null,
	pxpB1: 'Yes',
	pxpB1active: false,
	pxpB2: 'No',
	pxpB2active: false,
	isManualCardRef: false,
	ref: '',
	refError: false,
	change: '0.00',
	readOnly: false,
}

const processingState = {
	amount: '',
	amountError: false,
	handpointStatus: 'Processing payment...',
	pxpStatus: 0,
	pxpDisplay: '',
	pxpTimer: null,
	pxpB1: 'Yes',
	pxpB1active: false,
	pxpB2: 'No',
	pxpB2active: false,
	isManualCardRef: false,
	ref: '',
	refError: false,
	readOnly: true,
	//change: 'None'
}

class PaymentModal extends Component {
	state = initialState

	toggleManualCardRef = () => this.setState(state => ({//disabled for now until card integration is completed
			isManualCardRef: !state.isManualCardRef, // true //
	}))

	isFormValid = () => {
		this.setState({
				amountError: false,
				refError: false,
		})

		const { paymentType, customer_code } = this.props
		if (paymentType === 'override') return true

			const { amount, ref } = this.state
			const { amountOutstanding } = this.props

		let isErrors = false
		let refToSend = ref
		if (paymentType === 'acct') refToSend = customer_code
			// amount
		if (isEmpty(amount.toString()) || !isNumeric(amount.toString())) {
			this.setState({
					amountError: true
			})
			isErrors = true
		}
		//{
			if(parseFloat(amountOutstanding)===0 && parseFloat(amount)<(-100)){//non-sale refunds limited to 100
				this.setState({
						amountError: true
				})
				isErrors = true
			}
			if((parseFloat(amount)<0 && parseFloat(amountOutstanding)<0) && parseFloat(amount)!==parseFloat(amountOutstanding)){//refund needs to match exactly
				this.setState({
						amountError: true
				})
				isErrors = true
			}
		//}
		if (paymentType === 'card' || paymentType === 'cheque') {//paid too much
			if(parseFloat(amountOutstanding)>0 && parseFloat(amount)>parseFloat(amountOutstanding)){//paid too much
				this.setState({
						amountError: true
				})
				isErrors = true
			}
		}

		// ref
		if (paymentType === 'cash') {
			if (isEmpty(refToSend.toString()) || !isNumeric(refToSend.toString())) {
				this.setState({
						refError: true
				})
				isErrors = true
			}
		} else if (paymentType === 'card') {
			// form validation for card is handled in handleSubmit()
			//return true
		} else {
			if (isEmpty(refToSend.toString())) { //|| !isAlphanumeric(refToSend.toString())
				this.setState({
						refError: true
				})
				isErrors = true
			}
		}
		return !isErrors
	}

	handleInputChange = (e, { name, value }) => {
		const { amountOutstanding, paymentType } = this.props
		this.setState({ [name]: value }, () => {
				if (paymentType === 'acct') {
					this.setState(state => {
							return {
								amount:amountOutstanding,
							}
					})
				}
				if (paymentType === 'cash') {
					this.setState(state => {
							// auto-calculate change for cash payment type
							let change = '0'
							if(parseFloat(amountOutstanding)>0){
								change = (parseFloat(state.amount) - parseFloat(amountOutstanding)).toFixed(2)
							}
							if (!isNumeric(change)) change = 0
							this.setState({
									change: (change>0?change:'0.00'),
							})
							return {
								ref: change > 0 ? change : 0,
							}
					})
				}
		})
	}

	sendToReader = (sale_id,amount,onSubmit,paymentType,cardTerminalID,tillName) => {

		this.setState({
				pxpTimer: null,
		})
		const { printCardSlip } = this.props

		if(!cardTerminalID && !navigator.userAgent.match(/Android/i) && !navigator.userAgent.match(/iPhone|iPad|iPod/i)){
			alert('SUM-UP card capture only works on a tablet... This device is '+navigator.userAgent);
			this.setState({
					handpointStatus: 'Waiting to capture...',
					isLoading: false
			})
			return
		}

		if(cardTerminalID){//PAYMENT-EXPRESS
			//{
				let turl='https://picton.blueboxonline.com/?pxp-status=START&px-terminal-id='+cardTerminalID+'&tillName='+tillName+'&send_payment_ref='+sale_id+'&send_payment_amt='+amount;
				if(this.state.pxpStatus===1){
					turl='https://picton.blueboxonline.com/?pxp-status=VERIFY&px-terminal-id='+cardTerminalID+'&tillName='+tillName+'&send_payment_ref='+sale_id;
				}else{
					this.setState({
						pxpDisplay: 'Sending to terminal...',
						pxpStatus: 1,
						handpointStatus: 'Waiting for response...',
						readOnly: true,
					})
				}
			//}
			axios.get(turl)
			      .then(result => {
			      		      //console.log('Axios feedback:',result);
			      		      if(typeof result.data!='undefined' && typeof result.data.DL1!='undefined'){
			      		      	      this.setState({
			      		      	      		      pxpDisplay: result.data.DL1+' '+result.data.DL2,
			      		      	      })
			      		      }
			      		      if(typeof result.data!='undefined' && typeof result.data.B1!='undefined' && result.data.B1==='YES'){
			      		      	      this.setState({
			      		      	      		      pxpB1active: true,
			      		      	      })
			      		      }else{
			      		      	      this.setState({
			      		      	      		      pxpB1active: false,
			      		      	      })
			      		      }
			      		      if(typeof result.data!='undefined' && typeof result.data.B2!='undefined' && result.data.B2==='NO'){
			      		      	      this.setState({
			      		      	      		      pxpB2active: true,
			      		      	      })
			      		      }else{
			      		      	      this.setState({
			      		      	      		      pxpB2active: false,
			      		      	      })
			      		      }
			      		      if(typeof result.data!='undefined' && typeof result.data.B2!='undefined' && result.data.B2==='CANCEL'){
			      		      	      this.setState({
			      		      	      		      pxpB2active: true,
			      		      	      })
			      		      }else{
			      		      	      this.setState({
			      		      	      		      pxpB2active: false,
			      		      	      })
			      		      }
					      if(typeof result.data!='undefined' && ((typeof result.data.StatusId!='undefined' && result.data.StatusId*1===6) || (typeof result.data.Complete!='undefined' && result.data.Complete*1===1))){
					      	      if(typeof result.data.Rcpt!='undefined'){
					      	      	      printCardSlip(result.data.Rcpt);
					      	      }
					      	      let thisref='';
					      	      if(typeof result.data.Result.CT!='undefined' && typeof result.data.Result.CT=='string'){
					      	      	      thisref=result.data.Result.CT+'-'+result.data.Result.CN.substr(-4)
					      	      }
						      if(typeof result.data.Result!='undefined' && typeof result.data.Result.AP!='undefined' && result.data.Result.AP*1===1){
						      	      //alert('Payment success '+thisref)
							      console.log('Payment successful '+thisref,result);
							      //payment success
								clearInterval(this.state.pxpTimer)
								this.setState({
										pxpTimer: null,
								})
								onSubmit(sale_id, paymentType.toUpperCase(), amount, thisref)
								this.setState(initialState)
						      }else{
						      	        //alert('Payment failed '+thisref)
							        console.log('Payment failed '+thisref,result);
							        //payment success
								clearInterval(this.state.pxpTimer)
								this.setState({
										pxpTimer: null,
								})
								alert('PAYMENT FAILED OR CANCELLED - please check terminal...')
								this.setState(initialState)
						      }
					      }else{
						      console.log('Retrying');
						      //still waiting
						      this.setState({
						      		      pxpTimer: setTimeout(() => {
						      		      		      clearInterval(this.state.pxpTimer)
						      		      		      this.setState({
						      		      		      		      pxpTimer: null,
						      		      		      })
						      		      		      this.sendToReader(sale_id,amount,onSubmit,paymentType,cardTerminalID,tillName)
						      		      }, 3000),
						      })
					      }
				}
			      )
			      .catch(error => this.setState({
				error,
				isLoading: false
			      }));
		}else{//SUMUP
			/*window.open('sumupmerchant://pay/1.0?affiliate-key=483d8039-24f6-40fb-9f5f-cb08b1adccf1&app-id=com.blueboxonline.picton&total='+amount+'&currency=GBP&title='+sale_id+'&receipt-mobilephone=&receipt-email=&callback=https://picton.blueboxonline.com','_system');

			this.setState({
					handpointStatus: 'Waiting for response...',
			})
			axios.get('https://picton.blueboxonline.com/?smp-status=VERIFY&smp-tx-code='+sale_id)
			      .then(result => {
					      if(typeof result.data!='undefined' && typeof result.data.verified!='undefined' && result.data.verified===1){
						      if(result.data.successYN===1){
							      console.log('Payment successful '+result.data.ref);
							      //payment success
								clearInterval(this.state.pxpTimer)
								this.setState({
										pxpTimer: null,
								})
								onSubmit(sale_id, paymentType.toUpperCase(), amount, 'card ref to go here')
								this.setState(processingState)
						      }else{
							      //alert('Payment failed '+result.data.ref)
							      console.log('Payment failed '+result.data.ref);
							      //payment success
								clearInterval(this.state.pxpTimer)
								this.setState({
										pxpTimer: null,
								})
								this.setState(initialState)
						      }
					      }else{
						      console.log('Retrying');
						      //still waiting

						      this.setState({
						      		      pxpTimer:setTimeout(() => {
						      		      		       clearInterval(this.state.pxpTimer)
						      		      		      this.setState({
						      		      		      		      pxpTimer: null,
						      		      		      })
						      		      		      this.sendToReader(sale_id,amount,onSubmit,paymentType,cardTerminalID,tillName)
						      		      }, 3000),
						      })

					      }
				}
			      )
			      .catch(error => this.setState({
				error,
				isLoading: false
			      }));*/
		}

	}

	handleCardButtonClose = () => {
		const { tillName,sale_id, cardTerminalID } = this.props
		if(this.state.pxpStatus===1){
			this.setState({
					pxpDisplay: 'Sending cancel message...',
			})
			axios.get('https://picton.blueboxonline.com/?pxp-status=CANCEL&px-terminal-id='+cardTerminalID+'&send_payment_ref='+sale_id+'&tillName='+tillName+'&send_payment_cancelled=CANCEL')
			.then(result => {
					//this.handleClose() the close message will close the creen, we don't do it here
					this.setState({
							pxpDisplay: 'Cancelling...',
					})
				}
			      )
			      .catch(error => this.setState({
				error,
				isLoading: false
			      }));
		}else{
			this.handleClose()
		}
	}
	handleCardButtonYes = () => {
		const { tillName, sale_id, cardTerminalID } = this.props
		axios.get('https://picton.blueboxonline.com/?pxp-status=YESNO&px-terminal-id='+cardTerminalID+'&send_payment_ref='+sale_id+'&tillName='+tillName+'&yesno=YES')
	}
	handleCardButtonNo = () => {
		const { tillName, sale_id, cardTerminalID } = this.props
		axios.get('https://picton.blueboxonline.com/?pxp-status=YESNO&px-terminal-id='+cardTerminalID+'&send_payment_ref='+sale_id+'&tillName='+tillName+'&yesno=NO')
	}

	handleClose = () => {
		const { onClose } = this.props
		clearInterval(this.state.pxpTimer)
		this.setState({
				pxpTimer: null,
		})
		onClose()
		this.setState(initialState)
	}

	handleSubmit = async () => {
		if (!this.isFormValid()){
			return
		}

		const { amountOutstanding, onSubmit, sale_id, paymentType, customer_code, cardTerminalID, tillName } = this.props
		const { ref, amount, isManualCardRef } = this.state
		let amountPaid = 0
		if(parseFloat(amountOutstanding)>0){
			amountPaid = parseFloat(amount) > parseFloat(amountOutstanding) ? amountOutstanding : amount
		}else{
			amountPaid = parseFloat(amount)
		}

		if (paymentType === 'card') {
			// must be a numeric amount
			if (isEmpty(amount.toString()) || !isNumeric(amount.toString())) {
				this.setState({
						amountError: true
				})
				return
			}

			if (isManualCardRef) {
				// user enters ref manually
				if (isEmpty(ref.toString()) || !isAlphanumeric(ref.toString())) {
					this.setState({
							refError: true
					})
					return
				}
				onSubmit(sale_id, paymentType.toUpperCase(), amount, ref)
				this.setState(processingState)
				return
			} else {
				// get the ref from the card reader
				//let currency = process.env.REACT_APP_HANDPOINT_CURRENCY
				//let callbackurl = process.env.REACT_APP_HANDPOINT_CALLBACK_URL
				//let url = 'url-placeholder-just-in-case'//Hapi.urlForSale(amount * 100, currency, {}, callbackurl)

				//console.log(url, callbackurl)
				// Hapi.openUrl(url)

				// simulate interaction with reader
				console.log('Sending to reader...');
				this.setState({
						handpointStatus: 'Sending to reader...',
				}, () => {
							this.sendToReader(sale_id,amount,onSubmit,paymentType,cardTerminalID,tillName);
						})
				/*setTimeout(() => {

						this.setState({
								handpointStatus: 'Waiting for reader response...',
						}, () => {
							this.sendToReader(sale_id,amount,onSubmit,paymentType);
						})*/


						/*
						this.setState({
								handpointStatus: 'Waiting for reader response...',
						}, () => {
							setTimeout(() => {
									const refFromCardReader = 'refFromCardReader'
									if (isEmpty(refFromCardReader.toString()) || !isAlphanumeric(refFromCardReader.toString())) {
										this.setState({
												refError: true,
										})
										return
									}
									onSubmit(sale_id, paymentType.toUpperCase(), amount, refFromCardReader)
									this.setState(initialState)
							}, 3000)
						})
						*/
				/*}, 500)*/
				return
			}
		}

		let refToSend = ref
		let amountToSend = amountPaid
		if (paymentType === 'acct') {
			refToSend = customer_code
		}
		if (paymentType === 'override') {
			refToSend = `OVERRIDE-${amountOutstanding}`
			amountToSend = 0
		}

		onSubmit(sale_id, paymentType.toUpperCase(), amountToSend, refToSend)
		this.setState(initialState)
	}

	openAuthoriseModal = () => {
		const { setAuthoriseModalIsOpen } = this.props
		setAuthoriseModalIsOpen(true)
	}

	handleFullAmount = () => {
		const { amountOutstanding, paymentType} = this.props
		this.setState({
				amount: parseFloat(amountOutstanding).toFixed(2),
		})
		if(paymentType === 'cash'){
			this.setState({
					change: '0.00',
					ref: '0',
			})
		}
	}

	handleClearInput = () => {
		this.setState({
				amount: '',
		})
	}

	render() {
		const { amountOutstanding, customer_code, isOpen, isRequestInProgress, paymentType, cardTerminalID } = this.props

		if (!paymentType) return null

		const {
			handpointStatus, ref, amount, refError, amountError,
			isManualCardRef, change, readOnly
		} = this.state

		let refString
		switch (paymentType) {
		case 'cheque':
			refString = 'Cheque number'
			break
		case 'acct':
			refString = 'Customer code'
			break
		case 'card':
			refString = 'Payment ref'
			break
		case 'cash':
			refString = 'Change'
			break
		default:
			refString = 'refString'
		}

		return (
			<Modal
			onClose={this.handleClose}
			closeOnDimmerClick={false}
			open={isOpen}
			>
			<Modal.Header>New {paymentType.toUpperCase()} Payment</Modal.Header>
			<Modal.Content>
			<Table size="large" basic="very" collapsing>
			<Table.Body>
			<BorderlessTableRow>
				<BorderlessTableCell width={4} style={{textAlign:'left'}}>
					<Header as="h2">Amount to pay: £{parseFloat(amountOutstanding).toFixed(2)}</Header>
				</BorderlessTableCell>
				<BorderlessTableCell width={4} style={{textAlign:'right'}}>
					{cardTerminalID ?
						<div style={{fontWeight:'bold',color:'red',fontSize:'16pt',background:'yellow'}}>{this.state.pxpDisplay}</div>
						:
						<div></div>
					}
				</BorderlessTableCell>
			</BorderlessTableRow>
			<BorderlessTableRow>
			<BorderlessTableCell width={2} style={{ paddingBottom: 0, fontWeight: 700 }}>Payment type:</BorderlessTableCell>
			<BorderlessTableCell width={6}>{paymentType}</BorderlessTableCell>
			</BorderlessTableRow>
			{paymentType !== 'override' && (
					<div>
					<BorderlessTableRow>
					<BorderlessTableCell width={2} style={{ paddingBottom: 0, fontWeight: 700 }}>
					{refString}:
					</BorderlessTableCell>
					<BorderlessTableCell width={6}>
					{paymentType === 'acct' && customer_code}
					{paymentType === 'card' && (
							<Form>
							<Form.Group>
							{isManualCardRef ? (
									<Form.Input
									error={refError}
									name="ref"
									onChange={this.handleInputChange}
									placeholder="Transaction reference"
									width={12}
									/>
									) : (
							<div
							className="twelve wide field"
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
							>
							<span>{handpointStatus}</span>
							</div>
							)}
									<Form.Button
									disabled={isRequestInProgress || handpointStatus !== 'Waiting to capture...'}
									onClick={this.toggleManualCardRef}
									width={4}
									>
									{isManualCardRef ? 'Device' : 'Manual'}
									</Form.Button>
							</Form.Group>
							</Form>
					)}
					{paymentType === 'cash' && `£${ref || '0.00'}`}
					{paymentType === 'cheque' && (
							<Input
							error={refError}
							fluid
							name="ref"
							onChange={this.handleInputChange}
							placeholder={refString}
							required
							value={ref}
							/>
					)}
					</BorderlessTableCell>
					</BorderlessTableRow>
					<BorderlessTableRow>
					<BorderlessTableCell width={2} style={{ paddingBottom: 0, fontWeight: 700 }}>Payment amount:</BorderlessTableCell>
					<BorderlessTableCell width={6}>
					<Input
					error={amountError}
					disabled={readOnly}
					fluid
					name="amount"
					onChange={this.handleInputChange}
					onClick={this.handleClearInput}
					placeholder="0.00"
					required
					value={amount}
					>
					<input />
					<Button type='button' onClick={this.handleFullAmount}>Full Amount</Button>
					</Input>
					</BorderlessTableCell>
					</BorderlessTableRow>
					{paymentType === 'cash' &&
						<BorderlessTableRow>
						<BorderlessTableCell width={2} style={{ paddingBottom: 0, fontWeight: 700 }}>Change:</BorderlessTableCell>
						<BorderlessTableCell width={6} style={{fontSize:'18pt'}}>
						{change}
						</BorderlessTableCell>
						</BorderlessTableRow>
					}
					</div>
			)}
			</Table.Body>
			</Table>
			</Modal.Content>
			<Modal.Actions>
				<Table basic="very">
				<Table.Body>
					<BorderlessTableRow>
						<BorderlessTableCell>
							{paymentType === 'card' ? (
									<span>
										<Button
										onClick={this.handleCardButtonClose}
										>
										Cancel
										</Button>
									</span>
							):(
									<Button
									disabled={isRequestInProgress || handpointStatus !== 'Waiting to capture...'}
									onClick={this.handleClose}
									>
									Cancel
									</Button>
							)}
						</BorderlessTableCell>
						<BorderlessTableCell style={{textAlign:'center'}}>
							{paymentType === 'card' ? (
									<span>
										<Button
										style={{marginRight:'50px'}}
										disabled={!this.state.pxpB1active}
										onClick={this.handleCardButtonYes}
										>
										{this.state.pxpB1}
										</Button>
										<Button
										disabled={!this.state.pxpB2active}
										onClick={this.handleCardButtonNo}
										>
										{this.state.pxpB2}
										</Button>

									</span>
							):(<span/>)}
						</BorderlessTableCell>
						<BorderlessTableCell style={{textAlign:'right'}}>
							{paymentType !== 'override' && (
									<Button
									size='big'
									color='green'
									onClick={this.handleSubmit}
									loading={isRequestInProgress || handpointStatus !== 'Waiting to capture...'}
									>
									PAY
									</Button>
							)}
							{paymentType === 'override' && (
									<div>
									<Button
									primary
									onClick={this.openAuthoriseModal}
									loading={isRequestInProgress}
									>
									Authorise
									</Button>
									<AuthoriseModal successAction={this.handleSubmit} />
									</div>
							)}
						</BorderlessTableCell>
					</BorderlessTableRow>
				</Table.Body>
				</Table>
			</Modal.Actions>
			</Modal>
			)
	}
}

PaymentModal.propTypes = {
	amountOutstanding: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	paymentType: PropTypes.oneOf([
			'cash',
			'card',
			'cheque',
			'acct',
			'override',
	]),
	sale_id: PropTypes.string.isRequired,

	// The following props from redux:
	customer_code: PropTypes.string.isRequired,
	isRequestInProgress: PropTypes.bool.isRequired,
	setAuthoriseModalIsOpen: PropTypes.func.isRequired,
}

const mapState = state => ({
	customer_code: state.app.activeSale.customer.code || 'NotFound',
	isRequestInProgress: state.app.isRequestInProgress,
	cardTerminalID: state.settings.cardTerminalID,
	tillName: state.settings.tillName,
})
const mapDispatch = dispatch => ({
	setAuthoriseModalIsOpen: value => dispatch(setAuthoriseModalIsOpenAction(value)),
	printCardSlip: (printme) => dispatch(printCardSlipAction(printme)),
})

export default connect(mapState, mapDispatch)(PaymentModal)
