import React, { Component } from 'react'
import { connect } from 'react-redux'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'

import AppLoginForm from './AppLoginForm'
/*import AppScanBarcodeForm from './AppScanBarcodeForm'*/

class AppLoginScreen extends Component {
	render(){
		const { tillName } = this.props
		return(
			  <div
			    style={{
			      alignItems: 'center',
			      display: 'flex',
			      flexDirection: 'column',
			      height: '100%',
			      justifyContent: 'center',
			    }}
			  >
			    <Segment>
			      {tillName>''?<AppLoginForm/>:<span/>}
			    </Segment>
			    {/*
			    <Segment>
			      <AppScanBarcodeForm/>
			    </Segment>
			    */}
			  </div>
		)
	}
}

const mapState = state => ({
  tillName: state.settings.tillName,
})

export default connect(mapState)(AppLoginScreen)