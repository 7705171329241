import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import { connect } from 'react-redux'

import { loadDataAction } from '../../redux/reducers/app/actions'

class ReloadDataForm extends Component {
  handleSubmit = () => {
    const { loadData } = this.props
    loadData()
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Button
          content="Reload local data"
          type="submit"
          secondary
        />
      </Form>
    )
  }
}

ReloadDataForm.propTypes = {
  // The following props from redux:
  loadData: PropTypes.func.isRequired,
}

const mapDispatch = dispatch => ({
  loadData: () => dispatch(loadDataAction()),
})

export default connect(null, mapDispatch)(ReloadDataForm)