import React, { Component } from 'react'
import PropTypes from 'prop-types'
//import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import { connect } from 'react-redux'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import { Divider } from 'semantic-ui-react'

import CustomerSearchForm from './CustomerSearchForm'
import QuoteSearchForm from './QuoteSearchForm'
import SaleSearchForm from './SaleSearchForm'
import CustomerDetailForm from '../CustomerDetails'
import NewCustomerForm from './NewCustomerForm'
import NewCustomerHeader from './NewCustomerHeader'
//import NewSaleHeader from './NewSaleHeader'
import SearchResultsModal from './SearchResultsModal'
import withAuth from '../../lib/withAuth'

import { newSaleAction, setScreenAction } from '../../redux/reducers/app/actions'

import { logoutAction } from '../../redux/reducers/auth/actions'

class NewSaleScreen extends Component {

  handleClick = () => {
    const { newSale } = this.props
    newSale(8)//8 is the cash sale id
  }

  handleClick2 = () => {
    const { setScreen } = this.props
    setScreen('SCAN_CODE')
  }

  render() {
    const { isRequestInProgress, permissions, logout } = this.props
    console.log(permissions);
    if(localStorage.getItem('saleCompleted')*1===1){
    	    localStorage.setItem('saleCompleted', 0)
    	    logout()
    }

    return (
      <div>
        <SearchResultsModal />
        <Segment as={Grid} basic stackable loading={isRequestInProgress}>
          <Grid.Row>
            <Grid.Column width={8}>
            {(permissions.indexOf('ViewOnly')!==-1 || permissions.indexOf('Production')!==-1 || permissions.indexOf('Despatch')!==-1) &&
                <div>
                <Segment>
                <SaleSearchForm />
                <Form.Button type="button" color='teal' floated='right' content="Scan Code" onClick={this.handleClick2} />
                <Divider hidden clearing />
                </Segment>
                </div>
            }
            {(permissions.indexOf('ViewOnly')===-1 && permissions.indexOf('Production')===-1 && permissions.indexOf('Despatch')===-1) &&
                <div>
                <Segment>
                  <CustomerSearchForm />
                  <Form.Button type="button" color='purple' floated='right' content="Cash Sale" onClick={this.handleClick} />
                  <Divider hidden clearing />
                  </Segment>
                <Segment>
                  <QuoteSearchForm />
                </Segment>

                <Segment>
                  <SaleSearchForm />
                  <Form.Button type="button" color='teal' floated='right' content="Scan Code" onClick={this.handleClick2} />
                  <Divider hidden clearing />
                </Segment>

                </div>
            }
            </Grid.Column>
            <Grid.Column width={8}>
            {(permissions.indexOf('ViewOnly')===-1 && permissions.indexOf('Production')===-1 && permissions.indexOf('Despatch')===-1) &&
                <div>

                <Segment>
                  <CustomerDetailForm />
                </Segment>

                <Segment>
                  <NewCustomerHeader />
                  <NewCustomerForm />
                </Segment>

                </div>
            }
            {(permissions.indexOf('Despatch')!==-1) &&
                <Segment>
                  <CustomerDetailForm />
                </Segment>
            }
            </Grid.Column>
          </Grid.Row>
        </Segment>
      </div>
    )
  }
}

NewSaleScreen.propTypes = {
  // The following props from redux:
  isRequestInProgress: PropTypes.bool.isRequired,
}

const mapState = state => ({
  isRequestInProgress: state.app.isRequestInProgress,
  permissions: (state.auth.user?state.auth.user.permissions:''),
})


const mapDispatch = dispatch => ({
  newSale: (customer_id, quote_id) => dispatch(newSaleAction(customer_id, quote_id)),
  setScreen: screen => dispatch(setScreenAction(screen)),
  logout: () => dispatch(logoutAction()),
})


export default connect(mapState, mapDispatch)(withAuth(NewSaleScreen))
