import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'

class SaveQuoteForm extends Component {
  handleClick = () => {
    const { onSubmit, sale_id /*, quote_id*/ } = this.props
    onSubmit(sale_id)
  }
  openQuote = () => {
    const { quote_id } = this.props
    window.open('https://picton.blueboxonline.com/?class=bb_sales_quotes&method=view&global[fields][_id]='+quote_id,'quote_window')
  }

  render() {
    const { quote_id } = this.props
    if(quote_id>0){
    	    return(
    	    	    <Button
			className="ui icon button"
			onClick={this.openQuote}
			type="button"
		      >Saved As Quote {quote_id}</Button>
    	    	    );
    }else{
    	    return(
    	    	    <Button
			className="ui icon button"
			onClick={this.handleClick}
			type="submit"
		      ><i aria-hidden="true" className="save outline icon"></i> Save As Quote</Button>
    	    	    );
    }
  }
}

SaveQuoteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  sale_id: PropTypes.string.isRequired,
}

export default SaveQuoteForm