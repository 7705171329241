import React from 'react'
import PropTypes from 'prop-types'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'

const DeliveryAddress = ({ address }) => (
  <Segment>
    {address || <span style={{color:'silver'}}>There is no delivery address for this sale.</span>}
  </Segment>
)

DeliveryAddress.propTypes = {
  address: PropTypes.string,
}
DeliveryAddress.defaultProps = {
  address: null,
}

export default DeliveryAddress
