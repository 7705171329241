import React, { Component } from 'react'
import { Button, Confirm } from 'semantic-ui-react'
import { connect } from 'react-redux'

import { loadSaleAction } from '../redux/reducers/app/actions'

class SaveSaleButton extends Component {

  state = {
    open: false,
    header: '',
    content: ''
  }

  show = () => this.setState({ open: true })
  handleConfirm = () => this.setState({ open: false })
  handleCancel = () => this.setState({ open: false })

  handleClick = () => {
    const { loadSale, sale_id, customer_order_number, customer_status, total_owingCUR } = this.props
    if(!customer_order_number){
      this.setState({ header:'Order Ref Incomplete' })
      this.setState({ content:'Please enter the customer name in the order ref field' })
      this.setState({ open: true })
      return(false)
    }
    //alert(typeof customer_status.OK+'@'+total_owingCUR)
    if(total_owingCUR*1>0 && typeof customer_status.OK=='undefined'){
      this.setState({ header:'Customer Account Overdue' })
      this.setState({ content:'You cannot proceed with a new sale/quote until this customer account is settled.' })
      this.setState({ open: true })
      return(false)
    }
    loadSale(sale_id)
  }

  render() {
    return (
      <div>
      <Button color="blue" onClick={this.handleClick} className="ui big icon button" >Save/Pay <i aria-hidden="true" className="angle double right icon"></i></Button>
      <Confirm
                open={this.state.open}
                header={this.state.header}
                content={this.state.content}
                onCancel={this.handleCancel}
                onConfirm={this.handleConfirm}
              />
      </div>
    )
  }
}

const mapDispatch = dispatch => ({
	loadSale: sale_id => dispatch(loadSaleAction(sale_id)),
})

export default connect(null, mapDispatch)(SaveSaleButton)
