import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import { connect } from 'react-redux'

import { despatchAction } from '../../../redux/reducers/app/actions'

class ConfirmDespatchButton extends Component {
  handleClick = () => {
    const { despatch, sale_id, items, onSubmit } = this.props
    const lines = items
      .filter(i => i.quantityToDespatch && i.quantityToDespatch > 0)
      .map(i => ({
        _id: i._id,
        qty_despatchedNUM: i.quantityToDespatch,
      }))
    onSubmit()
    despatch(sale_id, lines)
  }

  render() {
    const { isRequestInProgress, isDisabled } = this.props

    return (
      <Button
        content="Despatch"
        disabled={isDisabled}
        loading={isRequestInProgress}
        icon="shipping fast"
        onClick={this.handleClick}
        primary
        size="large"
      />
    )
  }
}

ConfirmDespatchButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  sale_id: PropTypes.string.isRequired,

  // The following props from redux
  despatch: PropTypes.func.isRequired,
  isRequestInProgress: PropTypes.bool.isRequired,
}
ConfirmDespatchButton.defaultProps = {
  items: null,
}

const mapState = state => ({
  isRequestInProgress: state.app.isRequestInProgress,
})
const mapDispatch = dispatch => ({
  despatch: (sale_id, lines) => dispatch(despatchAction(sale_id, lines)),
})

export default connect(mapState, mapDispatch)(ConfirmDespatchButton)