import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Confirm from 'semantic-ui-react/dist/commonjs/addons/Confirm'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Input from 'semantic-ui-react/dist/commonjs/elements/Input'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import { isInt } from 'validator'

class QuantityInput extends Component {
  state = {
    isConfirmOpen: false,
    value: this.props.value,
    valueError: false,
  }

  closeConfirm = () => this.setState({ isConfirmOpen: false })

  openConfirm = () => this.setState({ isConfirmOpen: true })

  handleDecrement = () => {
    const { value } = this.state
    if (typeof value === 'string') {
      if (!isInt(value)) return
    }
    this.handleChange(null, { value: (value===1?-1:value - 1) })
  }

  handleIncrement = () => {
    const { value } = this.state
    if (typeof value === 'string') {
      if (!isInt(value)) return
    }
    this.handleChange(null, { value: (value===-1?1:value + 1) })
  }

  handleChange = (e, { value }) => {
    const { onChange } = this.props

    const intValue = parseInt(value)
    if (isNaN(intValue)) {
      this.setState({
        value,
        valueError: true,
      })
      return
    }

    //if (intValue < 0) return

    this.setState({ value: intValue, valueError: false }, () => {
      if (intValue === 0) {
        this.openConfirm()
      } else {
        onChange(intValue)
      }
    })
  }

  handleCancelDelete = () => {
    this.setState({ value: 1 }, () => {
      this.closeConfirm()
    })
  }

  handleDelete = () => {
    const { onZero } = this.props
    this.closeConfirm()
    onZero()
  }

  render() {
    const { product } = this.props
    const { isConfirmOpen, value, valueError } = this.state

    return (
      <div>
        <Input
          error={valueError}
          labelPosition="right"
          onChange={this.handleChange}
          value={value}
        >
          <Label
            onClick={this.handleDecrement}
            style={{ cursor: 'pointer' }}
          >
            <Icon
              fitted
              name="minus"
              style={{
                position: 'relative',
                top: 2,
              }}
            />
          </Label>
          <input style={{ textAlign: 'center', width: 44 }} />
          <Label
            onClick={this.handleIncrement}
            style={{ cursor: 'pointer' }}
          >
            <Icon
              fitted
              name="plus"
              style={{
                position: 'relative',
                top: 2,
              }}
            />
          </Label>
        </Input>
        <Confirm
          content={(
            <Modal.Content>
              Are you sure you want to delete <strong>{product || 'this item'}</strong>?
            </Modal.Content>
          )}
          header="Confirm delete item"
          onCancel={this.handleCancelDelete}
          onConfirm={this.handleDelete}
          open={isConfirmOpen}
        />
      </div>
    )
  }
}

QuantityInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onZero: PropTypes.func.isRequired,
  product: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
}

export default QuantityInput
