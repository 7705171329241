import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import { isEmpty } from 'validator'
import { connect } from 'react-redux'

import { searchQuotesAction } from '../../redux/reducers/app/actions'

class QuoteSearchForm extends Component {
  state = {
    quote: '',
    isQuoteError: false,
  }

  handleInputChange = (e, { name, value }) => this.setState({ [name]: value })

  handleSubmit = () => {
    const { searchQuotes } = this.props
    const { quote } = this.state

    // Form validation
    if (isEmpty(quote)) {
      this.setState({ isQuoteError: true })
      return
    }

    // search_quotes
    searchQuotes(quote)
  }

  render() {
    const { quote, isQuoteError } = this.state

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Input
          error={isQuoteError}
          label="Convert quote:"
          name="quote"
          onChange={this.handleInputChange}
          placeholder="Quote"
          required
          value={quote}
        />
        <Form.Button type="submit" primary content="Search Quotes" />
      </Form>
    )
  }
}

QuoteSearchForm.propTypes = {
  // The following props from redux:
  searchQuotes: PropTypes.func.isRequired,
}

const mapDispatch = dispatch => ({
  searchQuotes: q => dispatch(searchQuotesAction(q)),
})

export default connect(null, mapDispatch)(QuoteSearchForm)
