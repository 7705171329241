import React from 'react'
import PropTypes from 'prop-types'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import AddNoteButton from './AddNoteButton'

const SaleNotes = ({ notes, sales_orderISbb_sales_ordersID, permissions }) => {
  if(notes && notes.indexOf('~~')!==-1){notes=notes.split('~~')[0]}
  return(
  <Segment>
    {sales_orderISbb_sales_ordersID*1>0 && (
      <div>
      {(permissions.indexOf('ViewOnly')===-1 && <AddNoteButton sales_orderISbb_sales_ordersID={sales_orderISbb_sales_ordersID} />)}
      <div style={{clear:'both'}}></div>
      </div>
      )
    }
    {<div dangerouslySetInnerHTML={{__html: notes}}></div> || <span style={{color:'silver'}}>There are no notes for this sale.</span>}
  </Segment>
  )
}

SaleNotes.propTypes = {
  notes: PropTypes.string,
}
SaleNotes.defaultProps = {
  notes: null,
}

export default SaleNotes
