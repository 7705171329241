import React, { Component } from 'react'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import {Confirm} from 'semantic-ui-react'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { changeStageAction } from '../../redux/reducers/app/actions'

class StagesModal extends Component {
	
	state = { modal_open: false, selected_stage: 0 }
	handleOpen = (e, { value }) => {
		this.setState({ selected_stage: value })
		this.setState({ modal_open: true })
	}
	handleClose = () => {
		this.setState({ selected_stage: 0 })
		this.setState({ modal_open: false })
	}
	handleConfirm = () => {
		this.setState({ modal_open: false })
		this.handleChangeStage()
	}
	
  handleChangeStage = () => {
    const { changeStage, sale_id } = this.props
    changeStage(sale_id, this.state.selected_stage)
    this.setState({ selected_stage: 0 })
  }

  render() {
    const { permissions, handleClose, isOpen, sale_id, stages, stage_id, isRequestInProgress } = this.props

    /*const stageOptions = stages.map(s => ({
      value: s._id,
      text: s.name,
    }))
    */
    var stageOptions=[];
    var s;
    
    for(s of stages){
    	    var addme=false;
    	    var allowed=[];
	    if(permissions.indexOf('Production')!==-1){
	    	    allowed=[
	    	    'Part Orders',
	    	    'Embroidery',
	    	    'Design Confirmed',
	    	    'Embroidery Initials',
	    	    'Sublimation Design',
	    	    'Sublimation Print',
	    	    'Cutting',
	    	    'Sublimation & Trim',
	    	    'Assembly',
	    	    'Quality Control',
	    	    'Collection',
	    	    'Delivery',
	    	    ];
	    }else if(permissions.indexOf('Embroidery')!==-1){
	    	    allowed=[
	    	    'Part Orders',
	    	    'Heat Press',
	    	    'Embroidery',
	    	    'Embroidery Initials',
	    	    'Quality Control',
	    	    'Collection',
	    	    'Delivery',
	    	    ];
	    }else if(permissions.indexOf('Quality Control')!==-1){
	    	    allowed=[
	    	    'Part Orders',
	    	    'Quality Control',
	    	    'Collection',
	    	    'Delivery',
				'Refund Customer',
				'Resolved',
				'Back Orders',
				'Design',
				'Embroidery',
				'Heat Press',
				'Embroidery Initials',
	    	    ];
	    }else if(permissions.indexOf('Despatch')!==-1){
	    	    allowed=[
	    	    'Part Orders',
	    	    'Assembly',
	    	    'Quality Control',
	    	    'Collection',
	    	    'Delivery',
				'Refund Customer',
				'Resolved',
				'Back Orders',
				'Design',
				'Embroidery',
				'Heat Press',
				'Embroidery Initials',
	    	    ];
	    }
	    if(permissions.indexOf('Supervisor')!==-1){
		    addme=true;
	    }else if(allowed.indexOf(s.name)!==-1){
	    	    addme=true;
	    }
	    if(addme){
		    stageOptions.push({
				    value: s._id,
				    text: s.name,
		    });
	    }
    }

    return (
      <Modal
        onClose={handleClose}
        open={isOpen}
      >
        <Modal.Header>
          Stages {sale_id}
        </Modal.Header>
        <Modal.Content>
        	<Confirm
		  open={this.state.modal_open}
		  onCancel={this.handleClose}
		  onConfirm={this.handleConfirm}
		/>
          <Dropdown
            disabled={isRequestInProgress}
            onChange={this.handleOpen}
            options={stageOptions}
            placeholder="Select stage"
            selection
            value={stage_id}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

StagesModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  sale_id: PropTypes.string.isRequired,

  // The following props from redux:
  changeStage: PropTypes.func.isRequired,
  isRequestInProgress: PropTypes.bool.isRequired,
  stage_id: PropTypes.string,
  stages: PropTypes.arrayOf(PropTypes.object).isRequired,
}
StagesModal.defaultProps = {
  stage_id: null,
}

const mapState = state => ({
  isRequestInProgress: state.app.isRequestInProgress,
  stage_id: state.app.activeSale.stage_id,
  stages: state.app.stages,
})
const mapDispatch = dispatch => ({
  changeStage: (sale_id, stage) => dispatch(changeStageAction(sale_id, stage)),
})

export default connect(mapState, mapDispatch)(StagesModal)