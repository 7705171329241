import React, { Component } from 'react'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import { connect } from 'react-redux'

import { 
setModalEmailSentAction,
setModalEmailEmailAction,
setModalEmailDocTypeAction,
setModalEmailDocIdAction
} from '../../redux/reducers/app/actions'

class EmailButton extends Component {
  handleClick = () => {
    const { 
    doc_type, 
    doc_id, 
    email_to_send,
    setModalEmailSent,
    setModalEmailEmail,
    setModalEmailDocType,
    setModalEmailDocId
    } = this.props
    
      setModalEmailSent(false);//triggers the modal
      setModalEmailEmail(email_to_send);//populates the modal fields
      setModalEmailDocType(doc_type);//populates the modal fields
      setModalEmailDocId(doc_id);//populates the modal fields


  }

  render() {
    return (
      <Button
        style={{fontSize:'9pt'}}
        content="Email"
        onClick={this.handleClick}
      />
    )
  }
}

const mapState = state => ({
  sale_id: state.app.activeSale._id,
})
const mapDispatch = dispatch => ({
  setModalEmailSent: (bool) => dispatch(setModalEmailSentAction(bool)),
  setModalEmailEmail: (txt) => dispatch(setModalEmailEmailAction(txt)),
  setModalEmailDocType: (txt) => dispatch(setModalEmailDocTypeAction(txt)),
  setModalEmailDocId: (txt) => dispatch(setModalEmailDocIdAction(txt)),
})

export default connect(mapState, mapDispatch)(EmailButton)
