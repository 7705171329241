import React, { Component } from 'react'
import { connect } from 'react-redux'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import { Icon, Button } from 'semantic-ui-react'

import { searchSalesAction, newSaleAction } from '../../redux/reducers/app/actions'

import CustomerDetailsDoc from './CustomerDetailsDoc'

class CustomerDetailsLine extends Component {

	state={}

  toggleDetails=(e,elmprops)=>{
    const { customer } = this.props
    const { doctype } = elmprops
    document.getElementById(doctype+customer._id).style.display=(document.getElementById(doctype+customer._id).style.display==='block'?'none':'block');
  }

  handleNewSale = () => {
  	  const { customer, newSale } = this.props
  	  this.setState({newSaleBusy:true})
  	  newSale(customer._id);
  }

  render() {
    const {customer,permissions} = this.props
    const {newSaleBusy} = this.state

    if(customer.company_name==='null' || customer.company_name===null){
      customer.company_name=<span style={{color:'silver'}}>(NoCompanyName)</span>;
    }
    if(customer.email==='null' || customer.email===null){
      customer.email=<span style={{color:'silver'}}>(NoEmail)</span>;
    }
    if(customer.first_name==='null' || customer.first_name===null){
      customer.first_name=<span style={{color:'silver'}}>(NoFirstName)</span>;
    }
    if(customer.last_name==='null' || customer.last_name===null){
      customer.last_name=<span style={{color:'silver'}}>(NoLastName)</span>;
    }

    return (

        <Segment stacked>
          {(permissions.indexOf('Despatch')===-1 && permissions.indexOf('ViewOnly')===-1 && permissions.indexOf('Production')===-1) && <Button color='green' style={{float:'right'}} onClick={this.handleNewSale} loading={newSaleBusy}>New Sale</Button>}
          <Grid columns='equal'>
            <Grid.Row>
              <Grid.Column>
                <b>{customer.company_name}{' ('}{customer.code}{')'}</b>
                <Divider clearing fitted />
              </Grid.Column>

              <Grid.Column>
              {customer.first_name}{' '}{customer.last_name}
                <Divider clearing fitted />
              </Grid.Column>

              <Grid.Column>
                {customer.email}
                <Divider clearing fitted />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column style={{textAlign:'center',color:((customer.account_balance.total*1)>0?'red':'silver')}}>
                {(customer.account_balance.total*1).toFixed(2)}{' owing on Total'}
                <Divider clearing fitted />
              </Grid.Column>

              <Grid.Column style={{textAlign:'center',color:((customer.account_balance.on_so*1)>0?'red':'silver')}}>
                {(customer.account_balance.on_so*1).toFixed(2)}{' owing Un-despatched'}
                <Divider clearing fitted />
              </Grid.Column>

              <Grid.Column style={{textAlign:'center',color:((customer.account_balance.on_inv*1)>0?'red':'silver')}}>
                {(customer.account_balance.on_inv*1).toFixed(2)}{' owing on Invoices'}
                <Divider clearing fitted />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <span style={{color:'gray'}}><Icon id="icon1" name='file alternate outline'/>Quotes</span>
                <Divider clearing fitted />
                {customer.salesquotes ?
                  <div>
                    <div style={{marginBottom:'5px'}}>
                      <Button doctype='quotes' onClick={this.toggleDetails}>Toggle Quotes ({customer.salesquotes.length})</Button>
                    </div>
                    <div id={'quotes'+customer._id} style={{display:'none'}}>
                      {customer.salesquotes && customer.salesquotes.map((doc) => <CustomerDetailsDoc key={doc._id} doc={doc} done='converted' short='Quote:' classnm='bb_sales_quotes' />)}
                    </div>
                  </div>
                :
                  <span style={{color:'silver'}}>None</span>
                }
              </Grid.Column>

              <Grid.Column>
                <span style={{color:'gray'}}><Icon id="icon2" name='file alternate outline'/>Sales Orders</span>
                <Divider clearing fitted />
                {customer.salesorders ?
                  <div>
                    <div style={{marginBottom:'5px'}}>
                      <Button doctype='salesorders' onClick={this.toggleDetails}>Toggle Sales Orders ({customer.salesorders.length})</Button>
                    </div>
                    <div id={'salesorders'+customer._id} style={{display:'none'}}>
                      {customer.salesorders && customer.salesorders.map((doc)=><CustomerDetailsDoc key={doc._id} doc={doc} done='despatched' short='SO:' classnm='bb_sales_orders' />)}
                    </div>
                  </div>
                :
                  <span style={{color:'silver'}}>None</span>
                }
              </Grid.Column>

              <Grid.Column>
                <span style={{color:'gray'}}><Icon id="icon3" name='file alternate outline'/>Invoices</span>
                <Divider clearing fitted />
                {customer.salesdebtorsinvoices ?
                  <div>
                    <div style={{marginBottom:'5px'}}>
                      <Button doctype='salesdebtorsinvoices' onClick={this.toggleDetails}>Toggle Invoices ({customer.salesdebtorsinvoices.length})</Button>
                    </div>
                    <div id={'salesdebtorsinvoices'+customer._id} style={{display:'none'}}>
                      {customer.salesdebtorsinvoices && customer.salesdebtorsinvoices.map((doc)=><CustomerDetailsDoc key={doc._id} doc={doc} customer={customer} done='paid' short='Inv:' classnm='bb_sales_debtors_invoices' />)}
                    </div>
                  </div>
                  :
                  <span style={{color:'silver'}}>None</span>
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>


    )
  }
}

const mapState = state => ({
	email: state.auth.user.email,
})

const mapDispatch = dispatch => ({
  searchSales: q => dispatch(searchSalesAction(q)),
  newSale: (customer_id, quote_id) => dispatch(newSaleAction(customer_id, quote_id)),
})

export default connect(mapState,mapDispatch)(CustomerDetailsLine)
