import React, { Component } from 'react'
import {Modal, Button, Segment, Input, Form} from 'semantic-ui-react'
import { connect } from 'react-redux'

import { sendEmailAction,setModalEmailSentAction} from '../redux/reducers/app/actions'

class EmailModal extends Component {
  
  sendEmailHandle=()=>{
    const {emailModalDocType,emailModalDocId,sendEmail} = this.props
    console.log(this.props)
    sendEmail(document.getElementById('email_to_send_to').value,document.getElementById('email_to_send_bcc').value,emailModalDocType,emailModalDocId)
  }

  openModal=()=>{
  	  const {setModalEmailSent} = this.props
  	  setModalEmailSent(false)
  }
  
  closeModal=()=>{
  	  const {setModalEmailSent} = this.props
  	  setModalEmailSent(true)
  }

  render() {
    const { 
    	    email, 
    	    emailModalSending, 
    	    emailModalSent, 
    	    emailModalEmail,
    	    emailModalDocType,
    	    emailModalDocId
    } = this.props
    
    var doc_desc=''
    if(emailModalDocType==='bb_sales_debtors_invoices'){
    	    doc_desc+=' Invoice '
    }else if(emailModalDocType==='bb_sales_orders'){
    	    doc_desc+=' Sales Order '
    }else if(emailModalDocType==='bb_sales_quotes'){
    	    doc_desc+=' Quote '
    }else{
    	    doc_desc+=' Other '
    }
     doc_desc+=' '+emailModalDocId
    		    
    return (
    	    <span>
		<Modal onClose={this.closeModal} dimmer={'blurring'} open={emailModalSent}>
		<Modal.Header>Email this document : <small>{doc_desc}</small> </Modal.Header>
		<Modal.Content>
		  <Modal.Description>
		    <Segment basic loading={emailModalSending}>
		    <Form>
		      <Form.Field inline>
			<label>To:</label>
			<Input fluid placeholder='To email addresses...' id='email_to_send_to' defaultValue={emailModalEmail}/>
		      </Form.Field>
		      <Form.Field inline>
			<label>Bcc:</label>
			<Input fluid placeholder='Bcc email addresses...' id='email_to_send_bcc' defaultValue={email}/>
		      </Form.Field>
		      <Button color='blue' onClick={this.sendEmailHandle}>Send Email</Button>
		    </Form>
		    </Segment>
		  </Modal.Description>
		</Modal.Content>
	      </Modal>
         </span>
    )
  }
}

const mapState = state => ({
  email: (state.auth.user?state.auth.user.email:null),
  session_id: (state.auth.user?state.auth.user.session_id:null),
  emailModalSending: state.app.emailModalSending,
  emailModalSent: (state.app.emailModalSent?false:true),
  emailModalEmail: state.app.emailModalEmail,
  emailModalDocType: state.app.emailModalDocType,
  emailModalDocId: state.app.emailModalDocId,
})
const mapDispatch = dispatch => ({
  sendEmail: (to_email,bcc_email,doc_type,doc_id) => dispatch(sendEmailAction(to_email,bcc_email,doc_type,doc_id)),
  setModalEmailSent: (bool) => dispatch(setModalEmailSentAction(bool)),
})

export default connect(mapState, mapDispatch)(EmailModal)
