import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Input from 'semantic-ui-react/dist/commonjs/elements/Input'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import { connect } from 'react-redux'

import { setAuthoriseModalIsOpenAction, authoriseAction } from '../redux/reducers/app/actions'

class AuthoriseModal extends Component {
  state = {
    user_barcode: '',
  }

  handleInputChange = (e, { name, value }) => this.setState({ [name]: value })

  handleAuthorise = () => {
    const { authorise, sale_id, successAction } = this.props
    const { user_barcode } = this.state
    authorise(sale_id, user_barcode, successAction)
  }

  closeModal = () => {
    const { setAuthoriseModalIsOpen } = this.props
    setAuthoriseModalIsOpen(false)
  }

  componentDidUpdate(prevProps, prevState) {
    if(this._input){
      this._input.focus();
    }
  }

  render() {
    const { isOpen, isRequestInProgress } = this.props
    const { user_barcode } = this.state

    return (
      <Modal
        onClose={this.closeModal}
        open={isOpen}
        size="small"
      >
        <Modal.Header>
          Authorise Payment
        </Modal.Header>
        <Modal.Content>
          <Input
            fluid
            type="password"
            name="user_barcode"
            onChange={this.handleInputChange}
            placeholder="Scan supervisor barcode..."
            value={user_barcode}
            ref={c => (this._input = c)}
            onFocus = {() => this.setState({
                user_barcode: '',
              })
            }
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.closeModal}>Cancel</Button>
          <Button primary onClick={this.handleAuthorise} loading={isRequestInProgress}>Submit</Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

AuthoriseModal.propTypes = {
  successAction: PropTypes.func.isRequired,

  // The following props from redux:
  authorise: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isRequestInProgress: PropTypes.bool.isRequired,
  sale_id: PropTypes.string.isRequired,
  setAuthoriseModalIsOpen: PropTypes.func.isRequired,
}

const mapState = state => ({
  isOpen: state.app.isAuthoriseModalOpen,
  isRequestInProgress: state.app.isRequestInProgress,
  sale_id: (state.app.activeSale?state.app.activeSale._id:0),
})
const mapDispatch = dispatch => ({
  authorise: (sale_id, user_barcode, successAction) => dispatch(authoriseAction(sale_id, user_barcode, successAction)),
  setAuthoriseModalIsOpen: value => dispatch(setAuthoriseModalIsOpenAction(value)),
})

export default connect(mapState, mapDispatch)(AuthoriseModal)
