import { sendRequest } from "../../../lib/api";
import {
  printToThermalPrinter,
  printToSystemPrinter
} from "../../../lib/print";
import { actionTypes, screens } from "../../constants";

export const clearFlashMessageAction = () => ({
  type: actionTypes.CLEAR_FLASH_MESSAGE
});
export const setFlashMessageAction = message => ({
  type: actionTypes.SET_FLASH_MESSAGE,
  payload: message
});
const setActiveSaleAction = sale => ({
  type: actionTypes.SET_ACTIVE_SALE,
  payload: sale
});
const setHpEmbAction = hpEmb => ({
  type: actionTypes.SET_HPEMB,
  payload: hpEmb
});
export const setPaymentTypeAction = type => ({
  type: actionTypes.SET_PAYMENT_TYPE,
  payload: type
});
export const setPaymentModalIsOpenAction = value => ({
  type: actionTypes.SET_PAYMENT_MODAL_IS_OPEN,
  payload: value
});
export const setAuthoriseModalIsOpenAction = value => ({
  type: actionTypes.SET_AUTHORISE_MODAL_IS_OPEN,
  payload: value
});
const setProductCategoriesAction = categories => ({
  type: actionTypes.SET_PRODUCT_CATEGORIES,
  payload: categories
});
const setProductsAction = products => ({
  type: actionTypes.SET_PRODUCTS,
  payload: products
});
const setProductOptionsAction = product_options => ({
  type: actionTypes.SET_PRODUCT_OPTIONS,
  payload: product_options
});
const setProductsLoadedAction = value => ({
  type: actionTypes.SET_PRODUCTS_LOADED,
  payload: value
});
const setRequestInProgressAction = value => ({
  type: actionTypes.SET_APP_REQUEST_IN_PROGRESS,
  payload: value
});
const setSaleCategoriesAction = categories => ({
  type: actionTypes.SET_SALE_CATEGORIES,
  payload: categories
});
export const setScreenAction = screen => ({
  type: actionTypes.SET_SCREEN,
  payload: screen
});
const setSearchResultsAction = results => ({
  type: actionTypes.SET_SEARCH_RESULTS,
  payload: results
});
export const setSearchResultsModalIsOpenAction = value => ({
  type: actionTypes.SET_SEARCH_RESULTS_MODAL_IS_OPEN,
  payload: value
});
const setStagesAction = stages => ({
  type: actionTypes.SET_STAGES,
  payload: stages
});
const setProductionSalesAction = sales => ({
  type: actionTypes.SET_PRODUCTION_SALES,
  payload: sales
});
export const setDiscModalAction = flag => ({
  type: actionTypes.SET_DISCMODAL,
  payload: flag
});
export const setSearchCustomerDetailsTextAction = txt => ({
  type: actionTypes.SET_SEARCH_CUSTOMER_DETAILS_TEXT,
  payload: txt
});
export const setSendingModalEmailAction = bool => ({
  type: actionTypes.SET_SENDING_MODAL_EMAIL,
  payload: bool
});
export const setModalEmailSentAction = bool => ({
  type: actionTypes.SET_MODAL_EMAIL_SENT,
  payload: bool
});
export const setModalEmailEmailAction = txt => ({
  type: actionTypes.SET_MODAL_EMAIL,
  payload: txt
});
export const setModalEmailDocTypeAction = txt => ({
  type: actionTypes.SET_MODAL_DOC_TYPE,
  payload: txt
});
export const setModalEmailDocIdAction = txt => ({
  type: actionTypes.SET_MODAL_DOC_ID,
  payload: txt
});
export const setSendingModalNoteAction = bool => ({
  type: actionTypes.SET_SENDING_MODAL_NOTE,
  payload: bool
});
export const setModalNoteSentAction = bool => ({
  type: actionTypes.SET_MODAL_NOTE_SENT,
  payload: bool
});

export const loadDataAction = () => {
  return async (dispatch, getState) => {
    dispatch(setProductsLoadedAction(false));
    dispatch(setProductCategoriesAction([]));
    dispatch(setProductsAction([]));
    dispatch(setProductOptionsAction([]));

    const tillName = getState().settings.tillName;
    if (!tillName || tillName === "") {
      dispatch(setProductsLoadedAction(true));
      dispatch(
        setFlashMessageAction({
          message:
            "There is no name for this till yet, please add one in settings (ie TILL1, TILL2 etc).",
          type: "negative"
        })
      );
      return;
    }

    // Load system data + products
    try {
      const data = await sendRequest("app_settings");
      //console.log('app_settings', data)
      dispatch(setProductCategoriesAction(data.product_categories));
      dispatch(setSaleCategoriesAction(data.sale_categories));
      dispatch(setProductsAction(data.products));
      dispatch(setProductOptionsAction(data.product_options));
      dispatch(setHpEmbAction(data.hpemb));
      dispatch(setStagesAction(data.stages));
      dispatch(setProductsLoadedAction(true));
      dispatch(
        setFlashMessageAction({
          message: "The products have been successfully loaded.",
          type: "positive"
        })
      );
    } catch (e) {
      dispatch(setProductsLoadedAction(true));
      dispatch(
        setFlashMessageAction({
          message: "There was a problem loading the products.",
          type: "negative"
        })
      );
    }
  };
};

export const addSaleItemAction = (
  sale_id,
  item_id,
  option_1_id,
  option_2_id
) => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      sale_id,
      item_id,
      option_1_id,
      option_2_id,
      session_id
    };
    //console.log('addSaleItemAction', data)
    const response = await sendRequest("add_sale_item", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to add item: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: "Item added successfully.",
        type: "positive"
      })
    );
    dispatch(setActiveSaleAction(response));
    dispatch(setRequestInProgressAction(false));
  };
};

export const editSaleItemAction = (
  sale_id,
  line_id,
  notes = null,
  quantity = null,
  price = null,
  _delete = null,
  discount = null
) => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      sale_id,
      session_id,
      line_id
    };
    if (notes) data.notes = notes;
    if (quantity) data.qty = quantity;
    if (price) data.price = price;
    if (_delete) data.delete = _delete;
    if (discount === 0 || discount * 1 > 0) data.discount = discount;
    //console.log('editSaleItemAction', data)
    const response = await sendRequest("edit_sale_item", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to edit item: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      dispatch(setScreenAction(screens.VIEW_SALE));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Item ${_delete ? "deleted" : "edited"} successfully.`,
        type: "positive"
      })
    );
    dispatch(setActiveSaleAction(response));
    dispatch(setRequestInProgressAction(false));
  };
};

export const sendEmailAction = (to_email, bcc_email, doc_type, doc_id) => {
  return async (dispatch, getState) => {
    dispatch(setModalEmailSentAction(false));
    dispatch(setSendingModalEmailAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      to_email,
      bcc_email,
      doc_type,
      doc_id,
      session_id
    };
    //console.log('searchCustomersAction', data)
    const response = await sendRequest("send_email", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to send email: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setSendingModalEmailAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Email sent.`,
        type: "positive"
      })
    );
    dispatch(setModalEmailSentAction(true));
    dispatch(setSendingModalEmailAction(false));
    return response;
  };
};

export const sendNoteAction = (new_note, sale_id) => {
  return async (dispatch, getState) => {
    dispatch(setModalNoteSentAction(false));
    dispatch(setSendingModalNoteAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      new_note,
      sale_id,
      session_id
    };
    //console.log('searchCustomersAction', data)
    const response = await sendRequest("add_note", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to add note: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setModalNoteSentAction(true));
      dispatch(setSendingModalNoteAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Note added.`,
        type: "positive"
      })
    );
    dispatch(setModalNoteSentAction(true));
    dispatch(setSendingModalNoteAction(false));
    dispatch(setActiveSaleAction(response));
    return response;
  };
};

export const searchCustomersAction = search_string => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      search_string,
      session_id
    };
    //console.log('searchCustomersAction', data)
    const response = await sendRequest("search_customers", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to search customers: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    if (
      Array.isArray(response.search_results) &&
      response.search_results.length > 0
    ) {
      dispatch(
        setSearchResultsAction(
          response.search_results.map(r => ({
            ...r,
            type: "customer"
          }))
        )
      );
      dispatch(setSearchResultsModalIsOpenAction(true));
    } else {
      dispatch(
        setFlashMessageAction({
          message: `No search results for "${search_string}".`,
          type: "negative"
        })
      );
    }
    dispatch(setRequestInProgressAction(false));
  };
};

export const searchQuotesAction = search_string => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      search_string,
      session_id
    };
    //console.log('searchQuotesAction', data)
    const response = await sendRequest("search_quotes", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to search quotes: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    if (
      Array.isArray(response.search_results) &&
      response.search_results.length > 0
    ) {
      dispatch(
        setSearchResultsAction(
          response.search_results.map(r => ({
            ...r,
            type: "quote"
          }))
        )
      );
      dispatch(setSearchResultsModalIsOpenAction(true));
    } else {
      dispatch(
        setFlashMessageAction({
          message: `No search results for "${search_string}".`,
          type: "negative"
        })
      );
    }
    dispatch(setRequestInProgressAction(false));
  };
};

export const searchSalesAction = search_string => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      search_string,
      session_id
    };
    //console.log('searchSalesAction', data)
    const response = await sendRequest("search_sales", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to search sales: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    if (
      Array.isArray(response.search_results) &&
      response.search_results.length > 0
    ) {
      dispatch(
        setSearchResultsAction(
          response.search_results.map(r => ({
            ...r,
            type: "sale"
          }))
        )
      );
      dispatch(setSearchResultsModalIsOpenAction(true));
    } else {
      dispatch(
        setFlashMessageAction({
          message: `No search results for "${search_string}".`,
          type: "negative"
        })
      );
    }
    dispatch(setRequestInProgressAction(false));
  };
};

export const addCustomerAction = (
  firstName,
  lastName,
  companyName,
  email,
  mobile
) => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      first_name: firstName,
      last_name: lastName,
      company_name: companyName,
      email,
      mobile,
      session_id
    };
    //console.log('addCustomerAction', data)
    const response = await sendRequest("add_customer", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to add customer: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(newSaleAction(response.customer_id));
    dispatch(setRequestInProgressAction(false));
  };
};

export const newSaleAction = (customer_id = null, quote_id = null) => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const tillName = getState().settings.tillName;
    const data = { session_id };
    if (customer_id) data.customer_id = customer_id;
    if (quote_id) data.quote_id = quote_id;
    if (tillName) data.till_name = tillName;
    //console.log('newSaleAction', data)
    const response = await sendRequest("new_sale", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to create new sale: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `New sale created successfully.`,
        type: "positive"
      })
    );
    dispatch(setActiveSaleAction(response));
    dispatch(setScreenAction(screens.NEW_SALE_DETAIL));
    dispatch(setRequestInProgressAction(false));
  };
};

export const loadSaleAction = (sale_id, paidFilter) => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      session_id,
      sale_id,
      paidFilter
    };
    //console.log('loadSaleAction', data)
    const response = await sendRequest("view_sale", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to load sale: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(setActiveSaleAction(response));
    dispatch(setScreenAction(screens.VIEW_SALE));
    dispatch(setRequestInProgressAction(false));
  };
};

export const loadQuoteAction = quote_id => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      session_id,
      quote_id
    };
    //console.log('loadSaleAction', data)
    const response = await sendRequest("view_quote", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to load quote: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(setActiveSaleAction(response));
    dispatch(setScreenAction(screens.VIEW_SALE));
    dispatch(setRequestInProgressAction(false));
  };
};

export const updateSaleAction = (
  sale_id,
  category = null,
  mobile = null,
  email = null,
  order_ref = null,
  delivery_address = null,
  notes = null,
  alert_name = null,
  toggle_backofficeYN = null,
  _datetime_queued = null
) => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      session_id,
      sale_id
    };
    if (category) data.category = category;
    if (mobile) data.mobile = mobile;
    if (email) data.email = email;
    if (order_ref) data.order_ref = order_ref;
    if (delivery_address) data.delivery_address = delivery_address;
    if (notes) data.notes = notes;
    if (alert_name) data.alert_name = alert_name;
    if (toggle_backofficeYN !== null)
      data.toggle_backofficeYN = toggle_backofficeYN;
    if (_datetime_queued !== null) data._datetime_queued = _datetime_queued;
    //console.log('updateSaleAction', data)
    const response = await sendRequest("update_sale", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to update sale: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Updated sale successfully.`,
        type: "positive"
      })
    );
    dispatch(setActiveSaleAction(response));
    dispatch(setRequestInProgressAction(false));
  };
};

export const cancelSaleAction = sale_id => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      session_id,
      sale_id
    };
    data.cancel = 1;
    //console.log('cancelSaleAction', data)
    const response = await sendRequest("update_sale", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to update sale: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Updated sale successfully.`,
        type: "positive"
      })
    );
    dispatch(setActiveSaleAction(response));
    dispatch(setRequestInProgressAction(false));
  };
};

export const allocateStockAction = sale_id => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      session_id,
      sale_id
    };
    //console.log('allocateStockAction', data)
    const response = await sendRequest("allocate_stock", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to allocate stock: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Stock allocated successfully.`,
        type: "positive"
      })
    );
    dispatch(loadSaleAction(sale_id)); // we don't get updated sale data in the response, so reload the sale
    dispatch(setRequestInProgressAction(false));
  };
};

export const despatchAction = (sale_id, lines) => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      session_id,
      sale_id,
      lines
    };
    //console.log('despatchAction', data)
    const response = await sendRequest("despatch", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to despatch: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Despatched successfully.`,
        type: "positive"
      })
    );
    dispatch(loadSaleAction(sale_id)); // reload the sale
    dispatch(setRequestInProgressAction(false));
  };
};

export const changeStageAction = (sale_id, stage_id) => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      session_id,
      sale_id,
      stage_id
    };
    //console.log('changeStageAction', data)
    const response = await sendRequest("change_stage", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to change stage: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Changed stage successfully.`,
        type: "positive"
      })
    );
    dispatch(setActiveSaleAction(response));
    dispatch(setRequestInProgressAction(false));
  };
};

export const addSaleSignatureAction = (sale_id, file, print_name, sigtype) => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      session_id,
      sale_id,
      print_name,
      files: [
        {
          file,
          filename: "signature" + (sigtype ? "_" + sigtype : "") + ".png"
        }
      ]
    };
    //console.log('addSaleSignatureAction', data)
    const response = await sendRequest("add_sale_signature", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to add signature: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Added signature successfully.`,
        type: "positive"
      })
    );
    dispatch(setActiveSaleAction(response));
    dispatch(setRequestInProgressAction(false));
  };
};

export const addSaleHPEmbAction = (sale_id, hpemb) => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));

    const embroidery_logosYN = hpemb.embroideryLogos.length > 0 ? "1" : "0";
    const heat_press_logosYN = hpemb.heatPressLogos.length > 0 ? "1" : "0";
    const embroidery_initialsYN = hpemb.embroideryNames.length > 0 ? "1" : "0";
    const heat_press_initialsYN = hpemb.heatPressNames.length > 0 ? "1" : "0";
    const sublimationYN = hpemb.sublimations.length > 0 ? "1" : "0";

    const session_id = getState().auth.user.session_id;
    const data = {
      session_id,
      sale_id,
      hpemb: {
        embroidery_logosYN,
        heat_press_logosYN,
        embroidery_initialsYN,
        heat_press_initialsYN,
        sublimationYN,
        embroidery_logosNUM: hpemb.embroideryLogos.length.toString(),
        heat_press_logosNUM: hpemb.heatPressLogos.length.toString(),
        embroidery_initialsNUM: hpemb.embroideryNames.length.toString(),
        heat_press_initialsNUM: hpemb.heatPressNames.length.toString(),
        sublimationNUM: hpemb.sublimations.length.toString(),
        embroidery_logos: {
          rows: hpemb.embroideryLogos.map(i => ({
            text: i.text,
            location: i.location
          }))
        },
        heat_press_logos: {
          rows: hpemb.heatPressLogos.map(i => ({
            text: i.text,
            location: i.location
          }))
        },
        embroidery_initials: {
          rows: hpemb.embroideryNames.map(i => ({
            text: i.text,
            location: i.location,
            size: i.size,
            color: i.color
          }))
        },
        heat_press_initials: {
          rows: hpemb.heatPressNames.map(i => ({
            text: i.text,
            location: i.location,
            size: i.size,
            color: i.color
          }))
        },
        sublimation: {
          rows: hpemb.sublimations.map(i => ({
            size: i.size,
            text: i.text
          }))
        }
      }
    };
    //console.log('addSaleHPEmbAction', data)
    const response = await sendRequest("add_sale_hpemb", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to add HPEmb: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Added HPEmb successfully.`,
        type: "positive"
      })
    );
    dispatch(setActiveSaleAction(response));
    dispatch(setRequestInProgressAction(false));
  };
};

export const addPaymentAction = (sale_id, type, amount, ref) => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));

    const session_id = getState().auth.user.session_id;
    const tillName = getState().settings.tillName;
    const data = {
      session_id,
      sale_id,
      type,
      amount,
      ref
    };
    if (tillName) data.till_name = tillName;
    //console.log('addPaymentAction', data)
    const response = await sendRequest("add_payment", data);
    //console.log(response);
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to add payment: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      dispatch(setPaymentModalIsOpenAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Added payment successfully.`,
        type: "positive"
      })
    );
    // Print receipt if sale completed
    if (response.sale_completedYN * 1 === 1) {
      localStorage.setItem("saleCompleted", 1);
      dispatch(printReceiptAction(sale_id));
    }
    dispatch(setActiveSaleAction(response));
    dispatch(setRequestInProgressAction(false));
    dispatch(setPaymentModalIsOpenAction(false));
    // Redirect if sale completed
    if (response.sale_completedYN * 1 === 1) {
      dispatch(setScreenAction(screens.VIEW_SALE));
    }
  };
};

export const authoriseAction = (sale_id, user_barcode, successAction) => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));

    const session_id = getState().auth.user.session_id;
    const data = {
      session_id,
      sale_id,
      user_barcode
    };
    //console.log('authoriseAction', data)
    const response = await sendRequest("authorise", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to authorise: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Authorised successfully.`,
        type: "positive"
      })
    );
    dispatch(setRequestInProgressAction(false));
    dispatch(setAuthoriseModalIsOpenAction(false));
    successAction();
  };
};

export const productionAction = (filter = null) => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));

    const session_id = getState().auth.user.session_id;
    const data = {
      session_id
    };
    if (filter) data.filter = filter;
    //console.log('productionAction', data)
    const response = await sendRequest("production", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to fetch production sales: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Fetched production sales successfully.`,
        type: "positive"
      })
    );
    dispatch(
      setProductionSalesAction(
        Object.keys(response)
          .filter(k => k !== "message")
          .map(k => response[k])
      )
    );
    dispatch(setRequestInProgressAction(false));
  };
};

export const addSaleImageAction = (sale_id, file, filename) => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      session_id,
      sale_id,
      files: [
        {
          file,
          filename
        }
      ]
    };
    //console.log('addSaleImageAction', data)
    const response = await sendRequest("add_sale_image", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to add image: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Added image successfully.`,
        type: "positive"
      })
    );
    dispatch(setActiveSaleAction(response));
    dispatch(setRequestInProgressAction(false));
  };
};

export const saveAsQuoteAction = sale_id => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      session_id,
      sale_id
    };
    //console.log('saveAsQuoteAction', data)
    const response = await sendRequest("save_as_quote", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to save quote: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Saved quote successfully.`,
        type: "positive"
      })
    );
    dispatch(loadSaleAction(sale_id)); // we don't get updated sale data in the response, so reload the sale
    dispatch(setRequestInProgressAction(false));
  };
};

export const addSaleDocumentAction = (sale_id, file, filename) => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      session_id,
      sale_id,
      files: [
        {
          file,
          filename
        }
      ]
    };
    //console.log('addSaleDocumentAction', data)
    const response = await sendRequest("add_sale_doc", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to add document: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Added document successfully.`,
        type: "positive"
      })
    );
    dispatch(setActiveSaleAction(response));
    dispatch(setRequestInProgressAction(false));
  };
};

export const printStickerAction = sale_id => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      session_id,
      sale_id
    };
    //console.log('printStickerAction', data)
    const response = await sendRequest("print_sticker", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to print sticker: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Printed sticker successfully.`,
        type: "positive"
      })
    );
    if (window.cordova) {
      let dataString = "";
      dataString += String.fromCharCode(27) + "@\n"; //reset
      dataString += String.fromCharCode(27) + "a1\n"; //center
      dataString += response.sticker_data;
      dataString += String.fromCharCode(29) + "VA\n"; //cut: TODO: not working?
      dataString +=
        String.fromCharCode(27) +
        String.fromCharCode(112) +
        String.fromCharCode(0) +
        String.fromCharCode(50) +
        String.fromCharCode(250); //open drawer
      const printerIPAddress = getState().settings.printerIPAddress;
      const port = getState().settings.printerPort;
      printToThermalPrinter(printerIPAddress, port, dataString);
      // const printResult = printToThermalPrinter(printerIPAddress, port, dataString)
      // if (!printResult) {
      //   // Fallback to system printer
      //   const systemPrintResult = printToSystemPrinter(response.sticker_data)
      //   if (typeof systemPrintResult === 'string') {
      //     dispatch(setFlashMessageAction({
      //       message: printResult,
      //       type: 'negative',
      //     }))
      //   }
      // }
    } else {
      const printResult = printToSystemPrinter(response.sticker_data);
      if (typeof printResult === "string") {
        dispatch(
          setFlashMessageAction({
            message: printResult,
            type: "negative"
          })
        );
      }
    }
    dispatch(setRequestInProgressAction(false));
  };
};

export const printReceiptAction = sale_id => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    const session_id = getState().auth.user.session_id;
    const data = {
      session_id,
      sale_id
    };
    //console.log('printReceiptAction', data)
    const response = await sendRequest("print_receipt", data);
    //console.log(response)
    if (response.error) {
      dispatch(
        setFlashMessageAction({
          message: `Unable to print receipt: ${response.message}`,
          type: "negative"
        })
      );
      dispatch(setRequestInProgressAction(false));
      return;
    }
    dispatch(
      setFlashMessageAction({
        message: `Printed receipt successfully.`,
        type: "positive"
      })
    );
    if (window.cordova) {
      let dataString = "";
      dataString += String.fromCharCode(27) + "@\n"; //reset
      dataString += String.fromCharCode(27) + "a1\n"; //center
      dataString += response.receipt_data;
      dataString += String.fromCharCode(29) + "VA\n"; //cut: TODO: not working?
      dataString +=
        String.fromCharCode(27) +
        String.fromCharCode(112) +
        String.fromCharCode(0) +
        String.fromCharCode(50) +
        String.fromCharCode(250); //open drawer
      const printerIPAddress = getState().settings.printerIPAddress;
      const port = getState().settings.printerPort;
      printToThermalPrinter(printerIPAddress, port, dataString);
      // const printResult = printToThermalPrinter(printerIPAddress, port, dataString)
      // if (!printResult) {
      //   // Fallback to system printer
      //   const systemPrintResult = printToSystemPrinter(response.receipt_data)
      //   if (typeof systemPrintResult === 'string') {
      //     dispatch(setFlashMessageAction({
      //       message: printResult,
      //       type: 'negative',
      //     }))
      //   }
      // }
    } else {
      const printResult = printToSystemPrinter(response.receipt_data);
      if (typeof printResult === "string") {
        dispatch(
          setFlashMessageAction({
            message: printResult,
            type: "negative"
          })
        );
      }
    }
    dispatch(setRequestInProgressAction(false));
  };
};

export const printCardSlipAction = printme => {
  return async (dispatch, getState) => {
    dispatch(setRequestInProgressAction(true));
    let printmesplit = printme.match(/.{1,30}/g);
    printmesplit = printmesplit.join("<br>");
    //console.log('printCardSlipAction', printmesplit)
    dispatch(
      setFlashMessageAction({
        message: `Printed card slip successfully.`,
        type: "positive"
      })
    );
    if (window.cordova) {
      let dataString = "";
      dataString += String.fromCharCode(27) + "@\n"; //reset
      dataString += String.fromCharCode(27) + "a1\n"; //center
      dataString += printmesplit;
      dataString += String.fromCharCode(29) + "VA\n"; //cut: TODO: not working?
      dataString +=
        String.fromCharCode(27) +
        String.fromCharCode(112) +
        String.fromCharCode(0) +
        String.fromCharCode(50) +
        String.fromCharCode(250); //open drawer
      const printerIPAddress = getState().settings.printerIPAddress;
      const port = getState().settings.printerPort;
      printToThermalPrinter(printerIPAddress, port, dataString);
      // const printResult = printToThermalPrinter(printerIPAddress, port, dataString)
      // if (!printResult) {
      //   // Fallback to system printer
      //   const systemPrintResult = printToSystemPrinter(response.receipt_data)
      //   if (typeof systemPrintResult === 'string') {
      //     dispatch(setFlashMessageAction({
      //       message: printResult,
      //       type: 'negative',
      //     }))
      //   }
      // }
    } else {
      const printResult = printToSystemPrinter(printmesplit);
      if (typeof printResult === "string") {
        dispatch(
          setFlashMessageAction({
            message: printResult,
            type: "negative"
          })
        );
      }
    }
    dispatch(setRequestInProgressAction(false));
  };
};
