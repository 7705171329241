import React, { Component } from 'react'
import PropTypes from 'prop-types'
var QRCodeTag = require('qrcode.react');

class QRCode extends Component {
  render() {
    const { sale_id } = this.props
    return(
    	    <QRCodeTag value={sale_id} renderAs='svg' />
    	    )
  }
}

QRCode.propTypes = {
  sale_id: PropTypes.string.isRequired,
}

export default QRCode




