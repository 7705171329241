import React, { Component } from "react";
import PropTypes from "prop-types";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import { connect } from "react-redux";

import AllocateStockButton from "./AllocateStockButton";
import BorderlessTableCell from "../BorderlessTableCell";
import BorderlessTableRow from "../BorderlessTableRow";
import DespatchButton from "./DespatchButton";
import DeliveryAddress from "./DeliveryAddress";
//import EditableInput from '../EditableInput'
import EditButton from "./EditButton";
import CancelButton from "./CancelButton";
import FilesList from "../FilesList";
import ItemsTable from "../ItemsTable";
import NextStageButton from "./NextStageButton";
import PayCashButton from "../PayCashButton";
import PayCardButton from "../PayCardButton";
import PayChequeButton from "../PayChequeButton";
import PaymentDetails from "../PaymentDetails";
import PayOnAccountButton from "../PayOnAccountButton";
import PayOverrideButton from "../PayOverrideButton";
import PaymentModal from "../PaymentModal";
import PrintStickerButton from "./PrintStickerButton";
import EmailButton from "./EmailButton";
import PrintReceiptButton from "./PrintReceiptButton";
import PrintA4Button from "./PrintA4Button";
import QRCode from "./QRCode";
import SaleHeader from "../SaleHeader";
import SaleNotes from "./SaleNotes";
import HpembDisplay from "./HpembDisplay";
import SalePeopleList from "./SalePeopleList";
import SaleSummary from "../SaleSummary";
import withAuth from "../../lib/withAuth";

import SaveQuoteForm from "./SaveQuoteForm";

import SignatureButton from "../NewSaleDetailScreen/SignatureButton";

import {
  addSaleSignatureAction,
  setPaymentModalIsOpenAction,
  setPaymentTypeAction,
  updateSaleAction,
  addPaymentAction,
  saveAsQuoteAction,
  setSearchCustomerDetailsTextAction,
  setScreenAction,
  newSaleAction
} from "../../redux/reducers/app/actions";

class ViewSaleScreen extends Component {
  handleUpdateSale = (sale_id, data) => {
    const { updateSale } = this.props;
    const {
      category,
      mobile,
      email,
      order_ref,
      delivery_address,
      notes
    } = data;
    updateSale(
      sale_id,
      category,
      mobile,
      email,
      order_ref,
      delivery_address,
      notes
    );
  };

  closePaymentModal = () => {
    const { setPaymentType, setPaymentModalIsOpen } = this.props;
    setPaymentType(null);
    setPaymentModalIsOpen(false);
  };

  openPaymentModal = paymentType => {
    const { setPaymentType, setPaymentModalIsOpen } = this.props;
    setPaymentType(paymentType);
    setPaymentModalIsOpen(true);
  };

  handleAddPayment = (sale_id, type, amount, ref) => {
    const { addPayment } = this.props;
    addPayment(sale_id, type, amount, ref);
  };

  handleSaveQuote = sale_id => {
    const { saveAsQuote } = this.props;
    saveAsQuote(sale_id);
  };

  handleConvertQuote = (obj, elm) => {
    const { newSale } = this.props;
    newSale(elm.customer_id, elm.this_quote_id);
  };

  showStatus = activeSale => {
    if (typeof activeSale.customer.status.OK !== "undefined") {
      return (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: activeSale.customer.status.OK }}
        ></div>
      );
    } else {
      return (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: activeSale.customer.status.error }}
        ></div>
      );
    }
  };

  handleAddSaleSignature = (sale_id, file, print_name) => {
    const { addSaleSignature, activeSale } = this.props;
    const { stage_name } = activeSale;
    var sigtype =
      stage_name &&
      (stage_name.indexOf("despatch") || stage_name.indexOf("collect"))
        ? "despatch"
        : "";
    addSaleSignature(sale_id, file, print_name, sigtype);
  };

  viewCustomer = () => {
    const { setSearchCustomerDetailsText, activeSale, setScreen } = this.props;
    setSearchCustomerDetailsText(activeSale.customer.code);
    setScreen("CUSTOMER_DETAILS");
  };

  render() {
    const {
      activeSale,
      isPaymentModalOpen,
      isRequestInProgress,
      paymentType,
      permissions,
      tillName
    } = this.props;

    if (!permissions) {
      return <div></div>;
    }
    //console.log(activeSale)
    const {
      _id,
      sale_completedYN,
      sale_completed_now,
      sales_orderISbb_sales_ordersID,
      second_sales_orderISbb_sales_ordersID,
      saved_to_quoteISbb_sales_quotesID,
      this_quote_id, //indicates this is a quote
      alert_email,
      alert_mobile,
      alert_name,
      hpemb,
      customerISbb_usersID_IN_Debtors,
      white_lines,
      customer: {
        //first_name,
        //last_name,
        mobile,
        email,
        //street_address,
        //street_address2,
        //street_suburb,
        //street_city,
        //street_zipcode,
        credit_limitCUR,
        status: { available_to_spend }
      },
      delivery_addressISsmallplaintextbox,
      customer_order_number,
      files,
      grand_totalCUR,
      tax_totalCUR,
      items_totalCUR,
      external_notesISsmallplaintextbox,
      stage_name,
      stage_notes,
      payments,
      rows,
      sales_category,
      ship_date,
      stage_id,
      stage_log,
      transaction_date,
      total_owingCUR,
      total_paidCUR,
      total_outstandingNUM,
      signature_taken,
      teller,
      sales_order_cancelledYN,
      toggle_backofficeYN,
      _datetime_queued,
      hpemb_failed
    } = activeSale;

    //console.log(activeSale);

    //const customer = `${first_name} ${last_name}`
    const price = grand_totalCUR ? parseFloat(grand_totalCUR) : 0;

    const signatureImage = files.find(f => f.type === "SIGNATURE_DESPATCH");
    const signatureImageURL = signatureImage ? signatureImage.file : null;

    let show_edit_button = <span></span>;
    let show_cancel_button = <span></span>;
    if (
      sales_orderISbb_sales_ordersID * 1 === 0 &&
      saved_to_quoteISbb_sales_quotesID * 1 === 0 &&
      sales_order_cancelledYN * 1 !== 1 &&
      sale_completedYN !== 1
    ) {
      show_edit_button = (
        <span>
          <EditButton />
          <Divider hidden clearing />
        </span>
      );
    }
    if (
      sales_orderISbb_sales_ordersID * 1 === 0 &&
      saved_to_quoteISbb_sales_quotesID * 1 === 0 &&
      total_paidCUR * 1 === 0 &&
      sales_order_cancelledYN * 1 !== 1 &&
      permissions &&
      permissions.indexOf("ViewOnly") === -1 &&
      permissions.indexOf("Production") === -1 &&
      permissions.indexOf("Despatch") === -1
    ) {
      show_cancel_button = (
        <span>
          <CancelButton sale_id={_id} />
          <Divider hidden clearing />
        </span>
      );
    }

    let payment_buttons = <span></span>;
    //to show pay-buttons the sale needs to be a tablet sale (ie not a sales order or quote) and the till needs to be named as TILL1 etc
    if (
      sale_completedYN !== 1 &&
      sales_order_cancelledYN * 1 !== 1 &&
      this_quote_id * 1 === 0
    ) {
      if (
        sales_orderISbb_sales_ordersID * 1 === 0 &&
        second_sales_orderISbb_sales_ordersID * 1 === 0 &&
        saved_to_quoteISbb_sales_quotesID * 1 === 0 &&
        tillName.indexOf("TILL") !== -1
      ) {
        //if the customer is cash customer, and the sale has white lines and any of the 3 required fields are empty then show an alert
        if (!sales_category && white_lines * 1 > 0) {
          payment_buttons = (
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column></Grid.Column>
                <Grid.Column width={8}>
                  <Segment inverted color="violet">
                    CATEGORY MISSING - please select a sale category before
                    proceeding...
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        } else if (hpemb_failed * 1 === 1) {
          payment_buttons = (
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column></Grid.Column>
                <Grid.Column width={8}>
                  <Segment inverted color="purple">
                    HEAT-PRESS/EMROIDERY instructions are missing for this
                    order. Please enter them to proceed...
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        } else if (
          customerISbb_usersID_IN_Debtors * 1 === 8 &&
          white_lines * 1 > 0 &&
          (!alert_name ||
            alert_name.length <= 3 ||
            ((!alert_mobile || alert_mobile.length <= 3) &&
              (!alert_email || alert_email.length <= 3)))
        ) {
          payment_buttons = (
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column></Grid.Column>
                <Grid.Column width={8}>
                  <Segment inverted color="red">
                    CASH CUSTOMER name and email or mobile needed to proceed
                    because some items will be on backorder
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        } else if (
          white_lines * 1 === 0 ||
          signature_taken * 1 === 1 ||
          total_owingCUR * 1 === 0
        ) {
          payment_buttons = (
            <span>
              {rows && rows.length > 0 && white_lines * 1 === 0 && (
                <Segment inverted color="green" secondary>
                  <b>Please check:</b> No Back-Order Items - all taken now?
                </Segment>
              )}
              <PayChequeButton onClick={this.openPaymentModal} />
              <PayCashButton onClick={this.openPaymentModal} />
              <PayCardButton onClick={this.openPaymentModal} />
              {credit_limitCUR * 1 >= 500 && available_to_spend * 1 >= price ? (
                <PayOnAccountButton onClick={this.openPaymentModal} />
              ) : (
                <span />
              )}
              {grand_totalCUR * 1 > 0 && (
                <PayOverrideButton onClick={this.openPaymentModal} />
              )}
            </span>
          );
        } else {
          payment_buttons = (
            <span>
              <SignatureButton
                onAddSaleSignature={this.handleAddSaleSignature}
                sale_id={_id}
                stage_name={stage_name ? stage_name : ""}
              />
              <PayOverrideButton onClick={this.openPaymentModal} />
            </span>
          );
        }
        if (
          permissions.indexOf("ViewOnly") !== -1 ||
          permissions.indexOf("Despatch") !== -1 ||
          permissions.indexOf("Quality Control") !== -1 ||
          permissions.indexOf("Embroidery") !== -1 ||
          permissions.indexOf("Production") !== -1 ||
          permissions.indexOf("TeamWear") !== -1
        ) {
          payment_buttons = <span></span>;
        }
      }
    }
    if (
      permissions &&
      (permissions.indexOf("ViewOnly") !== -1 ||
        permissions.indexOf("Despatch") !== -1) &&
      this_quote_id * 1 === 0
    ) {
      payment_buttons = (
        <span>
          <SignatureButton
            onAddSaleSignature={this.handleAddSaleSignature}
            sale_id={_id}
            stage_name={stage_name ? stage_name : ""}
          />
        </span>
      );
    }

    let mainStyle = { padding: 0, margin: 0, backgroundColor: "#eeeeee" };
    if (sale_completedYN === 1) {
      mainStyle.backgroundColor = "#eeeeff";
    }
    if (sales_orderISbb_sales_ordersID * 1 > 0) {
      mainStyle.backgroundColor = "#eeffee";
    }
    if (sales_order_cancelledYN * 1 === 1) {
      mainStyle.backgroundColor = "#ffeeee";
    }
    if (this_quote_id * 1 > 0) {
      mainStyle.backgroundColor = "#EBF5FB";
    }

    if (sale_completed_now === 1 && permissions.indexOf("Supervisor") === -1) {
      //logoff here
    }

    return (
      <Grid
        as={Segment}
        basic
        stackable
        loading={isRequestInProgress}
        style={mainStyle}
      >
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column width={10}>
            <SaleHeader
              this_quote_id={this_quote_id}
              sale_id={_id}
              sale_completedYN={sale_completedYN * 1}
              sales_order_cancelledYN={sales_order_cancelledYN}
              sales_orderISbb_sales_ordersID={sales_orderISbb_sales_ordersID}
              second_sales_orderISbb_sales_ordersID={
                second_sales_orderISbb_sales_ordersID
              }
              saved_to_quoteISbb_sales_quotesID={
                saved_to_quoteISbb_sales_quotesID
              }
              text={this_quote_id * 1 > 0 ? "View Quote:" : `View Sale:`}
            />
            {sales_order_cancelledYN * 1 === 1 && (
              <div
                style={{
                  background: "red",
                  color: "white",
                  textAlign: "center",
                  padding: "10px"
                }}
              >
                ***CANCELLED***
              </div>
            )}
          </Grid.Column>
          <Grid.Column width={6} textAlign="right">
            <SaleSummary
              itemCount={rows ? rows.length : 0}
              price={price}
              sale_id={_id}
              this_quote_id={this_quote_id * 1 > 0 ? this_quote_id : false}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Grid.Column width={12}>
            <Table compact basic="very">
              <Table.Body>
                <BorderlessTableRow>
                  <BorderlessTableCell
                    width={2}
                    style={{ paddingBottom: 0, fontWeight: 700 }}
                  >
                    Order #:
                  </BorderlessTableCell>
                  <BorderlessTableCell width={6} style={{ paddingBottom: 0 }}>
                    {customer_order_number}
                  </BorderlessTableCell>
                  <BorderlessTableCell
                    style={{ paddingBottom: 0, fontWeight: 700 }}
                  >
                    Category:
                  </BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>
                    {sales_category ? sales_category.name : "Not set"}
                  </BorderlessTableCell>
                </BorderlessTableRow>

                <BorderlessTableRow>
                  <BorderlessTableCell
                    style={{ paddingBottom: 5, fontWeight: 700 }}
                  >
                    Backoffice Order:
                  </BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 5 }}>
                    {toggle_backofficeYN * 1 === 1 ? "Yes" : "No"}
                  </BorderlessTableCell>
                  <BorderlessTableCell
                    style={{ paddingBottom: 5, fontWeight: 700 }}
                  >
                    Send to Queue:
                  </BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 5 }}>
                    {_datetime_queued > "" ? "Yes" : "No"}
                  </BorderlessTableCell>
                </BorderlessTableRow>

                <BorderlessTableRow>
                  <BorderlessTableCell
                    style={{ paddingBottom: 0, fontWeight: 700 }}
                  >
                    Customer:
                  </BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>
                    {activeSale.customer.first_name}{" "}
                    {activeSale.customer.last_name}
                  </BorderlessTableCell>
                  <BorderlessTableCell
                    style={{ paddingBottom: 0, fontWeight: 700 }}
                  >
                    Date of sale:
                  </BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>
                    {transaction_date}
                  </BorderlessTableCell>
                </BorderlessTableRow>
                <BorderlessTableRow>
                  <BorderlessTableCell
                    style={{ paddingBottom: 0, fontWeight: 700 }}
                  >
                    Company:
                  </BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>
                    {activeSale.customer.company_name} (
                    {activeSale.customer.code}){" "}
                    {activeSale.customer._id * 1 !== 8 /*cash customer*/ &&
                      permissions &&
                      permissions.indexOf("ViewOnly") === -1 &&
                      permissions.indexOf("Production") === -1 && (
                        <Button
                          style={{ fontSize: "8pt" }}
                          onClick={this.viewCustomer}
                        >
                          <Icon name="user" />
                          View
                        </Button>
                      )}{" "}
                  </BorderlessTableCell>
                  <BorderlessTableCell
                    style={{ paddingBottom: 0, fontWeight: 700 }}
                  >
                    Date promised:
                  </BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>
                    {ship_date}
                  </BorderlessTableCell>
                </BorderlessTableRow>
                <BorderlessTableRow>
                  <BorderlessTableCell
                    style={{ paddingBottom: 0, fontWeight: 700 }}
                  >
                    Street:
                  </BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>
                    {activeSale.customer.street_address}
                  </BorderlessTableCell>
                  <BorderlessTableCell
                    style={{ paddingBottom: 0, fontWeight: 700 }}
                  >
                    Captured by:
                  </BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>
                    {teller
                      ? teller
                      : stage_log
                      ? stage_log.so_created.stage_user
                      : ""}
                  </BorderlessTableCell>
                </BorderlessTableRow>
                <BorderlessTableRow>
                  <BorderlessTableCell
                    style={{ paddingBottom: 0, fontWeight: 700 }}
                  >
                    Town:
                  </BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>
                    {activeSale.customer.street_city}
                  </BorderlessTableCell>
                  <BorderlessTableCell
                    style={{ paddingBottom: 0, fontWeight: 700 }}
                  >
                    Mobile:
                  </BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>
                    {/*<EditableInput
                      defaultValue={alert_mobile || mobile || ''}
                      onSubmit={v => this.handleUpdateSale(_id, { mobile: v })}
                      placeholder="Mobile"
                    />*/}
                    {alert_mobile || mobile || ""}
                  </BorderlessTableCell>
                </BorderlessTableRow>
                <BorderlessTableRow>
                  <BorderlessTableCell
                    style={{ paddingBottom: 0, fontWeight: 700 }}
                  >
                    Postcode:
                  </BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>
                    {activeSale.customer.street_zipcode}
                  </BorderlessTableCell>
                  <BorderlessTableCell
                    style={{ paddingBottom: 0, fontWeight: 700 }}
                  >
                    Email:
                  </BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>
                    {/*<EditableInput
                      defaultValue={alert_email || email || ''}
                      onSubmit={v => this.handleUpdateSale(_id, { email: v })}
                      placeholder="Email"
                    />*/}
                    {alert_email || email || ""}
                  </BorderlessTableCell>
                </BorderlessTableRow>
                <BorderlessTableRow>
                  <BorderlessTableCell
                    style={{ paddingBottom: 0, fontWeight: 700 }}
                  >
                    &nbsp;
                  </BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>
                    &nbsp;
                  </BorderlessTableCell>
                  <BorderlessTableCell
                    style={{ paddingBottom: 0, fontWeight: 700 }}
                  >
                    Cash Customer Name
                  </BorderlessTableCell>
                  <BorderlessTableCell style={{ paddingBottom: 0 }}>
                    {alert_name || ""}
                  </BorderlessTableCell>
                </BorderlessTableRow>
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column width={4}>
            <span>
              <Segment basic textAlign="right">
                <QRCode sale_id={_id} />
              </Segment>
              {permissions &&
                permissions.indexOf("ViewOnly") === -1 &&
                permissions.indexOf("Production") === -1 &&
                sales_order_cancelledYN * 1 !== 1 && (
                  <Segment basic textAlign="right">
                    {sales_orderISbb_sales_ordersID && <PrintStickerButton />}
                    {this_quote_id * 1 <= 0 && <PrintReceiptButton />}
                    {(this_quote_id * 1 > 0 ||
                      sales_orderISbb_sales_ordersID * 1 > 0) && (
                      <EmailButton
                        email_to_send={alert_email || email || ""}
                        doc_type={
                          this_quote_id * 1 > 0
                            ? "bb_sales_quotes"
                            : sales_orderISbb_sales_ordersID * 1 > 0
                            ? "bb_sales_orders"
                            : "bb_picton_tablet_sales"
                        }
                        doc_id={_id}
                      />
                    )}
                    {sales_orderISbb_sales_ordersID && (
                      <PrintA4Button sale_id={_id} />
                    )}
                  </Segment>
                )}
            </span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column width={16}>
            <ItemsTable
              items={rows}
              isEditable={false}
              sale_id={_id}
              total_owing={total_owingCUR}
              items_totalCUR={items_totalCUR}
              tax_totalCUR={tax_totalCUR}
              grand_totalCUR={grand_totalCUR}
              sales_orderISbb_sales_ordersID={sales_orderISbb_sales_ordersID}
              second_sales_orderISbb_sales_ordersID={
                second_sales_orderISbb_sales_ordersID
              }
              this_quote_id={this_quote_id}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column stretched width={6}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ flex: 1 }}>
                <FilesList files={files} />
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            {permissions &&
              permissions.indexOf("ViewOnly") === -1 &&
              permissions.indexOf("Production") === -1 &&
              permissions.indexOf("Despatch") === -1 && (
                <div>
                  {show_edit_button}
                  {sales_orderISbb_sales_ordersID * 1 > 0 &&
                  permissions.indexOf("SuperAdmin") !== -1 ? (
                    <span>
                      <AllocateStockButton sale_id={_id} />
                    </span>
                  ) : (
                    <span></span>
                  )}
                </div>
              )}
          </Grid.Column>
          <Grid.Column width={6} textAlign="right">
            <PaymentDetails
              amountOutstanding={total_owingCUR.toString()}
              payments={payments}
              this_quote_id={this_quote_id}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            {permissions &&
              permissions.indexOf("ViewOnly") === -1 &&
              permissions.indexOf("Production") === -1 &&
              sales_order_cancelledYN * 1 !== 1 && (
                <div>
                  {sales_orderISbb_sales_ordersID * 1 > 0 ||
                  total_owingCUR * 1 <= 0 ? (
                    <span />
                  ) : this_quote_id * 1 > 0 ? (
                    total_outstandingNUM * 1 > 0 ? (
                      <span>
                        <Button
                          onClick={this.handleConvertQuote}
                          this_quote_id={this_quote_id}
                          customer_id={activeSale.customer._id}
                          color="green"
                        >
                          Process Quote
                        </Button>
                      </span>
                    ) : (
                      "Quote fully processed."
                    )
                  ) : (
                    <span>
                      <SaveQuoteForm
                        onSubmit={this.handleSaveQuote}
                        sale_id={_id}
                        quote_id={saved_to_quoteISbb_sales_quotesID}
                      />
                    </span>
                  )}
                </div>
              )}
          </Grid.Column>
          <Grid.Column width={12}>
            <div style={{ display: "flex" }}>
              <div style={{ textAlign: "right", flex: 1 }}>
                {payment_buttons}
                <PaymentModal
                  amountOutstanding={total_owingCUR.toString()}
                  onSubmit={this.handleAddPayment}
                  onClose={this.closePaymentModal}
                  isOpen={isPaymentModalOpen}
                  paymentType={paymentType}
                  sale_id={_id}
                />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>
            {sales_orderISbb_sales_ordersID * 1 > 0 ? (
              <SalePeopleList stage_log={stage_log} />
            ) : (
              <HpembDisplay hpemb={hpemb} />
            )}
            {show_cancel_button}
          </Grid.Column>
          <Grid.Column width={7}>
            <DeliveryAddress address={delivery_addressISsmallplaintextbox} />
            {sales_orderISbb_sales_ordersID * 1 > 0 ? (
              <HpembDisplay hpemb={hpemb} />
            ) : (
              <span />
            )}
            <SaleNotes
              permissions={permissions}
              notes={external_notesISsmallplaintextbox}
              sales_orderISbb_sales_ordersID={
                sales_orderISbb_sales_ordersID * 1
              }
            />
            {sales_orderISbb_sales_ordersID * 1 > 0 ? (
              <Segment>
                <div
                  style={{ fontSize: "7pt", fontWeight: "bold", color: "gray" }}
                >
                  STAGE NOTES:
                </div>{" "}
                {stage_notes}
              </Segment>
            ) : (
              <div></div>
            )}
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            {sales_orderISbb_sales_ordersID * 1 > 0 &&
            permissions &&
            (permissions.indexOf("Despatch") !== -1 ||
              permissions.indexOf("Supervisor") !== -1 ||
              permissions.indexOf("SuperAdmin") !== -1) ? (
              <span>
                <NextStageButton
                  sale_id={_id}
                  stage_id={stage_id}
                  permissions={permissions}
                />
                <Divider hidden clearing />
                {typeof stage_log["Delivery"] !== "undefined" ||
                typeof stage_log["Collection"] !== "undefined" ||
                typeof stage_log["Part Orders"] !== "undefined" ? (
                  typeof activeSale.customer.status.OK !== "undefined" &&
                  total_outstandingNUM * 1 > 0 ? (
                    <DespatchButton
                      items={rows}
                      sale_id={_id}
                      signatureImageURL={signatureImageURL}
                      stage_name={stage_name}
                    />
                  ) : total_outstandingNUM * 1 > 0 ? (
                    <Segment inverted color="red">
                      Cannot Despatch {total_outstandingNUM} - Customer Account
                      Issue
                    </Segment>
                  ) : (
                    <div>(Nothing to despatch)</div>
                  )
                ) : (
                  <span />
                )}
              </span>
            ) : (permissions.indexOf("Production") !== -1 ||
                permissions.indexOf("Embroidery") !== -1 ||
                permissions.indexOf("Quality Control") !== -1) &&
              sales_orderISbb_sales_ordersID * 1 > 0 ? (
              <NextStageButton
                sale_id={_id}
                stage_id={stage_id}
                permissions={permissions}
              />
            ) : (
              <span></span>
            )}
            {permissions.indexOf("ViewOnly") === -1 &&
              permissions.indexOf("Production") === -1 && (
                <div>{this.showStatus(activeSale)}</div>
              )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

ViewSaleScreen.propTypes = {
  // The following props from redux:
  addPayment: PropTypes.func.isRequired,
  activeSale: PropTypes.object.isRequired,
  isPaymentModalOpen: PropTypes.bool.isRequired,
  isRequestInProgress: PropTypes.bool.isRequired,
  paymentType: PropTypes.bool,
  product_categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  setPaymentModalIsOpen: PropTypes.func.isRequired,
  setPaymentType: PropTypes.func.isRequired,
  updateSale: PropTypes.func.isRequired,
  saveAsQuote: PropTypes.func.isRequired
};

const mapState = state => ({
  activeSale: state.app.activeSale,
  isPaymentModalOpen: state.app.isPaymentModalOpen,
  paymentType: state.app.paymentType,
  isRequestInProgress: state.app.isRequestInProgress,
  permissions: state.auth.user ? state.auth.user.permissions : null,
  tillName: state.settings.tillName
});
const mapDispatch = dispatch => ({
  setScreen: screen => dispatch(setScreenAction(screen)),
  addPayment: (sale_id, type, amount, ref) =>
    dispatch(addPaymentAction(sale_id, type, amount, ref)),
  setPaymentModalIsOpen: value => dispatch(setPaymentModalIsOpenAction(value)),
  setPaymentType: type => dispatch(setPaymentTypeAction(type)),
  updateSale: (
    sale_id,
    category,
    mobile,
    email,
    order_ref,
    delivery_address,
    notes
  ) =>
    dispatch(
      updateSaleAction(
        sale_id,
        category,
        mobile,
        email,
        order_ref,
        delivery_address,
        notes
      )
    ),
  saveAsQuote: sale_id => dispatch(saveAsQuoteAction(sale_id)),
  addSaleSignature: (sale_id, file, print_name, sigtype) =>
    dispatch(addSaleSignatureAction(sale_id, file, print_name, sigtype)),
  setSearchCustomerDetailsText: txt =>
    dispatch(setSearchCustomerDetailsTextAction(txt)),
  newSale: (customer_id, quote_id) =>
    dispatch(newSaleAction(customer_id, quote_id))
});

export default connect(mapState, mapDispatch)(withAuth(ViewSaleScreen));
