import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import { isEmpty } from 'validator'
import { connect } from 'react-redux'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'

import { searchSalesAction } from '../../redux/reducers/app/actions'

class SaleSearchForm extends Component {
  state = {
    sale: '',
    isSaleError: false,
  }

  handleInputChange = (e, { name, value }) => this.setState({ [name]: value })

  handleSubmit = () => {
    const { searchSales } = this.props
    const { sale } = this.state

    // Form validation
    if (isEmpty(sale)) {
      this.setState({ isSaleError: true })
      return
    }

    // search_sales
    searchSales(sale)
  }

  render() {
    const { sale, isSaleError } = this.state

    return (
      <Form onSubmit={this.handleSubmit}>
        <Header as="h2">Find an active sale:</Header>
        <Form.Input
          error={isSaleError}
          name="sale"
          onChange={this.handleInputChange}
          placeholder="Sale"
          required
          value={sale}
        />
        <Form.Button type="submit" primary content="Search Sales" />
      </Form>
    )
  }
}

SaleSearchForm.propTypes = {
  // The following props from redux:
  searchSales: PropTypes.func.isRequired,
}

const mapDispatch = dispatch => ({
  searchSales: q => dispatch(searchSalesAction(q)),
})

export default connect(null, mapDispatch)(SaleSearchForm)