import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Confirm from 'semantic-ui-react/dist/commonjs/addons/Confirm'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Input from 'semantic-ui-react/dist/commonjs/elements/Input'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import Select from 'semantic-ui-react/dist/commonjs/addons/Select'
import { connect } from 'react-redux'

class SublimationNameForm extends Component {
  state = {
    isConfirmDeleteOpen: false,
  }

  handleTextChange = (e, { value }) => {
    const { handleChange, name } = this.props
    handleChange({
      ...name,
      text: value,
    })
  }

  handleSizeChange = (e, { value }) => {
    const { handleChange, name } = this.props
    handleChange({
      ...name,
      size: value,
    })
  }

  handleCloseConfirmDelete = () => this.setState({ isConfirmDeleteOpen: false })

  handleOpenConfirmDelete = () => this.setState({ isConfirmDeleteOpen: true })

  handleDelete = () => {
    const { handleDelete, name } = this.props
    handleDelete(name)
  }

  render() {
    const { name: n, sublimationSizeOptions } = this.props
    const { isConfirmDeleteOpen } = this.state

    return (
      <div>
        <Form.Group key={n.id}>
          <Form.Field
            control={Input}
            fluid
            onChange={this.handleTextChange}
            placeholder="Text"
            value={n.text || ''}
            width={5}
          />
          <Form.Field
            control={Select}
            fluid
            onChange={this.handleSizeChange}
            options={sublimationSizeOptions
              .filter(o => o.value.toString().trim().length > 0)
              .map(o => ({
                key: o.value,
                text: o.value,
                value: o.value.toString(),
              })
            )}
            placeholder="Size"
            value={n.size}
            width={2}
          />
          <Form.Field
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            width={1}
          >
            <Icon
              color="red"
              name="delete"
              onClick={this.handleOpenConfirmDelete}
              size="large"
              style={{ cursor: 'pointer' }}
            />
          </Form.Field>
        </Form.Group>
        <Confirm
          content={(
            <Modal.Content>
              <span>Are you sure you want to delete the sublimation item <strong>{n.text}</strong>?</span>
            </Modal.Content>
          )}
          header="Confirm delete sublimation item"
          onCancel={this.handleCloseConfirmDelete}
          onConfirm={this.handleDelete}
          open={isConfirmDeleteOpen}
        />
      </div>
    )
  }
}

SublimationNameForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  name: PropTypes.object.isRequired,

  // The following props from redux:
  sublimationSizeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const mapState = state => ({
  sublimationSizeOptions: state.app.hpEmb.sublimationSizeOptions,
})

export default connect(mapState)(SublimationNameForm)
