import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";

import SalesCards from "./SalesCards";
import withAuth from "../../lib/withAuth";
import { productionAction } from "../../redux/reducers/app/actions";

const filterOptions = [
  {
    text: "Open sales",
    value: "unpaid"
  },
  {
    text: "In production",
    value: "completed"
  },
  {
    text: "Recent sales",
    value: "recent"
  },
  {
    text: "In quotes",
    value: "quotes"
  }
];

class ProductionScreen extends Component {
  state = {
    paidFilter: null,
    sales: null,
    stageFilter: null
  };

  handlePaidFilterChange = () => {
    this.setState({
      paidFilter: "completed",
      stageFilter: null
    });
    this.loadSales("completed");
  };
  handleRecentFilterChange = () => {
    this.setState({
      paidFilter: "recent",
      stageFilter: null
    });
    this.loadSales("recent");
  };
  handleQuotesFilterChange = () => {
    this.setState({
      paidFilter: "quotes",
      stageFilter: null
    });
    this.loadSales("quotes");
  };
  handleUnPaidFilterChange = () => {
    this.setState({
      paidFilter: "unpaid",
      stageFilter: null
    });
    this.loadSales("unpaid");
  };

  handleStageFilterChange = (e, { value }) => {
    this.setState({
      paidFilter: null,
      stageFilter: value
    });
    this.loadSales(value);
  };

  loadSales = (filter = null) => {
    const { production } = this.props;
    production(filter);
  };

  render() {
    const { isRequestInProgress, productionSales, stages } = this.props;
    const { paidFilter, stageFilter } = this.state;

    const stageOptions = stages.map(s => ({
      value: s._id,
      text: s.name
    }));

    const paidFilterOption = filterOptions.find(f => f.value === paidFilter);
    const paidFilterText = paidFilterOption ? paidFilterOption.text : "";

    const stageFilterOption = stageOptions.find(f => f.value === stageFilter);
    const stageFilterText = stageFilterOption ? stageFilterOption.text : "";

    return (
      <div>
        {isRequestInProgress && <Loader active content="Loading sales..." />}
        <Form>
          <Form.Group widths="equal">
            <button
              className="ui button purple"
              onClick={this.handleRecentFilterChange}
            >
              Recent Sales
            </button>
            <button
              className="ui button red"
              onClick={this.handleUnPaidFilterChange}
            >
              Open Sales
            </button>
            <button
              className="ui button gray"
              onClick={this.handleQuotesFilterChange}
            >
              Quotes
            </button>
            <button
              className="ui button green"
              onClick={this.handlePaidFilterChange}
            >
              In Production
            </button>
            <Form.Dropdown
              disabled={isRequestInProgress}
              onChange={this.handleStageFilterChange}
              options={stageOptions}
              placeholder="Select Stage"
              selection
              value={stageFilter}
            />
          </Form.Group>
        </Form>
        {!isRequestInProgress && (paidFilterText || stageFilterText) && (
          <div>
            <Header as="h2">
              {paidFilterText}
              {stageFilterText}
              {": "}
              {productionSales.length} sale
              {productionSales.length === 1 ? "" : "s"}
            </Header>
            <SalesCards paidFilter={paidFilter} sales={productionSales} />
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.handleRecentFilterChange();
  }
}

ProductionScreen.propTypes = {
  // The following props from redux:
  isRequestInProgress: PropTypes.bool.isRequired,
  production: PropTypes.func.isRequired,
  productionSales: PropTypes.arrayOf(PropTypes.object).isRequired,
  stages: PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapState = state => ({
  isRequestInProgress: state.app.isRequestInProgress,
  productionSales: state.app.productionSales,
  stages: state.app.stages
});
const mapDispatch = dispatch => ({
  production: filter => dispatch(productionAction(filter))
});

export default connect(mapState, mapDispatch)(withAuth(ProductionScreen));
