import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'
import { isNumeric } from 'validator'
import { connect } from 'react-redux'

import DeleteItemButton from './DeleteItemButton'
import QuantityInput from './QuantityInput'
import EditableInput from '../../EditableInput'

class ItemTableRow extends Component {

	constructor(props) {
		super(props);
		this.state = {isOpen: false};
	}

  handleDeleteSaleItem = () => {
    const { onDeleteSaleItem, sale_id, item } = this.props
    onDeleteSaleItem(sale_id, item._id)
  }

  handleEditSaleItem = (data) => {
    const { onEditSaleItem, sale_id, item } = this.props
    onEditSaleItem(sale_id, item._id, data)
  }

  render() {
    const { handleOpenDiscModal,isEditable, item, sale_id, product_options, products, permissions, sales_orderISbb_sales_ordersID, second_sales_orderISbb_sales_ordersID } = this.props

    const {
      // notes,
      grand_totalCUR,
      qtyNUM,
      qty_allocatedNUM,
      qty_availableNUM,
      //qty_despatchedNUM,
      qty_processedNUM,
      //qty_incrementedNUM,
      //qty_decrementedNUM,
      qty_outstandingNUM,
      qty_onpoNUM,
      discountNUM,
      priceCUR,
      taxTAX,
      //description,
      //name,
      sku_code,
      white_line,
      hpemb_item
    } = item

    // look up parent product
    const product = products.find(p => {
      return p._id === item.itemISbb_item_dataID
    })
    let productDescription = product ? product.name : 'N/A'

    // look up item
    const option = product_options.find(o => {
      return (o.item_id === item.itemISbb_item_dataID
        && o.option_1_id === item.option1ISbb_item_data_options1ID
        && o.option_2_id === item.option2ISbb_item_data_options2ID)
    })
    const sku = option ? option.code : sku_code
    if (option) productDescription += ` ${option.name}`
    /*if(typeof product.discountNUM !== 'undefined' && product.discountNUM*1>0){
    	    productDescription += ` (${product.discountNUM}% discount)`
    }*/

    // console.log(item, option)



    const price = parseFloat(priceCUR)
    const quantity = parseInt(qtyNUM)
    const vat = parseFloat(taxTAX)

    let rowStyle = {background:'white'}
    if(qty_outstandingNUM*1===0){
    	    rowStyle.background='#ddffdd'
    }

    let tickIcon = <i></i>
    let stockAvailable=0
    //if(sale_id.substring(0,2)==='77' && qty_outstandingNUM*1>0 && (qty_availableNUM*1)>=(qty_outstandingNUM*1) && (!description || description.length===0)){//green line
    //alert((sales_orderISbb_sales_ordersID*1)+'@'+(second_sales_orderISbb_sales_ordersID*1))
    if((sales_orderISbb_sales_ordersID*1)===0 && (second_sales_orderISbb_sales_ordersID*1)===0){
	    if((white_line*1)===0){
		    stockAvailable=1
		     tickIcon = <Popup
			  content='Item is in stock...'
			  trigger={(
			    <span>
			    <span>&nbsp;</span>
			    <Icon
			      fitted
			      name="check square"
			      size="large"
			      color='green'
			      style={{ cursor: 'pointer' }}
			    />
			    </span>
			  )}
			/>
		     rowStyle.background='#ddffdd'
	    }
    }

    let embIcon = <i></i>
    if(hpemb_item*1===1){
    	    embIcon = <i>
    	   	 <Popup
                  content='HP/EMB Item...'
                  trigger={(
                    <span>
                    <span>&nbsp;</span>
                    <Icon
                      fitted
                      name="write square"
                      size="large"
                      color='purple'
                    />
                    </span>
                  )}
                />
    	    </i>
    }
    if(qtyNUM*1<0){
           rowStyle.background='#ffdddd'
    }
    let editable_items_list = [487,488,498,499,587,3074,43,44,45]

    const doHandleOpenDiscModal=()=>{
    	    const {item}=this.props
    	    handleOpenDiscModal(item._id,item.discountNUM);
    }

    return (
      <Table.Row style={rowStyle}>
        {isEditable && (
          <Table.Cell>
            <DeleteItemButton
              product={productDescription}
              onDelete={this.handleDeleteSaleItem}
            />
          </Table.Cell>
        )}
        <Table.Cell>{productDescription}</Table.Cell>
        <Table.Cell>{sku}{embIcon}</Table.Cell>
        <Table.Cell>
          {isEditable ? (
            <EditableInput
              defaultValue={item.description || ''}
              onSubmit={v => this.handleEditSaleItem({ notes: v })}
              placeholder=""
              width={50}
            />
          ) : (
            <div>
              {item.description && (
                <Popup
                  content={item.description}
                  trigger={(
                    <Icon
                      fitted
                      name="file alternate outline"
                      size="large"
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                />
              )}
              {stockAvailable===1 && (
                <Popup
                  content='Item will be despatched immediately upon payment...'
                  trigger={(
                    <span className="ui green horizontal label">Now</span>
                  )}
                />
              )}
            </div>
          )}
        </Table.Cell>
        <Table.Cell textAlign="right">
          {isEditable ? (
            <QuantityInput
              onChange={v => this.handleEditSaleItem({ quantity: v })}
              product={productDescription}
              onZero={this.handleDeleteSaleItem}
              value={quantity}
            />
          ) : (
              <span>
		      <span style={{ fontSize: (qty_outstandingNUM*1===0?'':'1.3rem'), fontWeight: (qty_outstandingNUM*1===0?'normal':'bold') }}>{quantity}</span>
		      /
		      {(sale_id.substring(0,2)==='77' || sale_id.substring(0,2)==='78')?'-':(qty_allocatedNUM*1)}
		      /
		      <span style={{ fontSize: (qty_outstandingNUM*1===0?'1.3rem':''), fontWeight: (qty_outstandingNUM*1===0?'bold':'normal') }}>{sale_id.substring(0,2)==='77'?'-':(qty_processedNUM*1)}</span>
              </span>
          )}
        </Table.Cell>
        {!isEditable && (
          <div>
            <Table.Cell
              style={{ fontSize: '1.3rem', fontWeight: (qty_outstandingNUM*1===0?'normal':'bold'), color: (qty_outstandingNUM*1===0?'silver':'black') }}
              textAlign="right"
            >
              {qty_outstandingNUM*1}
            </Table.Cell>
            <Table.Cell textAlign="right">
              {/*sale_id.substring(0,2)==='77'?'-':(qty_availableNUM*1)}/{sale_id.substring(0,2)==='77'?'-':(qty_onpoNUM*1)*/}
              {(qty_availableNUM*1)}/{(qty_onpoNUM*1)}
              {tickIcon}
            </Table.Cell>
          </div>
        )}
        <Table.Cell textAlign="right">
          {(isEditable && (permissions.indexOf('Supervisor')!==-1 || editable_items_list.includes(item.itemISbb_item_dataID*1))) ? (
            <EditableInput
              defaultValue={price}
              formatValue={v => {
                if (!isNumeric(v.toString())) return v
                return parseFloat(v).toFixed(2).toString()
              }}
              onSubmit={v => this.handleEditSaleItem({ price: v })}
              textAlign="right"
              validationFunc={v => isNumeric(v.toString()) && v >= 0}
              width={40}
            />
          ) : (
            <span>{price.toFixed(2)}</span>
          )}
        </Table.Cell>
        <Table.Cell textAlign="right" onClick={ (permissions.indexOf('Supervisor')!==-1 && isEditable) && doHandleOpenDiscModal} style={{cursor:((permissions.indexOf('Supervisor')!==-1 && isEditable) && 'pointer'),color:(discountNUM*1===0?'silver':'')}}>{(discountNUM*1).toFixed(0)+'%'}</Table.Cell>
        <Table.Cell textAlign="right">{vat.toFixed(2)}</Table.Cell>
        <Table.Cell textAlign="right">{(/*quantity * price + vat*/(grand_totalCUR*1)).toFixed(2)}</Table.Cell>
      </Table.Row>
    )
  }
}

ItemTableRow.propTypes = {
  isEditable: PropTypes.bool,
  item: PropTypes.object.isRequired,
  onDeleteSaleItem: PropTypes.func,
  onEditSaleItem: PropTypes.func,
  sale_id: PropTypes.string.isRequired,

  // The following props from redux:
  product_options: PropTypes.arrayOf(PropTypes.object).isRequired,
}
ItemTableRow.defaultProps = {
  isEditable: false,
  onDeleteSaleItem: () => {},
  onEditSaleItem: () => {},
}

const mapState = state => ({
  products: state.app.products,
  product_options: state.app.product_options,
  permissions: (state.auth.user?state.auth.user.permissions:null),
})

export default connect(mapState)(ItemTableRow)
