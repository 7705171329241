import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import styled from 'styled-components'

import AddItemButton from './AddItemButton'
import EmbroideryLogosForm from './EmbroideryLogosForm'
import EmbroideryNamesForm from './EmbroideryNamesForm'
import HeatPressLogosForm from './HeatPressLogosForm'
import HeatPressNamesForm from './HeatPressNamesForm'
import SublimationsForm from './SublimationsForm'

const ButtonContainer = styled.div`
display: flex;
justify-content: flex-end;
flex: 1;
`

const HeaderContainer = styled.div`
display: flex;
margin-bottom: 1rem;
`

const StyledHeader = styled(Header)`
align-items: center;
display: flex;
margin-bottom: 0;
`

class HPEmbForm extends Component {
  render() {
    const {
      embroideryLogos, heatPressLogos, embroideryNames, heatPressNames, sublimations,
      handleAddItem, handleChangeItem, handleDeleteItem,
    } = this.props

    return (
      <Form size="small">
        <HeaderContainer>
          <StyledHeader as="h4">Embroidery logos</StyledHeader>
          <ButtonContainer>
            <AddItemButton
              buttonText="Add embroidery logo"
              handleClick={() => handleAddItem('embroideryLogos', { text: '', location: null })}
            />
          </ButtonContainer>
        </HeaderContainer>
        <EmbroideryLogosForm
          items={embroideryLogos}
          handleChange={(item) => handleChangeItem('embroideryLogos', item)}
          handleDelete={(item) => handleDeleteItem('embroideryLogos', item)}
        />

        <HeaderContainer>
          <StyledHeader as="h4">Heat press logos</StyledHeader>
          <ButtonContainer>
            <AddItemButton
              buttonText="Add heat press logo"
              handleClick={() => handleAddItem('heatPressLogos', { text: '', location: null })}
            />
          </ButtonContainer>
        </HeaderContainer>
        <HeatPressLogosForm
          items={heatPressLogos}
          handleChange={(item) => handleChangeItem('heatPressLogos', item)}
          handleDelete={(item) => handleDeleteItem('heatPressLogos', item)}
        />

        <HeaderContainer>
          <StyledHeader as="h4">Embroidery initials/name</StyledHeader>
          <ButtonContainer>
            <AddItemButton
              buttonText="Add embroidery initials/name"
              handleClick={() => handleAddItem('embroideryNames', { text: '', location: null, size: null, colour: null })}
            />
          </ButtonContainer>
        </HeaderContainer>
        <EmbroideryNamesForm
          items={embroideryNames}
          handleChange={(item) => handleChangeItem('embroideryNames', item)}
          handleDelete={(item) => handleDeleteItem('embroideryNames', item)}
        />

        <HeaderContainer>
          <StyledHeader as="h4">Heat press initials/name</StyledHeader>
          <ButtonContainer>
            <AddItemButton
              buttonText="Add heat press initials/name"
              handleClick={() => handleAddItem('heatPressNames', { text: '', location: null, size: null, colour: null })}
            />
          </ButtonContainer>
        </HeaderContainer>
        <HeatPressNamesForm
          items={heatPressNames}
          handleChange={(item) => handleChangeItem('heatPressNames', item)}
          handleDelete={(item) => handleDeleteItem('heatPressNames', item)}
        />

        <HeaderContainer>
          <StyledHeader as="h4">Sublimation name/number</StyledHeader>
          <ButtonContainer>
            <AddItemButton
              buttonText="Add sublimation"
              handleClick={() => handleAddItem('sublimations', { text: '', size: null })}
            />
          </ButtonContainer>
        </HeaderContainer>
        <SublimationsForm
          items={sublimations}
          handleChange={(item) => handleChangeItem('sublimations', item)}
          handleDelete={(item) => handleDeleteItem('sublimations', item)}
        />
      </Form>
    )
  }
}

HPEmbForm.propTypes = {
  handleAddItem: PropTypes.func.isRequired,
  handleChangeItem: PropTypes.func.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  embroideryLogos: PropTypes.arrayOf(PropTypes.object).isRequired,
  heatPressLogos: PropTypes.arrayOf(PropTypes.object).isRequired,
  embroideryNames: PropTypes.arrayOf(PropTypes.object).isRequired,
  heatPressNames: PropTypes.arrayOf(PropTypes.object).isRequired,
  sublimations: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default HPEmbForm
