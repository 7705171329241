import React from 'react'
import PropTypes from 'prop-types'
import List from 'semantic-ui-react/dist/commonjs/elements/List'

const PaymentDetails = ({ amountOutstanding, payments, this_quote_id }) => (
  <List style={{ marginBottom: 0 }}>
    {payments.map((p, i) => (
      <List.Item key={`${p.ref}-${p.amount}-${i}`}>
        Payment: {p.type}
        {(p.type !== 'CASH' && p.ref) && ` #${p.ref.split('#')[1]}`}
        {(p.type === 'CASH' && p.ref) && ` (Change:${p.ref.split('#')[1]})`}
        {' '}
        £{parseFloat(p.amount).toFixed(2)}
      </List.Item>
    ))}
    {this_quote_id*1>0?
      <div>
      </div>
      :
      <List.Item style={{ fontWeight: 'bold' }}>
        Amount outstanding: £{parseFloat(amountOutstanding).toFixed(2)}
      </List.Item>
    }
  </List>
)

PaymentDetails.propTypes = {
  amountOutstanding: PropTypes.string,
  payments: PropTypes.arrayOf(PropTypes.object),
}
PaymentDetails.defaultProps = {
  amountOutstanding: '0',
  payments: [],
}

export default PaymentDetails
