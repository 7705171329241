import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import { connect } from 'react-redux'

import { setScreenAction } from '../../redux/reducers/app/actions'

class MenuItem extends Component {
  handleClick = () => {
    const { setScreen, targetScreen } = this.props
    setScreen(targetScreen)
  }

  render() {
    const { title } = this.props

    return (
      <Menu.Item
        onClick={this.handleClick}
        style={{ cursor: 'pointer' }}
      >
        {title}
      </Menu.Item>
    )
  }
}

MenuItem.propTypes = {
  screen: PropTypes.string.isRequired,
  setScreen: PropTypes.func.isRequired,
  targetScreen: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

const mapState = state => ({
  screen: state.app.screen,
})
const mapDispatch = dispatch => ({
  setScreen: screen => dispatch(setScreenAction(screen)),
})

export default connect(mapState, mapDispatch)(MenuItem)
