import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import { connect } from 'react-redux'

import { printStickerAction } from '../../redux/reducers/app/actions'

class PrintStickerButton extends Component {
  handleClick = () => {
    const { printSticker, sale_id } = this.props
    printSticker(sale_id)
  }

  render() {
    return (
      <Button
        style={{fontSize:'9pt'}}
        content="Sticker"
        onClick={this.handleClick}
      />
    )
  }
}

PrintStickerButton.propTypes = {
  // The following props from redux:
  printSticker: PropTypes.func.isRequired,
  sale_id: PropTypes.string.isRequired,
}

const mapState = state => ({
  sale_id: state.app.activeSale._id,
})
const mapDispatch = dispatch => ({
  printSticker: (sale_id) => dispatch(printStickerAction(sale_id)),
})

export default connect(mapState, mapDispatch)(PrintStickerButton)
