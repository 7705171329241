import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'

import DespatchModal from './DespatchModal'


class DespatchButton extends Component {
  state = {
    isModalOpen: false,
  }

  closeModal = () => this.setState({ isModalOpen: false })

  openModal = () => this.setState({ isModalOpen: true })

  render() {
    const { items, sale_id, signatureImageURL, stage_name } = this.props
    const { isModalOpen } = this.state

    return (
      <div>
        <Button
          content="Despatch"
          disabled={!items || items.length === 0}
          icon="shipping fast"
          onClick={this.openModal}
          primary
          size="large"
        />
        <DespatchModal
          handleClose={this.closeModal}
          items={items}
          isOpen={isModalOpen}
          sale_id={sale_id}
          signatureImageURL={signatureImageURL}
          stage_name={stage_name}
        />
      </div>
    )
  }
}

DespatchButton.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  sale_id: PropTypes.string.isRequired,
  signatureImageURL: PropTypes.string,
}
DespatchButton.defaultProps = {
  items: null,
  signatureImageURL: null,
}

export default DespatchButton
