import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'

class ItemRow extends Component {
  state = {
    isDropdownOpen: false
  }

  handleItemClick = () => {
    const { onClick, item, option } = this.props
    onClick(item, option)
  }

  handleRowClick = () => {
    const { isDropdownOpen } = this.state
    if(isDropdownOpen){
	this.closeDropdown();
    }else{
	this.openDropdown();
    };
  }

  closeDropdown = () => this.setState({ isDropdownOpen: false })

  openDropdown = () => this.setState({ isDropdownOpen: true })

  render() {
    const { item, onClick, option } = this.props
    const { isDropdownOpen } = this.state

    // if it's an option, we use that to populate the row
    const rowData = option || item
    const { code, name, priceNUM, options } = rowData

    const sortedOptions = options
      ? options
          .sort((a, b) => a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? 1 : -1)
      : null

    // if (isNaN(parseFloat(priceNUM))) console.log(item)

    let background = null
    if (!!option) background = 'rgba(0, 0, 0, 0.01)'

    return (
      <>
        <Table.Row
          onClick={options && options.length > 0 ? this.handleRowClick : this.handleItemClick}

          id={'ticked_'+item._id+'_'+(option?option.option_1_id:0)+'_'+(option?option.option_2_id:0)}

          style={{
            background,
            cursor: 'pointer'
          }}
        >
          <Table.Cell>{!option && code}</Table.Cell>
          <Table.Cell>{option && `${item.name} - `}{name}</Table.Cell>
          <Table.Cell>&pound;{parseFloat(priceNUM).toFixed(2)}</Table.Cell>
          <Table.Cell>
            {options && options.length > 0 && (
              <Label
                basic
                onClick={isDropdownOpen ? this.closeDropdown : this.openDropdown}
                style={{ cursor: 'pointer' }}
              >
                <Icon name={isDropdownOpen ? "chevron up" : "chevron down"} />
                {options && ` ${options.length}`}
              </Label>
            )}
          </Table.Cell>
        </Table.Row>
        {isDropdownOpen && sortedOptions.map(o => (
          <ItemRow
            key={o._id}
            item={item}
            onClick={onClick}
            option={o}
          />
        ))}
      </>
    )
  }
}

ItemRow.propTypes = {
  item: PropTypes.object.isRequired,
  option: PropTypes.object,
  onClick: PropTypes.func.isRequired,
}
ItemRow.defaultProps = {
  option: null,
}

export default ItemRow
