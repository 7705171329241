import { actionTypes, screens } from '../../constants'

const initialState = {
  activeSale: null,
  flashMessage: null,
  hpEmb: {},
  isAuthoriseModalOpen: false,
  isPaymentModalOpen: false,
  isProductsLoaded: false,
  isRequestInProgress: false,
  isSearchResultsModalOpen: false,
  paymentType: null,
  discModal: false,
  products: [],
  product_categories: [],
  product_options: [],
  productionSales: [],
  sale_categories: [],
  screen: screens.APP_LOGIN,
  searchResults: [],
  stages: [],
  searchCustomerDetailsText: '',
  emailModalSent: true,
  emailModalSending: false,
  emailModalEmail: '',
  emailModalDocType: '',
  emailModalDocId: '',
  addNoteModalSent: true,
  addNoteModalSending: false,
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_FLASH_MESSAGE:
      return {
        ...state,
        flashMessage: null,
      }
    case actionTypes.SET_ACTIVE_SALE:
      return {
        ...state,
        activeSale: action.payload,
      }
    case actionTypes.SET_SEARCH_CUSTOMER_DETAILS_TEXT:
        return {
          ...state,
          searchCustomerDetailsText: action.payload,
        }
    case actionTypes.SET_SENDING_EMAIL:
        return {
          ...state,
          sendingEmail: action.payload,
        }
    case actionTypes.SET_EMAIL_SENT:
        return {
          ...state,
          emailSent: action.payload,
        }
    case actionTypes.SET_SENDING_MODAL_NOTE:
        return {
          ...state,
          addNoteModalSending: action.payload,
        }
    case actionTypes.SET_MODAL_NOTE_SENT:
        return {
          ...state,
          addNoteModalSent: action.payload,
        }
    case actionTypes.SET_SENDING_MODAL_EMAIL:
        return {
          ...state,
          emailModalSending: action.payload,
        }
    case actionTypes.SET_MODAL_EMAIL_SENT:
        return {
          ...state,
          emailModalSent: action.payload,
        }
    case actionTypes.SET_MODAL_EMAIL:
        return {
          ...state,
          emailModalEmail: action.payload,
        }
    case actionTypes.SET_MODAL_DOC_TYPE:
        return {
          ...state,
          emailModalDocType: action.payload,
        }
    case actionTypes.SET_MODAL_DOC_ID:
        return {
          ...state,
          emailModalDocId: action.payload,
        }
    case actionTypes.SET_AUTHORISE_MODAL_IS_OPEN:
      return {
        ...state,
        isAuthoriseModalOpen: action.payload,
      }
    case actionTypes.SET_APP_REQUEST_IN_PROGRESS:
      return {
        ...state,
        isRequestInProgress: action.payload,
      }
    case actionTypes.SET_FLASH_MESSAGE:
      return {
        ...state,
        flashMessage: action.payload,
      }
    case actionTypes.SET_PAYMENT_MODAL_IS_OPEN:
      return {
        ...state,
        isPaymentModalOpen: action.payload,
      }
    case actionTypes.SET_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: action.payload,
      }
    case actionTypes.SET_PRODUCT_OPTIONS:
      return {
        ...state,
        product_options: action.payload,
      }
    case actionTypes.SET_PRODUCTION_SALES:
      return {
        ...state,
        productionSales: action.payload,
      }
    case actionTypes.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      }
    case actionTypes.SET_PRODUCT_CATEGORIES:
      return {
        ...state,
        product_categories: action.payload,
      }
    case actionTypes.SET_PRODUCTS_LOADED:
      return {
        ...state,
        isProductsLoaded: action.payload,
      }
    case actionTypes.SET_SALE_CATEGORIES:
      return {
        ...state,
        sale_categories: action.payload,
      }
    case actionTypes.SET_HPEMB:
      return {
        ...state,
        hpEmb: action.payload,
      }
    case actionTypes.SET_SCREEN:
      return {
        ...state,
        screen: action.payload,
      }
    case actionTypes.SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      }
    case actionTypes.SET_SEARCH_RESULTS_MODAL_IS_OPEN:
      return {
        ...state,
        isSearchResultsModalOpen: action.payload,
      }
    case actionTypes.SET_STAGES:
      return {
        ...state,
        stages: action.payload,
      }
    case actionTypes.SET_DISCMODAL:
      return {
        ...state,
        discModal: action.payload,
      }
    default:
      return state
  }
}

export default appReducer
