import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Confirm from 'semantic-ui-react/dist/commonjs/addons/Confirm'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'

class DeleteItemButton extends Component {
  state = {
    isConfirmOpen: false,
  }

  closeConfirm = () => this.setState({ isConfirmOpen: false })

  openConfirm = () => this.setState({ isConfirmOpen: true })

  handleConfirm = () => {
    const { onDelete } = this.props
    onDelete()
    //this.closeConfirm()
  }

  render() {
    const { product } = this.props
    const { isConfirmOpen } = this.state

    return (
      <div>
        <Icon
          name="delete"
          onClick={this.handleConfirm}
          style={{
            color: 'red',
            cursor: 'pointer',
          }}
        />
        <Confirm
          content={(
            <Modal.Content>
              Are you sure you want to delete <strong>{product || 'this item'}</strong>?
            </Modal.Content>
          )}
          header="Confirm delete item"
          open={isConfirmOpen}
          onCancel={this.closeConfirm}
          onConfirm={this.handleConfirm}
        />
      </div>
    )
  }
}

DeleteItemButton.propTypes = {
  product: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
}
DeleteItemButton.defaultProps = {
  product: null,
}

export default DeleteItemButton
