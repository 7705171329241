import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import { connect } from 'react-redux'

import { logoutAction } from '../../redux/reducers/auth/actions'

class LogoutMenuItem extends Component {
  handleLogout = () => {
    const { logout } = this.props
    logout()
  }

  render() {
    const { isRequestInProgress, name, permissions } = this.props

    return (
      <Menu.Item
        onClick={this.handleLogout}
        style={{ cursor: 'pointer' }}
      >
        Logout{name && ` ${name}`}
        {permissions.indexOf('SuperAdmin')!==-1 && <Icon name='user plus' title='SuperAdmin' style={{marginBottom:'5px',marginLeft:'10px',fontSize:'12pt',color:'red'}} /> }
        {permissions.indexOf('Supervisor')!==-1 && <Icon name='user plus' title='Supervisor' style={{marginBottom:'5px',marginLeft:'10px',fontSize:'12pt',color:'yellow'}} /> }
        {permissions.indexOf('Tellers')!==-1 && <Icon name='user' title='Tellers' style={{marginBottom:'5px',marginLeft:'10px',fontSize:'12pt',color:'white'}} /> }
        {permissions.indexOf('ViewOnly')!==-1 && <Icon name='user' title='ViewOnly' style={{marginBottom:'5px',marginLeft:'10px',fontSize:'12pt',color:'cyan'}} /> }
        {permissions.indexOf('Production')!==-1 && <Icon name='user' title='Production' style={{marginBottom:'5px',marginLeft:'10px',fontSize:'12pt',color:'purple'}} /> }
        {permissions.indexOf('TeamWear')!==-1 && <Icon name='user' title='TeamWear' style={{marginBottom:'5px',marginLeft:'10px',fontSize:'12pt',color:'purple'}} /> }
        {permissions.indexOf('Embroidery')!==-1 && <Icon name='user' title='Embroidery' style={{marginBottom:'5px',marginLeft:'10px',fontSize:'12pt',color:'purple'}} /> }
        {permissions.indexOf('Quality Control')!==-1 && <Icon name='user' title='Quality Control' style={{marginBottom:'5px',marginLeft:'10px',fontSize:'12pt',color:'purple'}} /> }
        {permissions.indexOf('Despatch')!==-1 && <Icon name='user' title='Despatch' style={{marginBottom:'5px',marginLeft:'10px',fontSize:'12pt',color:'olive'}} /> }
        {isRequestInProgress && <Loader inline inverted active size="tiny" />}
      </Menu.Item>
    )
  }
}

LogoutMenuItem.propTypes = {
  // All props from redux
  isRequestInProgress: PropTypes.bool.isRequired,
  name: PropTypes.string,
  logout: PropTypes.func.isRequired,
}
LogoutMenuItem.defaultProps = {
  name: null
}

const mapState = state => ({
  isRequestInProgress: state.auth.isLoginInProgress,
  name: state.auth.user.first_name,
  permissions: state.auth.user.permissions,
})
const mapDispatch = dispatch => ({
  logout: () => dispatch(logoutAction()),
})

export default connect(mapState, mapDispatch)(LogoutMenuItem)
