import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import { connect } from 'react-redux'
import Webcam from 'react-webcam'

import { addSaleImageAction } from '../../redux/reducers/app/actions'
import dataURLToBlob from '../../lib/dataURLToBlob'
import readFile from '../../lib/readFile'

class AddPhotoButton extends Component {
  constructor(props) {
    super(props)
    this.webcamRef = React.createRef()
  }

  state = {
    isWebcamOpen: false,
  }

  closeModal = () => this.setState({ isWebcamOpen: false })

  openModal = () => this.setState({ isWebcamOpen: true })

  capture = () => {
    const { addSaleImage, sale_id } = this.props
    const dataURL = this.webcamRef.current.getScreenshot()
    const file = dataURLToBlob(dataURL)

    this.closeModal()
    addSaleImage(
      sale_id,
      file,
      'image.jpg'
    )
  }

  takePhoto = () => {
    const { sale_id, addSaleImage } = this.props

    if (window.cordova) {
      navigator.camera.getPicture(
        (filePath) => {
          window.resolveLocalFileSystemURL( // handle content:// paths with https://github.com/hiddentao/cordova-plugin-filepath
            filePath,
            (fileEntry) => {
              fileEntry.file(f => readFile(f, (dataURL) => {
                const blob = dataURLToBlob(dataURL)
                addSaleImage(
                  sale_id,
                  blob,
                  filePath.substring(filePath.lastIndexOf('/') + 1),
                )
              }))
            },
            (e) => alert(e),
          )
        },
        message => alert('takePhoto failed: ' + message),
      )
    } else {
      this.openModal()
    }
  }

  render() {
    const { isWebcamOpen } = this.state

    return (
      <div>
        <Button
          compact
          content="+ Photo"
          onClick={this.takePhoto}
        />
        <Modal
          onClose={this.closeModal}
          open={isWebcamOpen}
        >
          <Modal.Content style={{ textAlign: 'center' }}>
            <Webcam
              audio={false}
              ref={this.webcamRef}
              screenshotFormat="image/jpeg"
              style={{
                height: '100%,',
                width: '100%',
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.closeModal}>Cancel</Button>
            <Button primary onClick={this.capture}>Take photo</Button>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

AddPhotoButton.propTypes = {
  // The following props from redux:
  addSaleImage: PropTypes.func.isRequired,
  sale_id: PropTypes.string.isRequired,
}

const mapState = state => ({
  sale_id: state.app.activeSale._id,
})
const mapDispatch = dispatch => ({
  addSaleImage: (sale_id, file, filename) => dispatch(addSaleImageAction(sale_id, file, filename)),
})

export default connect(mapState, mapDispatch)(AddPhotoButton)
