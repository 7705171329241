import React, { Component } from 'react'
import { connect } from 'react-redux'
//import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
//import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'
//import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import { Icon } from 'semantic-ui-react'

import {
loadSaleAction,
loadQuoteAction,
newSaleAction,
setModalEmailSentAction,
setModalEmailEmailAction,
setModalEmailDocTypeAction,
setModalEmailDocIdAction
} from '../../redux/reducers/app/actions'

class CustomerDetailsDoc extends Component {

  state = {
    busy: false
  }

  handleOpen = () => {
    const {
    doc,
    customer,
    classnm,
    loadSale,
    loadQuote,
    /*newSale, */
    setModalEmailSent,
    setModalEmailEmail,
    setModalEmailDocType,
    setModalEmailDocId
    } = this.props

    this.setState({busy: true});

    if(classnm==='bb_sales_quotes'){
      loadQuote(doc._id)
      //newSale(doc.customerISbb_usersID_IN_Debtors, doc._id)
    }else if(classnm==='bb_sales_orders'){
      loadSale(doc._id)
    }else if(classnm==='bb_sales_debtors_invoices'){
      setModalEmailSent(false);//triggers the modal
      setModalEmailEmail(customer.email);//populates the modal fields
      setModalEmailDocType(classnm);//populates the modal fields
      setModalEmailDocId(doc._id);//populates the modal fields
      this.setState({busy: false});
    }
    //let url="https://picton.blueboxonline.com/?class="+classnm+"&method=view&global[fields][_id]="+doc._id;
    //window.open(url,'docview');
  }

  render(){
    const{doc}=this.props

    let perc=(((doc.amount_processedCUR*1)/(doc.grand_totalCUR*1))*100).toFixed(0);
    if(perc==='NaN'){
      perc=0;
    }

    return(
      <Dimmer.Dimmable as={Segment} dimmed={this.state.busy} style={{cursor:'pointer'}} onClick={this.handleOpen}>

        <Dimmer active={this.state.busy} inverted>
          <Loader>Loading</Loader>
        </Dimmer>

        <div style={{float:'left'}}><Icon id="icon3" name='file alternate outline'/>{this.props.short}{doc._id+' '}</div>
        <div style={{float:'right'}}>{doc.transaction_date+' '}</div>
        <div style={{clear:'both'}}/>
        <div style={{float:'left'}}>{(doc.grand_totalCUR*1).toFixed(2)+' '} <span style={{color:'silver'}}>{' ('+(doc.amount_processedCUR*1).toFixed(2)+') '}</span> </div>
        <div style={{float:'right',color:((perc*1)===100?'green':'red')}}>{perc+'% '+this.props.done}</div>
        <div style={{clear:'both'}}/>
        <div style={{color:'gray',fontSize:'10pt'}}>{doc.customer_order_number+' '}</div>

      </Dimmer.Dimmable>
    )
  }
}

const mapState = state => ({
  isRequestInProgress: state.app.isRequestInProgress,
})

const mapDispatch = dispatch => ({
  loadSale: (sale_id) => dispatch(loadSaleAction(sale_id)),
  newSale: (customer_id, quote_id) => dispatch(newSaleAction(customer_id, quote_id)),
  setModalEmailSent: (bool) => dispatch(setModalEmailSentAction(bool)),
  setModalEmailEmail: (txt) => dispatch(setModalEmailEmailAction(txt)),
  setModalEmailDocType: (txt) => dispatch(setModalEmailDocTypeAction(txt)),
  setModalEmailDocId: (txt) => dispatch(setModalEmailDocIdAction(txt)),
  loadQuote: (quote_id) => dispatch(loadQuoteAction(quote_id)),
})

export default connect(mapState,mapDispatch)(CustomerDetailsDoc)
