import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import { connect } from 'react-redux'

import { screens } from '../../redux/constants'
import { setScreenAction } from '../../redux/reducers/app/actions'

class EditStockButton extends Component {
  handleClick = () => {
    const { setScreen } = this.props
    setScreen(screens.NEW_SALE_DETAIL)
  }

  render() {
    return (
      <Button primary onClick={this.handleClick}>Edit</Button>
    )
  }
}

EditStockButton.propTypes = {
  setScreen: PropTypes.func.isRequired,
}

const mapDispatch = dispatch => ({
  setScreen: screen => dispatch(setScreenAction(screen)),
})

export default connect(null, mapDispatch)(EditStockButton)