import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'
import { connect } from 'react-redux'

import SearchResultRow from './SearchResultRow'
import { setSearchResultsModalIsOpenAction, loadSaleAction, loadQuoteAction } from '../../../redux/reducers/app/actions'

class SearchResultsModal extends Component {
  handleClose = () => this.props.setSearchResultsModalIsOpen(false)

  componentDidUpdate() {
    const { searchResults, loadSale, loadQuote, setSearchResultsModalIsOpen } = this.props
    if(searchResults.length && searchResults.length===1){

        if('quote_id' in searchResults[0]){
        	setSearchResultsModalIsOpen(false)
        	loadQuote(searchResults[0].quote_id)
        }else if ('sale_id' in searchResults[0]){
        	setSearchResultsModalIsOpen(false)
        	loadSale(searchResults[0].sale_id)
        }//else{
        	//newSale(searchResults[0].customer_id, searchResults[0].quote_id) better to show them the customer so they can choose to click on them
       // }
    }
  }

  render() {
    const { isOpen, searchResults } = this.props

    const firstResult = searchResults[0]
    if (!firstResult) return null

    let columns = []
    if ('customer_id' in firstResult) {
      columns = [
        { name: 'Id', field: 'customer_id'},
        { name: 'First name', field: 'first_name'},
        { name: 'Last name', field: 'last_name'},
        { name: 'Company', field: 'company_name'},
        { name: 'Code', field: 'code'},
        { name: 'Email', field: 'email'},
        { name: 'Mobile', field: 'mobile'},
      ]
    } else if ('quote_id' in firstResult) {
      columns = [
        { name: 'Id', field: 'quote_id'},
        { name: 'Code', field: 'customer_code'},
        { name: 'Date', field: 'transaction_date'},
        { name: 'First name', field: 'first_name'},
        { name: 'Last name', field: 'last_name'},
        { name: 'Company', field: 'company_name'},
        { name: 'Order Number', field: 'customer_order_number'},
        //{ name: 'Email', field: 'email'},
        //{ name: 'Mobile', field: 'mobile'},
        { name: 'Value', field: 'grand_totalCUR'},
      ]
    } else if ('sale_id' in firstResult) {
      columns = [
        { name: 'Id', field: 'sale_id'},
        { name: 'Code', field: 'customer_code'},
        { name: 'Date', field: 'transaction_date'},
        { name: 'First name', field: 'first_name'},
        { name: 'Last name', field: 'last_name'},
        { name: 'Company', field: 'company_name'},
        { name: 'Order Number', field: 'customer_order_number'},
        //{ name: 'Email', field: 'email'},
        //{ name: 'Mobile', field: 'mobile'},
        { name: 'Value', field: 'grand_totalCUR'},
      ]
    } else {
      return null
    }

    return (
      <Modal open={isOpen} onClose={this.handleClose}>
        <Modal.Header>Search results</Modal.Header>
        <Modal.Content scrolling>
          <Table compact selectable>
            <Table.Header>
              <Table.Row>
                {columns.map(c => <Table.HeaderCell key={c.name}>{c.name}</Table.HeaderCell>)}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {searchResults.map(r => {
                let id
                switch (r.type) {
                  case 'customer':
                    id = r.customer_id
                    break
                  case 'quote':
                    id = r.quote_id
                    break
                  case 'sale':
                    id = r.sale_id
                    break
                  default:
                    id = null
                }
                if(r.type==='customer' && r.on_holdYN==='1'){
                	if(typeof r.company_name==='string' && r.company_name.indexOf('ON HOLD')===-1){
                		r.company_name+=' (ON HOLD)'
               		}else{
               			r.company_name='(ON HOLD)'
               		}
                }
                return (
                  <SearchResultRow
                    key={id}
                    data={r}
                    columns={columns}
                  />
                )
              })}
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    )
  }
}

SearchResultsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  searchResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSearchResultsModalIsOpen: PropTypes.func.isRequired,
}

const mapState = state => ({
  isOpen: state.app.isSearchResultsModalOpen,
  searchResults: state.app.searchResults,
})
const mapDispatch = dispatch => ({
  setSearchResultsModalIsOpen: v => dispatch(setSearchResultsModalIsOpenAction(v)),
  loadSale: (sale_id) => dispatch(loadSaleAction(sale_id)),
  loadQuote: (quote_id) => dispatch(loadQuoteAction(quote_id)),
})

export default connect(mapState, mapDispatch)(SearchResultsModal)
