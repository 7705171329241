import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import { connect } from 'react-redux'
import 'semantic-ui-offline/semantic.min.css'

import MainMenu from './components/MainMenu'
import AppLoginScreen from './components/AppLoginScreen'
import NewSaleScreen from './components/NewSaleScreen'
import ProductionScreen from './components/ProductionScreen'
import NewSaleDetailScreen from './components/NewSaleDetailScreen'
import SettingsLoginScreen from './components/SettingsLoginScreen'
import SettingsScreen from './components/SettingsScreen'
import ViewSaleScreen from './components/ViewSaleScreen'
import ScanCodeScreen from './components/ScanCodeScreen'
import EmailModal from './components/EmailModal'
import AddNoteModal from './components/AddNoteModal'
import CustomerDetailsScreen from './components/CustomerDetails/CustomerDetailsScreen'
import FlashMessage from './components/FlashMessage'
import { loadDataAction, clearFlashMessageAction } from './redux/reducers/app/actions'
import { screens } from './redux/constants'

class App extends Component {
  async componentDidMount() {
    const { loadData } = this.props
    loadData()
  }

  render() {
    const { clearFlashMessage, flashMessage, isProductsLoaded, screen } = this.props

    const menuHeight = 49

    return (
      <Container
        as={Segment}
        basic
        style={{
          height: `calc(100vh - ${menuHeight}px)`,
        }}
      >
        {!isProductsLoaded && <Loader active content="Loading products..." />}
        {isProductsLoaded && (
          <div>
            <MainMenu />
            <div style={{ marginTop: menuHeight, height: '100%' }}>
              {flashMessage && (
                <FlashMessage
                  onDismiss={clearFlashMessage}
                  top={menuHeight}
                  type={flashMessage.type}
                >
                  {flashMessage.message}
                </FlashMessage>
              )}
              {screen === screens.SCAN_CODE && <ScanCodeScreen />}
              {screen === screens.APP_LOGIN && <AppLoginScreen />}
              {screen === screens.NEW_SALE && <NewSaleScreen />}
              {screen === screens.PRODUCTION && <ProductionScreen />}
              {screen === screens.NEW_SALE_DETAIL && <NewSaleDetailScreen />}
              {screen === screens.SETTINGS_LOGIN && <SettingsLoginScreen />}
              {screen === screens.SETTINGS && <SettingsScreen />}
              {screen === screens.VIEW_SALE && <ViewSaleScreen />}
              {screen === screens.CUSTOMER_DETAILS && <CustomerDetailsScreen />}
            </div>
            <EmailModal/>
            <AddNoteModal/>
          </div>
        )}
      </Container>
    )
  }
}

App.propTypes = {
  // The following props from redux:
  clearFlashMessage: PropTypes.func.isRequired,
  flashMessage: PropTypes.object,
  isProductsLoaded: PropTypes.bool.isRequired,
  loadData: PropTypes.func.isRequired,
  screen: PropTypes.string.isRequired,
}
App.defaultProps = {
  flashMessage: null,
}

const mapState = state => ({
  flashMessage: state.app.flashMessage,
  isProductsLoaded: state.app.isProductsLoaded,
  screen: state.app.screen,
})
const mapDispatch = dispatch => ({
  clearFlashMessage: () => dispatch(clearFlashMessageAction()),
  loadData: categories => dispatch(loadDataAction(categories))
})

export default connect(mapState, mapDispatch)(App)
