import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import { connect } from 'react-redux'

import StagesModal from './StagesModal'

class NextStageButton extends Component {
  state = {
    isModalOpen: false,
  }

  closeModal = () => this.setState({ isModalOpen: false })

  openModal = () => this.setState({ isModalOpen: true })

  render() {
    const { sale_id, stage_id, stages, permissions } = this.props
    const { isModalOpen } = this.state

    const stage = stages.find(s => s._id === stage_id)
    const stageName = stage ? stage.name : 'N/A'

    return (
      <div>
        <Button
          as="div"
          labelPosition="left"
          style={{ cursor: 'default' }}
        >
          <Label
            basic
            pointing="right"
            style={{ fontWeight: 400 }}
          >
            Stage: {stageName}
          </Label>
          <Button
            icon
            onClick={this.openModal}
            color="green"
          >
            Next
            <Icon name="arrow right" />
          </Button>
        </Button>
        <StagesModal
          handleClose={this.closeModal}
          isOpen={isModalOpen}
          sale_id={sale_id}
          permissions={permissions}
        />
      </div>
    )
  }
}

NextStageButton.propTypes = {
  stage_id: PropTypes.string,
  sale_id: PropTypes.string.isRequired,

  // The following props from redux
  stages: PropTypes.arrayOf(PropTypes.object).isRequired,
}
NextStageButton.defaultProps = {
  stage_id: null,
}

const mapState = state => ({
  stages: state.app.stages,
})

export default connect(mapState, null)(NextStageButton)
