import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import { isEmpty } from 'validator'
import { connect } from 'react-redux'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'

import { searchCustomersAction } from '../../redux/reducers/app/actions'

class CustomerSearchForm extends Component {
  state = {
    customer: '',
    isCustomerError: false,
  }

  handleInputChange = (e, { name, value }) => this.setState({ [name]: value })

  handleSubmit = () => {
    const { searchCustomers } = this.props
    const { customer } = this.state

    // Form validation
    if (isEmpty(customer)) {
      this.setState({ isCustomerError: true })
      return
    }

    // search_customers
    searchCustomers(customer)
  }

  render() {
    const { customer, isCustomerError } = this.state

    return (
      <Form onSubmit={this.handleSubmit}>
      <Header as="h2">New sale:</Header>
        <Form.Input
          error={isCustomerError}
          label="Find customer:"
          name="customer"
          onChange={this.handleInputChange}
          placeholder="Customer"
          required
          value={customer}
        />
        <Form.Button type="submit" primary content="Search Customers" />
      </Form>
    )
  }
}

CustomerSearchForm.propTypes = {
  // The following props from redux:
  searchCustomers: PropTypes.func.isRequired,
}

const mapDispatch = dispatch => ({
  searchCustomers: q => dispatch(searchCustomersAction(q)),
})

export default connect(null, mapDispatch)(CustomerSearchForm)
