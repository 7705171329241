import React, { Component } from 'react'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
//import { isEmpty } from 'validator'
import { connect } from 'react-redux'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'

import { setScreenAction, setSearchCustomerDetailsTextAction } from '../../redux/reducers/app/actions'

class CustomerDetailForm extends Component {

  handleSubmit = () => {
    const { setScreen,setSearchCustomerDetailsText } = this.props
    setSearchCustomerDetailsText(document.getElementById('setSearchCustomerDetailsTextInput').value);
    setScreen('CUSTOMER_DETAILS')
  }

  render() {

    return (
      <Form onSubmit={this.handleSubmit}>
        <Header as="h2">Find Customer:</Header>
        <Form.Input
          name="customer_details_search"
          id="setSearchCustomerDetailsTextInput"
          onChange={this.handleInputChange}
          placeholder="Customer name/code etc..."
          required
        />
        <Form.Button type="submit" primary content="Search Customers" />
      </Form>
    )
  }
}


const mapDispatch = dispatch => ({
  setScreen: screen => dispatch(setScreenAction(screen)),
  setSearchCustomerDetailsText: txt => dispatch(setSearchCustomerDetailsTextAction(txt))
})

export default connect(null, mapDispatch)(CustomerDetailForm)
