import { actionTypes } from '../../constants'

const initialState = {
  password: 'pictonsettings', // this should come from API app_settings?
  printerIPAddress: '1.1.1.1',
  printerPort: '9100',
  tillName: '',
  cardTerminalID: '',
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PRINTER_IP_ADDRESS:
      return {
        ...state,
        printerIPAddress: action.payload,
      }
    case actionTypes.SET_PRINTER_PORT:
      return {
        ...state,
        printerPort: action.payload,
      }
    case actionTypes.SET_TILL_NAME_ACTION:
      return {
        ...state,
        tillName: action.payload,
      }
    case actionTypes.SET_CARD_TERMINAL_ID_ACTION:
      return {
        ...state,
        cardTerminalID: action.payload,
    }
    default:
      return state
  }
}

export default settingsReducer
