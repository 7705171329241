export const actionTypes = {
  CLEAR_FLASH_MESSAGE: 'CLEAR_FLASH_MESSAGE',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SET_ACTIVE_SALE: 'SET_ACTIVE_SALE',
  SET_AUTHORISE_MODAL_IS_OPEN: 'SET_AUTHORISE_MODAL_IS_OPEN',
  SET_APP_REQUEST_IN_PROGRESS: 'SET_APP_REQUEST_IN_PROGRESS',
  SET_FLASH_MESSAGE: 'SET_FLASH_MESSAGE',
  SET_HPEMB: 'SET_HPEMB',
  SET_LOGIN_ERROR: 'SET_LOGIN_ERROR',
  SET_LOGIN_IN_PROGRESS: 'SET_LOGIN_IN_PROGRESS',
  SET_PAYMENT_MODAL_IS_OPEN: 'SET_PAYMENT_MODAL_IS_OPEN',
  SET_PAYMENT_TYPE: 'SET_PAYMENT_TYPE',
  SET_PRINTER_IP_ADDRESS: 'SET_PRINTER_IP_ADDRESS',
  SET_PRINTER_PORT: 'SET_PRINTER_PORT',
  SET_PRODUCT_CATEGORIES: 'SET_PRODUCT_CATEGORIES',
  SET_PRODUCT_OPTIONS: 'SET_PRODUCT_OPTIONS',
  SET_PRODUCTION_SALES: 'SET_PRODUCTION_SALES',
  SET_PRODUCTS_LOADED: 'SET_PRODUCTS_LOADED',
  SET_PRODUCTS: 'SET_PRODUCTS',
  SET_SALE_CATEGORIES: 'SET_SALE_CATEGORIES',
  SET_SCREEN: 'SET_SCREEN',
  SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS',
  SET_SEARCH_RESULTS_MODAL_IS_OPEN: 'SET_SEARCH_RESULTS_MODAL_IS_OPEN',
  SET_STAGES: 'SET_STAGES',
  SET_TILL_NAME_ACTION: 'SET_TILL_NAME_ACTION',
  SET_CARD_TERMINAL_ID_ACTION: 'SET_CARD_TERMINAL_ID_ACTION',
  SET_DISCMODAL: 'SET_DISCMODAL',
  SET_SEARCH_CUSTOMER_DETAILS_TEXT: 'SET_SEARCH_CUSTOMER_DETAILS_TEXT',
  SET_SENDING_MODAL_EMAIL: 'SET_SENDING_MODAL_EMAIL',
  SET_MODAL_EMAIL_SENT: 'SET_MODAL_EMAIL_SENT',
  SET_MODAL_EMAIL: 'SET_MODAL_EMAIL',
  SET_MODAL_DOC_TYPE: 'SET_MODAL_DOC_TYPE',
  SET_MODAL_DOC_ID: 'SET_MODAL_DOC_ID',
  SET_SENDING_MODAL_NOTE: 'SET_SENDING_MODAL_NOTE',
  SET_MODAL_NOTE_SENT: 'SET_MODAL_NOTE_SENT',
}

export const screens = {
  APP_LOGIN: 'APP_LOGIN',
  NEW_SALE: 'NEW_SALE',
  NEW_SALE_DETAIL: 'NEW_SALE_DETAIL',
  PRODUCTION: 'PRODUCTION',
  SETTINGS: 'SETTINGS',
  SETTINGS_LOGIN: 'SETTINGS_LOGIN',
  VIEW_SALE: 'VIEW_SALE',
  SCAN_CODE: 'SCAN_CODE',
  CUSTOMER_DETAILS: 'CUSTOMER_DETAILS',
}
