const readFile = async (file, callback) => {
  const reader = new FileReader()
  reader.onloadend = function (e) { // no arrow function as we use this.result
    const content = this.result
    callback(content)
  }
  reader.readAsDataURL(file)
}

export default readFile
