import React, { Component } from 'react'
import {Modal, Button, Segment, TextArea, Form} from 'semantic-ui-react'
import { connect } from 'react-redux'

import { sendNoteAction,setModalNoteSentAction} from '../redux/reducers/app/actions'

class AddNoteModal extends Component {

  sendNoteHandle=()=>{
    const {sendNote,sale_id} = this.props
    if(!document.getElementById('new_note').value){
      return false
    }
    if(!sale_id){
      alert('No sale ID for this note - cannot sent...')
      return false
    }
    sendNote(document.getElementById('new_note').value,sale_id)
  }

  openModal=()=>{
  	  const {setModalNoteSent} = this.props
  	  setModalNoteSent(false)
  }

  closeModal=()=>{
  	  const {setModalNoteSent} = this.props
  	  setModalNoteSent(true)
  }

  render() {
    const {
    	    addNoteModalSent,
          addNoteModalSending
    } = this.props

    return (
    	    <span>
        		<Modal onClose={this.closeModal} dimmer={'blurring'} open={addNoteModalSent}>
          		<Modal.Header>Add a new note:</Modal.Header>
          		<Modal.Content>
          		  <Modal.Description>
          		    <Segment basic loading={addNoteModalSending}>
          		    <Form>
          		      <Form.Field inline>
          			      <label>Note:</label>
          			      <TextArea fluid placeholder='Note...' id='new_note'/>
          		      </Form.Field>
          		      <Button color='blue' onClick={this.sendNoteHandle}>Add Note</Button>
          		    </Form>
          		    </Segment>
          		  </Modal.Description>
          		</Modal.Content>
        	  </Modal>
         </span>
    )
  }
}

const mapState = state => ({
  sale_id: (state.app.activeSale?state.app.activeSale._id:0),
  addNoteModalSending: state.app.addNoteModalSending,
  addNoteModalSent: (state.app.addNoteModalSent?false:true)
})
const mapDispatch = dispatch => ({
  sendNote: (note,doc_id) => dispatch(sendNoteAction(note,doc_id)),
  setModalNoteSent: (bool) => dispatch(setModalNoteSentAction(bool)),
})

export default connect(mapState, mapDispatch)(AddNoteModal)
