import React from 'react'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'

const HpembDisplay = ({ hpemb }) => {
	if (!hpemb) return <Segment>This sale has no heat press or embroidery instructions.</Segment>
	if (typeof hpemb.embroidery_logos!='undefined'){
		if( Object.prototype.toString.call( hpemb.embroidery_logos.rows ) === '[object Array]' ) {
		    //
		}else{
			hpemb.embroidery_logos.rows=[hpemb.embroidery_logos.rows[1]];
		}
	}
	if (typeof hpemb.heat_press_logos!='undefined'){
		if( Object.prototype.toString.call( hpemb.heat_press_logos.rows ) === '[object Array]' ) {
		    //
		}else{
			hpemb.heat_press_logos.rows=[hpemb.heat_press_logos.rows[1]];
		}
	}
	if (typeof hpemb.embroidery_initials!='undefined'){
		if( Object.prototype.toString.call( hpemb.embroidery_initials.rows ) === '[object Array]' ) {
		    //
		}else{
			hpemb.embroidery_initials.rows=[hpemb.embroidery_initials.rows[1]];
		}
	}
	if (typeof hpemb.heat_press_initials!='undefined'){
		if( Object.prototype.toString.call( hpemb.heat_press_initials.rows ) === '[object Array]' ) {
		    //
		}else{
			hpemb.heat_press_initials.rows=[hpemb.heat_press_initials.rows[1]];
		}
	}
	if (typeof hpemb.sublimation!='undefined'){
		if( Object.prototype.toString.call( hpemb.sublimation.rows ) === '[object Array]' ) {
		    //
		}else{
			hpemb.sublimation.rows=[hpemb.sublimation.rows[1]];
		}
	}
	return(
	<Segment>
		<div>
			{typeof hpemb.embroidery_logos!='undefined' ? (
				<div>
					<h4 style={{marginBottom:'2px'}}>Embroidery Logos:</h4>
					<span>
					{hpemb.embroidery_logos.rows.map((i,index) => (
					    <div key={index}>
						<span> {i.location}: </span>
						<span> {i.text} </span>
						<div style={{height:'1px',borderTop:'1px solid #efefef',marginTop:'2px',marginBottom:'5px'}} />
					    </div>
					  ))}
					</span>
				</div>
			):''}
		</div>
		<div>
			{typeof hpemb.heat_press_logos!='undefined' ? (
				<div>
					<h4 style={{marginTop:'10px',marginBottom:'2px'}}>Heat Press Logos:</h4>
					<span>
					{hpemb.heat_press_logos.rows.map((i,index) => (
					    <div key={index}>
						<span> {i.location}: </span>
						<span> {i.text} </span>
						<div style={{height:'1px',borderTop:'1px solid #efefef',marginTop:'2px',marginBottom:'5px'}} />
					    </div>
					  ))}
					</span>
				</div>
			):''}
		</div>
		<div>
			{typeof hpemb.embroidery_initials!='undefined' ? (
				<div>
					<h4 style={{marginTop:'10px',marginBottom:'2px'}}>Embroidery Initials:</h4>
					<span>
					{hpemb.embroidery_initials.rows.map((i,index) => (
					    <div key={index}>
						<span> {i.location}: </span>
						<span> {i.text} </span>
						<span> Size:{i.size} </span>
						<span> {i.color} </span>
						<div style={{height:'1px',borderTop:'1px solid #efefef',marginTop:'2px',marginBottom:'5px'}} />
					    </div>
					  ))}
					</span>
				</div>
			):''}
		</div>
		<div>
			{typeof hpemb.heat_press_initials!='undefined' ? (
				<div>
					<h4 style={{marginTop:'10px',marginBottom:'2px'}}>Heat Press Initials:</h4>
					<span>
					{hpemb.heat_press_initials.rows.map((i,index) => (
					    <div key={index}>
						<span> {i.location}: </span>
						<span> {i.text} </span>
						<span> Size:{i.size} </span>
						<span> {i.color} </span>
						<div style={{height:'1px',borderTop:'1px solid #efefef',marginTop:'2px',marginBottom:'5px'}} />
					    </div>
					  ))}
					</span>
				</div>
			):''}
		</div>
		<div>
			{typeof hpemb.sublimation!='undefined' ? (
				<div>
					<h4 style={{marginTop:'10px',marginBottom:'2px'}}>Sublimation:</h4>
					<span>
					{hpemb.sublimation.rows.map((i,index) => (
					    <div key={index}>
						<span> Size:{i.size} </span>
						<span> {i.text} </span>
						<div style={{height:'1px',borderTop:'1px solid #efefef',marginTop:'2px',marginBottom:'5px'}} />
					    </div>
					  ))}
					</span>
				</div>
			):''}
		</div>
	</Segment>
	)
}

HpembDisplay.defaultProps = {
  hpemb: {
  	  embroidery_logos:{rows:[]},
  	  heat_press_logos:{rows:[]},
  	  embroidery_initials:{rows:[]},
  	  heat_press_initials:{rows:[]},
  	  sublimation:{rows:[]}
  },
}

export default HpembDisplay
