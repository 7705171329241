import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

class EditableTextArea extends Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  state = {
    isEditing: false,
    value: this.props.defaultValue,
  }

  handleChange = (e) => this.setState({ value: e.target.value })

  handleEdit = () => {
    this.setState({ isEditing: true }, () => this.inputRef.current.focus())
  }

  handleEditComplete = () => {
    const { defaultValue, onSubmit } = this.props
    const { value } = this.state
    this.setState({ isEditing: false }, () => {
      if (value !== defaultValue) onSubmit(value)
    })
  }

  keyPress = (e) => {
    if (e.keyCode === 13) this.handleEditComplete()
  }

  render() {
    const { placeholder, rows } = this.props
    const { isEditing, value } = this.state

    return (
      <div
        onClick={isEditing ? this.handleEditComplete : this.handleEdit}
        style={{ cursor: 'pointer', display: 'flex' }}
      >
        <textarea
          disabled={!isEditing}
          onChange={this.handleChange}
          onKeyDown={this.keyPress}
          placeholder={placeholder}
          ref={this.inputRef}
          rows={rows}
          style={{
            border: !isEditing ? '1px dashed #c8c8c8' : null,
            // borderLeft: 0,
            // borderRight: 0,
            // borderTop: 0,
            flex: 1,
            cursor: !isEditing ? 'pointer' : null,
          }}
          value={value || ''}
        />
        <Icon
          color={isEditing ? 'green' : null}
          name={isEditing ? 'check' : 'edit'}
          size="large"
          style={{
            cursor: 'pointer',
            marginLeft: '0.4rem',
          }}
        />
      </div>
    )
  }
}

EditableTextArea.propTypes = {
  defaultValue: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
}
EditableTextArea.defaultProps = {
  defaultValue: '',
  placeholder: '',
  rows: 3,
}

export default EditableTextArea
